import { EnvelopeIcon, PlusIcon, RectangleStackIcon, TrashIcon, UserIcon, UserMinusIcon, UserPlusIcon, UsersIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { addUser, deleteUser, getGroupData, manageUserRole } from "src/actions/user";
import { apiRequest } from "src/async/apiUtils";
import TextAccordion from "src/components/Shared/Accordions/TextAccordion";
import Button from "src/components/Shared/Buttons/Button";
import DataExport from "src/components/Shared/DataExport";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import Input from "src/components/Shared/Forms/Inputs/Input";
import RadioGroup from "src/components/Shared/Forms/Inputs/RadioGroup";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject/MultiSelectObject";
import MultiSelectObjectUsers from "src/components/Shared/Forms/Selects/MultiSelectObject/MultiSelectObjectUsers";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import Members from "src/components/Shared/Members";
import Modal from "src/components/Shared/Modal";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import Search from "src/components/Shared/Search";
import MultiRowSelectButton from "src/components/Shared/Table/MultiRowSelect/MultiRowSelectButton";
import MultiRowSelectContainer from "src/components/Shared/Table/MultiRowSelect/MultiRowSelectContainer";
import MultiRowSelectedCountBubble from "src/components/Shared/Table/MultiRowSelect/MultiRowSelectedCountBubble";
import TRHeader from "src/components/Shared/Table/TRHeader";
import Table from "src/components/Shared/Table/Table";
import TableOptions from "src/components/Shared/TableOptions";
import UserEntry from "src/components/Users/List/UserEntry";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";
import { formatDate } from "src/helpers/formatDate";
import { getUrlParams } from "src/helpers/getUrlParams";
import useFetch from "src/hooks/useFetch";
import useWindowDimensions from "src/hooks/useWindowDimensions";

const UsersList = ({ title = "", filterUserType = [], user = {}, ...props }) => {
  const navigate = useNavigate();
  const roles = [
    { key: "Participant", value: "participant" },
    { key: "Editor", value: "editor" },
    { key: "Privileged", value: "privileged" },
    { key: "Admin", value: "admin" },
  ];
  const [deleteId, setDeleteId] = useState(null);
  const [addUser, setAddUser] = useState(false);
  const [addExistingUser, setAddExistingUser] = useState(false);
  const defaultState = {
    name: "",
    email: "",
    password: "",
    confirm_password: "",
    image: "",
    type: "participant",
    notifications: false,
    update: true,
    disabled_sso_auto_updated: false,
    disabled_dataset_auto_updated: false,
  };
  const [state, setState] = useState(defaultState);
  // Search
  const [keyword, setKeyword] = useState("");
  const [status] = useState("");
  const [group] = useState("");
  const [groupList, setGroupList] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  // Pagination
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(0);
  // All User
  const [userLoaded, setUserLoaded] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedExistingUserGroups, setSelectedExistingUserGroups] = useState([]);
  const [successModalStart, setSuccessModalStart] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [domoUser, setDomoUser] = useState({});
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [disableActions, setDisableActions] = useState(false);
  const [initialCustomFields, setInitialCustomFields] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
  const [workspaceModalOpen, setWorkspaceModalOpen] = useState(false);
  const [isDeleteMultiUserModalOpen, setIsDeleteMultiUserModal] = useState(false);
  const [isResendMultiUserModalOpen, setIsResendMultiUserModal] = useState(false);
  const [isResendMultiUserResetPasswordModalOpen, setIsResendMultiUserResetPasswordModal] = useState(false);
  const [isMultiUserStatusUpdateModelOpen, setIsMultiUserStatusUpdateModelOpen] = useState(false);
  const [multiUserStatusUpdateValue, setMultiUserStatusUpdateValue] = useState("DEACTIVATE");
  const [userType, setUserType] = useState("participant");
  const { width } = useWindowDimensions();

  let {
    params: { workspaces: workspaceId },
  } = getUrlParams(window);

  const loadCustomFields = useCallback(() => {
    (async () => {
      try {
        const { data: resData } = await apiRequest("GET", `/users/custom-fields`);

        if (resData.status === 200 && resData.data) {
          setInitialCustomFields(
            resData.data.map((item) => ({
              ...item,
              value: item.default_value || "",
            })),
          );
          setCustomFields(
            resData.data.map((item) => ({
              ...item,
              value: item.default_value || "",
            })),
          );
        }
      } catch (err) {
        console.dir("err", err);
      }
    })();
  }, []);

  useEffect(() => {
    if (workspaceId) loadCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId]);

  const {
    response: { data: users, meta },
    status: { done: usersLoaded },
    refreshData: refreshUsers,
  } = useFetch(workspaceId && props.workspaceDetails?._id ? `/workspaces/:workspace_id/users` : "/users", { params: { workspace_id: workspaceId }, query: { limit: limit, offset: offset, keyword: keyword, status: status, group: group, type: filterUserType + "" } });

  const {
    response: { data: domoUsers },
  } = useFetch(workspaceId && props.workspaceDetails?._id && props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED" ? `/workspaces/:workspace_id/domo-users` : "", { params: { workspace_id: workspaceId } });

  const {
    response: { data: allWorkspaces },
  } = useFetch(!workspaceId ? "/workspaces/list" : "", {
    body: {
      includes: ["groups"],
    },
  });

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  useEffect(() => {
    refreshUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, offset, status, group]);

  useEffect(() => {
    refreshUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  // Load groups
  useEffect(() => {
    // const ac = new AbortController();

    const loadGroups = async (workspaceId) => {
      try {
        if (props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED") {
          const res = await apiRequest("get", `/workspaces/:workspace_id/domo-groups`, {
            params: { workspace_id: workspaceId },
          });

          if (res.data.status === 200) {
            setGroupList(res.data.data);
          }
        } else {
          let data = {};
          if (workspaceId) {
            data = await apiRequest("get", `/workspaces/:workspace_id/groups`, { params: { workspace_id: workspaceId } });
          } else {
            data = await apiRequest("post", `/groups/list`);
          }
          data = data?.data;
          setGroupList(data.data);
        }
      } catch (error) {
        // console.dir("ERROR:", error.message);
      }
    };

    if (workspaceId) {
      loadGroups(workspaceId);
    }

    // return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, props.workspaceDetails?.auth_token, props.workspaceDetails?.workspace_type]);

  useEffect(() => {
    let multiUserStatusDeactivateOrActivate = users?.filter((user) => checkedUsers.includes(user._id) && user.active_status).length ? "DEACTIVATE" : "ACTIVATE";
    setMultiUserStatusUpdateValue(multiUserStatusDeactivateOrActivate);
  }, [users, checkedUsers]);

  useEffect(() => {
    const loadUsers = async () => {
      const res = await apiRequest("get", "users/list", { queries: { type: "user/editor" } });

      if (res.status === 200) {
        setAllUsers(res.data.data);
      }

      setUserLoaded(true);
    };

    if (workspaceId) {
      loadUsers();
    }
  }, [workspaceId]);

  const removeUserFromWorkspace = async (e) => {
    if (e) {
      e.preventDefault();
    }

    try {
      setSuccessModalStart(true);
      const data = await apiRequest(
        "POST",
        `/workspaces/:workspace_id/users/remove`,
        {
          body: {
            users: [deleteId],
            auth_token: props.workspaceDetails?.auth_token,
          },
          params: {
            workspace_id: workspaceId,
          },
        },
        {
          showToastMessage: true,
          onSuccess: () => {},
          onFailure: () => {},
        },
      );
      if (data.status === 200) {
        setDeleteId(null);
        setSuccessModalStart(false);
        if (users.length === 1 && offset !== 0) {
          setOffset(offset - 1);
        } else {
          refreshUsers();
        }
      }
    } catch (error) {
      setSuccessModalStart(false);
    }
  };

  const removeUser = async (e) => {
    if (e) {
      e.preventDefault();
    }

    try {
      setSuccessModalStart(true);
      const message = await props.deleteUser({
        users: [deleteId],
      });
      setDeleteId(null);
      setSuccessModalStart(false);
      if (users.length === 1 && offset !== 0) {
        setOffset(offset - 1);
      } else {
        refreshUsers();
      }
      //
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSuccessModalStart(false);
    }
  };

  const removeUserAccess = async (userId = "") => {
    try {
      const res = await apiRequest("DELETE", `/workspaces/:workspace_id/users/:user_id/permissions`, {
        params: {
          workspace_id: props.workspaceDetails?._id,
          user_id: userId,
        },
      });

      if (res.data.status === 200) {
        if (users.length === 1 && offset !== 0) {
          setOffset(offset - 1);
        } else {
          refreshUsers();
        }
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const prepData = async () => {
    let queries = { keyword: keyword, type: "user,editor" };
    const params = { workspace_id: workspaceId };
    if (filterUserType.length) queries.filterTypes = filterUserType.toString();
    try {
      const { data } = await apiRequest("GET", workspaceId ? `/workspaces/:workspace_id/users` : `/users`, { queries, params });

      let exportDoc = [];
      if (data.data.length > 0) {
        for (let index = 0; index < data.data.length; index++) {
          const element = data.data[index];
          let groupName = [];
          if (element?.groups && element.groups.length > 0) {
            for (let i = 0; i < element.groups.length; i++) {
              const grup = element.groups[i];
              groupName.push(grup.name);
            }
          }

          exportDoc.push({
            Name: element?.name,
            Email: element?.email,
            Type: element?.type,
            Groups: groupName.length > 0 ? groupName?.toString() : "",
            "Date Created": formatDate(element?.created_at, "MM/DD/YYYY HH:mm:ss"),
            "Last Login": element?.last_login ? formatDate(element?.last_login, "MM/DD/YYYY HH:mm:ss") : "n/a",
            "Is Active": element?.active_status ? "true" : "false",
            "Deactivation Dated": !element?.active_status ? formatDate(element.deactivated_at, "MM/DD/YYYY HH:mm:ss") : "n/a",
          });
        }
      }
      return exportDoc;
    } catch (error) {
      // console.dir("ERROR:", error);
    }
  };

  useEffect(() => {
    let updatedGroups = groupList.filter((group) => selectedGroups.includes(group._id));
    setState({
      ...state,
      groups: updatedGroups,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroups]);

  const handleSelectedGroups = (groups) => {
    let filteredGroups = groups.filter((group) => group.selected);
    setSelectedGroups(filteredGroups.map((group) => group.key));
  };

  const onSubmit = async (users = null) => {
    try {
      if (typeof users !== "boolean" && !Array.isArray(users) && props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED") {
        if (state.name && state.email) {
          const existingUser = allUsers.find((user) => user.email.toLowerCase() === state.email.toLowerCase());

          if (existingUser) {
            toast.error("This email is taken.");
            return;
          }

          const findDomoUser = domoUsers.find((user) => user.email.toLowerCase() === state.email.toLowerCase());

          if (findDomoUser) {
            setAddUser(false);
            setAddExistingUser(false);
            setDomoUser(findDomoUser);

            setIsImportModalOpen(true);
            return;
          }
        }
      }

      setSuccessModalStart(true);
      const res = await apiRequest(
        "POST",
        `/workspaces/:workspace_id/users/add`,
        {
          body: {
            users: Array.isArray(users)
              ? props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED"
                ? users.map((user) => ({ ...user, groups: selectedGroups, custom_fields: customFields, type: userType }))
                : users.map((user) => ({ ...user, groups: selectedGroups, custom_fields: customFields }))
              : [{ ...state, groups: selectedGroups, custom_fields: customFields }],
            auth_token: props.workspaceDetails?.auth_token,
            existing: Array.isArray(users) ? true : false,
            isDomo: props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED",
            workspace_id: props.workspaceDetails?._id,
          },
          params: {
            workspace_id: workspaceId,
          },
        },
        {
          showToastMessage: true,
          onSuccess: () => {},
          onFailure: () => {},
        },
      );
      if (res.status === 200) {
        refreshUsers();
        setAddUser(false);
        setAddExistingUser(false);
        setSelectedUsers([]);
        setSelectedExistingUserGroups([]);

        setState(defaultState);

        setIsImportModalOpen(false);
        setDomoUser(false);
      }
      setSuccessModalStart(false);
    } catch (error) {
      setSuccessModalStart(false);
    } finally {
    }
  };

  const manageUserRole = async (e, user) => {
    try {
      const data = await props.manageUserRole({
        id: user._id,
        type: e.value,
        auth_token: props.workspaceDetails?.auth_token,
        workspace_id: workspaceId,
      });
      toast.success(data);
    } catch (error) {
      refreshUsers();
      toast.error(error.message);
    }
  };

  const onUserModalClose = () => {
    setIsImportModalOpen(false);
    setDomoUser({});

    setAddUser(false);
    setAddExistingUser(false);
    setSelectedUsers([]);
    setSelectedExistingUserGroups([]);

    setState(defaultState);
    setCustomFields(initialCustomFields);
  };

  const inviteMultiUser = async () => {
    setDisableActions(true);

    try {
      let url = `/users/send-welcome-email`;
      if (workspaceId) {
        url = `/workspaces/:workspace_id/users/send-welcome-email`;
      }
      const params = { workspace_id: workspaceId };
      const res = await apiRequest("POST", url, { body: { userIds: checkedUsers }, params });

      if (res.data.status === 200) {
        setCheckedUsers([]);
        toast.success(res.data.message);
        setIsResendMultiUserModal(false);
      } else {
        toast.error(res.data.message);
      }
    } finally {
      setDisableActions(false);
    }
  };

  const inviteMultiUserResetPassword = async () => {
    try {
      setDisableActions(true);
      let url = `/users/send-reset-email`;
      if (workspaceId) {
        url = `/workspaces/:workspace_id/users/send-reset-email`;
      }
      const params = { workspace_id: workspaceId };
      const res = await apiRequest("POST", url, { body: { userIds: checkedUsers }, params });

      if (res.data.status === 200) {
        setCheckedUsers([]);
        toast.success(res.data.message);
        setIsResendMultiUserResetPasswordModal(false);
      } else {
        toast.error(res.data.message);
      }
    } finally {
      setDisableActions(false);
    }
  };

  const multiUserStatusUpdate = async (multiUserStatusUpdateValue = false) => {
    setDisableActions(true);
    setIsMultiUserStatusUpdateModelOpen(false);

    try {
      const res = await apiRequest("POST", "/users/manage-status", { body: { id: checkedUsers, activeStatus: multiUserStatusUpdateValue === "ACTIVATE" ? true : false } });

      if (res.data.status === 200) {
        setCheckedUsers([]);
        refreshUsers();

        setIsMultiUserStatusUpdateModelOpen(false);
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setDisableActions(false);
    }
  };

  const deleteMultiuser = async () => {
    setDisableActions(true);

    try {
      const res = await apiRequest("POST", workspaceId && props.workspaceDetails?._id ? `/workspaces/:workspace_id/users/remove` : "/users/remove", { body: { users: checkedUsers }, params: { workspace_id: props.workspaceDetails?._id } });

      if (res.data.status === 200) {
        setCheckedUsers([]);
        setIsDeleteMultiUserModal(false);
        refreshUsers();

        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error("Something went wrong.");
    } finally {
      setDisableActions(false);
    }
  };

  const assignWorkspaceUsers = async () => {
    setDisableActions(true);

    try {
      const res = await apiRequest("POST", "/users/workspace-assign", { body: { workspaces: selectedWorkspaces, userIds: checkedUsers } });
      if (res.data.status === 200) {
        setCheckedUsers([]);
        setSelectedWorkspaces([]);
        setWorkspaceModalOpen(false);
        toast.success(res.data.message);

        refreshUsers();
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setDisableActions(false);
    }
  };

  const updateCustomFields = (fieldId, value) => {
    setCustomFields((fields) =>
      fields.map((field) =>
        field._id !== fieldId
          ? field
          : {
              ...field,
              value,
            },
      ),
    );
  };

  return (
    <>
      <TableOptions
        wrapperClass={"!items-start"}
        innerClass={"items-start"}
        leftContent={
          <Search
            keyword={keyword}
            setKeyword={(val) => {
              setOffset(0);
              setKeyword(val);
            }}
            placeholder={"Search"}
            inline={true}
          />
        }
        middleContent={
          <>
            {user?.type === "admin" && (
              <MultiRowSelectContainer
                disabledToolTip={{ id: `${user?.type}-list`, text: `Select at least 1 row to enable functionality` }}
                disabled={checkedUsers?.length === 0}>
                <MultiRowSelectButton
                  xPlacement={width > 640 ? "bottomLeft" : "bottom"}
                  isDropdown="true"
                  name="Send emails"
                  disabled={checkedUsers?.length === 0}
                  Icon={EnvelopeIcon}
                  items={[
                    { name: "Send welcome email", onClick: () => setIsResendMultiUserModal(true) },
                    { name: "Send reset password email", onClick: () => setIsResendMultiUserResetPasswordModal(true) },
                  ]}
                />
                {!workspaceId && (
                  <MultiRowSelectButton
                    name="Assign to workspace"
                    disabled={checkedUsers?.length === 0}
                    onClick={() => setWorkspaceModalOpen(true)}
                    component={
                      <IconsWithPlus
                        size={checkedUsers?.length === 0 ? "h-6 stroke-gray-400" : ` h-6 stroke-gray-400 group-hover:stroke-${props?.site?.highlight_color ? "highlightColor" : "gray-500"}`}
                        strokeColor={checkedUsers?.length === 0 ? "stroke-gray-400" : `stroke-gray-400 group-hover:stroke-${props?.site?.highlight_color ? "highlightColor" : "gray-500"}`}
                        item={{ icon: RectangleStackIcon }}
                      />
                    }
                  />
                )}
                <span className="px-2 text-gray-300">|</span>
                <MultiRowSelectButton
                  name={multiUserStatusUpdateValue === "ACTIVATE" ? "Activate users" : "Deactivate users"}
                  disabled={checkedUsers?.length === 0}
                  onClick={() => setIsMultiUserStatusUpdateModelOpen(true)}
                  Icon={checkedUsers.length === 0 ? UserIcon : multiUserStatusUpdateValue === "ACTIVATE" ? UserPlusIcon : UserMinusIcon}
                />
                {props?.site?.api_state !== "DOMO_DATASET" && (
                  <MultiRowSelectButton
                    name="Delete users"
                    disabled={checkedUsers?.length === 0}
                    onClick={() => setIsDeleteMultiUserModal(true)}
                    Icon={TrashIcon}
                  />
                )}
                <div className="relative">
                  <MultiRowSelectedCountBubble
                    count={checkedUsers.length}
                    clear={() => setCheckedUsers([])}
                    position="left-full top-1/2 -translate-y-1/2 ml-2"
                  />
                </div>
              </MultiRowSelectContainer>
            )}
          </>
        }
        rightContent={
          !workspaceId
            ? props?.site?.api_state !== "DOMO_DATASET" &&
              user?.type === "admin" && (
                <Button
                  version="secondary"
                  hoverText={`${title === "Admin" ? "Add an administrator" : "Add a user"}`}
                  onClick={() => navigate(title === "Admin" ? `/admins/add` : `/users/add`)}>
                  <PlusIcon className="h-4 w-4 stroke-[3px]" />
                  {title === "Admin" ? "Add administrator" : "Add user"}
                </Button>
              )
            : authorizeUserComponentAccess(user, workspaceId, "user", ["update"]) && (
                <Button
                  version="secondary"
                  onClick={() => setAddUser(true)}>
                  <PlusIcon className="h-4 w-4 stroke-[3px]" />
                  Add user
                </Button>
              )
        }
      />
      <Table
        tableHeader={
          <TRHeader>
            {user?.type === "admin" && (
              <th
                scope="col"
                className="relative w-12 whitespace-nowrap rounded-tl-lg py-3 pl-1 text-sm sm:pl-4">
                <ReactTooltip
                  id={`user_list_select_all`}
                  delayShow={200}
                  positionStrategy="fixed"
                  className="z-[200] rounded bg-gray-700 px-2 py-2 opacity-100">
                  <div className="font-normal leading-[10px]">Select all users shown</div>
                </ReactTooltip>
                <div data-tooltip-id="user_list_select_all">
                  <Checkbox
                    isChecked={checkedUsers.length && checkedUsers.length === users.length}
                    onChange={() => {
                      if (checkedUsers.length === users.length) {
                        setCheckedUsers([]);
                      } else {
                        setCheckedUsers(users.map((user) => user._id));
                      }
                    }}
                  />
                </div>
              </th>
            )}
            <th
              scope="col"
              className="rounded-tl-lg py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Name
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
              Email
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
              Status
            </th>
            {props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED" && (
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                Domo Role
              </th>
            )}
            <th
              scope="col"
              className="relative rounded-tr-lg py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </TRHeader>
        }
        colSpan="5"
        setDeleteId={setDeleteId}
        deleteId={deleteId}
        loaded={usersLoaded}
        dataExists={users?.length > 0}>
        {users?.length > 0 &&
          users.reduce((filteredUsers, user) => {
            return filteredUsers.length < limit
              ? [
                  ...filteredUsers,
                  <UserEntry
                    key={user._id}
                    user={user}
                    deleteId={deleteId}
                    setDeleteId={setDeleteId}
                    refreshUsers={refreshUsers}
                    manageUserRole={manageUserRole}
                    workspaceDetails={props.workspaceDetails}
                    removeUserAccess={removeUserAccess}
                    allowMulti
                    checkedUsers={checkedUsers}
                    setCheckedUsers={setCheckedUsers}
                    disableCheckButton={disableActions}
                  />,
                ]
              : filteredUsers;
          }, [])}
      </Table>
      <PaginationFooter
        itemName={title === "Admin" ? `Admin record` : `User record`}
        count={users?.length ? meta?.count : 0}
        loaded={usersLoaded}
        limit={limit}
        offset={offset}
        tabCount={usersLoaded && Math.ceil(meta?.count / limit)}
        onChange={handlePaginationChange}
      />
      {user.type !== "user" && (
        <DataExport
          title="user_list_report"
          headings={["Name", "Email", "Type", "Groups", "Date Created", "Last Login", "Is Active", "Deactivated Date"]}
          prepData={prepData}
        />
      )}
      {/* User delete modal */}
      <Modal
        title="User"
        secondaryTitle="Delete"
        isOpen={!!deleteId}
        onCancel={() => setDeleteId(null)}
        onSuccess={(e) => {
          if (workspaceId) {
            removeUserFromWorkspace(e);
          } else {
            removeUser(e);
          }
        }}
        defaultOptions={{
          onSuccessButtonText: "Delete",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successModalStart,
        }}>
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {usersLoaded && users?.find((user) => user._id === deleteId)?.name}?</div>
      </Modal>
      {/* User create modal */}
      <Modal
        title={props.workspaceDetails?.name}
        secondaryTitle="Create User"
        isOpen={addUser}
        onCancel={onUserModalClose}
        onSuccess={onSubmit}
        size="xxl"
        defaultOptions={{
          onSuccessButtonText: "Submit",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successModalStart,
        }}
        defaultStyles={{
          overFlowYVisible: false,
        }}>
        <div className="relative grid w-full gap-4 md:grid-cols-2 mb-4">
          <div className="relative w-full">
            <Input
              name="fname"
              label="Full Name"
              inline={true}
              type={"text"}
              value={state.name}
              autoComplete={"new-fname"}
              onChange={(e) =>
                setState({
                  ...state,
                  name: e.target.value,
                })
              }
            />
          </div>
          <div className="relative w-full">
            <Input
              name="email"
              label="Email"
              inline={true}
              type={"email"}
              value={state.email}
              autoComplete={"new-email"}
              onChange={(e) =>
                setState({
                  ...state,
                  email: e.target.value,
                })
              }
            />
          </div>
          {customFields.length > 0 &&
            customFields
              .filter((field) => field.is_required)
              .map((field) => (
                <div
                  className="relative w-full"
                  key={field._id}>
                  <Input
                    name={field.label}
                    inline={true}
                    label={field.label}
                    value={field.value || ""}
                    onChange={(e) => updateCustomFields(field._id, e.target.value)}
                  />
                </div>
              ))}
          {props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED" && (
            <div className="relative w-full">
              <SelectMenu
                label={"Domo Role"}
                inline={true}
                options={roles}
                setOption={(e) =>
                  setState({
                    ...state,
                    type: e.value,
                  })
                }
                startIndex={roles.findIndex((rl) => rl.value === state.role) > 0 ? roles.findIndex((rl) => rl.value === state.role) : 0}
              />
            </div>
          )}
        </div>
        {customFields?.filter((field) => !field.is_required)?.length > 0 && (
          <div className="w-full mb-4">
            <div className="text-base text-gray-400 mb-2">* Optional attributes</div>
            <div className="relative grid w-full gap-4 md:grid-cols-2">
              {customFields
                .filter((field) => !field.is_required)
                .map((field) => (
                  <div
                    className="relative w-full"
                    key={field._id}>
                    <Input
                      inline={true}
                      name={field.label}
                      label={field.label}
                      value={field.value || ""}
                      onChange={(e) => updateCustomFields(field._id, e.target.value)}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
        <TextAccordion
          headerText="Advanced features"
          headerTextPosition="left">
          <div className="mb-4 flex w-full flex-col">
            <RadioGroup
              title="Send welcome email on setup"
              subtitle="Default settings will send out an email on user creation."
              options={[
                { id: "send-email", title: "Send invite email" },
                { id: "manual", title: "Manually set password" },
              ]}
              defaultChecked={state.update ? "send-email" : "manual"}
              onChange={(id) => {
                setState({
                  ...state,
                  update: id === "send-email",
                });
              }}
              layout="horizontal"
            />
            <div className={classNames("grid gap-y-3 transition-all duration-150", state.update ? "mt-2 max-h-0 overflow-hidden opacity-0" : "mt-6 max-h-[200px] opacity-100")}>
              <p className="p-0 text-sm text-gray-300">
                Please enter a <span className="px-[2px] font-semibold text-gray-400">temporary</span> password that should be reset upon logging in.
              </p>
              <div className="flex w-full gap-x-4 gap-y-2">
                {/* Password */}
                <div className="w-full">
                  <Input
                    name="password"
                    label="Password"
                    type={"password"}
                    inline={true}
                    value={state.password}
                    autoComplete={"new-password"}
                    onChange={(e) =>
                      setState({
                        ...state,
                        password: e.target.value,
                      })
                    }
                  />
                </div>

                {/* Confirm Password */}
                <div className="w-full">
                  <Input
                    name="confirm-password"
                    label="Confirm Password"
                    type={"password"}
                    inline={true}
                    value={state.confirm_password}
                    autoComplete={"new-password"}
                    onChange={(e) =>
                      setState({
                        ...state,
                        confirm_password: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col">
            <ToggleHeader
              title="SSO auto update"
              subtitle="Allow user's data auto update while login with SSO"
              position="left">
              <Toggle
                checked={!state?.disabled_sso_auto_updated}
                onChange={() => {
                  setState({ ...state, disabled_sso_auto_updated: !state?.disabled_sso_auto_updated });
                }}
              />
            </ToggleHeader>
          </div>
          <div className="flex w-full flex-col">
            <ToggleHeader
              title="Dataset & bulk upload auto update"
              subtitle="Allow user's data auto update while import from Dataset and bulk upload"
              position="left">
              <Toggle
                checked={!state?.disabled_dataset_auto_updated}
                onChange={() => {
                  setState({ ...state, disabled_dataset_auto_updated: !state?.disabled_dataset_auto_updated });
                }}
              />
            </ToggleHeader>
          </div>
        </TextAccordion>

        <div className="relative mt-4 w-full">
          <MultiSelectObject
            inline={true}
            defaultOptions={groupList?.map((group) => {
              return { key: group.id || group._id, value: group.name, selected: false, object: group };
            })}
            searchableFields={["name"]}
            title="Assign groups"
            onChange={handleSelectedGroups}
          />
        </div>

        {user?.type === "admin" && (
          <>
            <hr className="mt-8 w-full" />
            <div className="relative mt-4 flex w-full">
              <div
                className="group flex items-center gap-x-4 rounded border border-transparent px-2 py-1 text-gray-600 transition-all duration-200 hover:cursor-pointer hover:border-highlightColor hover:text-highlightColor"
                onClick={() => {
                  setAddExistingUser(true);
                  setAddUser(false);
                  setUserType("participant");
                }}>
                <IconsWithPlus
                  strokeColor={"stroke-gray-600 group-hover:stroke-highlightColor"}
                  item={{ icon: UsersIcon }}
                />
                Add an existing user
              </div>
            </div>
          </>
        )}
      </Modal>
      {/* User import modal */}
      <Modal
        title={props.workspaceDetails?.name}
        secondaryTitle="Import User"
        isOpen={isImportModalOpen}
        onCancel={onUserModalClose}
        onSuccess={() => onSubmit(true)}
        defaultOptions={{
          onSuccessButtonText: "Yes",
          onCancelButtonText: "No",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successModalStart,
        }}>
        <h1>An user with this email already exists on DOMO, do you still want to import on our Portal?</h1>
        <h3 className="ml-5 mt-5 font-bold">{domoUser?.name || ""}</h3>
        <h5 className="ml-5 mt-1 font-semibold">{domoUser?.email || ""}</h5>
      </Modal>
      <Modal
        title={props.workspaceDetails?.name}
        secondaryTitle={`Add an existing user`}
        isOpen={addExistingUser}
        onCancel={() => setAddExistingUser(false)}
        onSuccess={() => {
          onSubmit(allUsers.filter((user) => selectedUsers.includes(user._id)).map((user) => ({ ...user, groups: selectedExistingUserGroups })));
        }}
        defaultOptions={{
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successModalStart,
        }}
        defaultStyles={{
          overFlowYVisible: true,
        }}>
        <MultiSelectObjectUsers
          usersLoaded={userLoaded}
          users={allUsers?.filter((singleUser) => !users?.map((user) => user._id).includes(singleUser._id))}
          selectedUserIds={selectedUsers}
          handleSelectedUsers={(allSelectedUsers) => {
            setSelectedUsers(allSelectedUsers.filter((user) => user.selected).map((user) => user.object._id));
          }}
        />
        {props.workspaceDetails?.workspace_type === "JWT_FULL_EMBED" && (
          <div className="relative mt-4 w-full">
            <label className="mb-1 flex justify-between text-sm font-medium text-gray-700">Domo Role</label>
            <SelectMenu
              options={roles}
              setOption={(e) => setUserType(e.value)}
              startIndex={roles.findIndex((rl) => rl.value === userType) > 0 ? roles.findIndex((rl) => rl.value === userType) : 0}
            />
          </div>
        )}
      </Modal>
      {user?.type === "admin" && (
        <>
          {/* Users delete modal */}
          <Modal
            title="User(s)"
            secondaryTitle="Delete"
            isOpen={isDeleteMultiUserModalOpen}
            onCancel={() => setIsDeleteMultiUserModal(false)}
            onSuccess={deleteMultiuser}
            isLoading={disableActions}
            defaultOptions={{
              onSuccessButtonText: "Delete",
            }}>
            <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete the selected User(s)?</div>
          </Modal>
          {/* Users resend reset password email send modal */}
          <Modal
            title="Users"
            secondaryTitle="Reset password email "
            isOpen={isResendMultiUserResetPasswordModalOpen}
            onCancel={() => setIsResendMultiUserResetPasswordModal(false)}
            onSuccess={inviteMultiUserResetPassword}
            isLoading={disableActions}
            defaultOptions={{
              onSuccessButtonText: "Reset Password Email(s)",
            }}>
            <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to resend reset password email(s) to the selected {checkedUsers.length > 1 ? "users" : "user"}?</div>
          </Modal>
          {/* Users resend email send modal */}
          <Modal
            title="Users"
            secondaryTitle="Resend welcome email"
            isOpen={isResendMultiUserModalOpen}
            onCancel={() => setIsResendMultiUserModal(false)}
            onSuccess={inviteMultiUser}
            isLoading={disableActions}
            defaultOptions={{
              onSuccessButtonText: "Resend Welcome Email(s)",
            }}>
            <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to resend welcome email(s) to the selected {checkedUsers.length > 1 ? "users" : "user"}?</div>
          </Modal>
          {/* Users status change modal */}
          <Modal
            title="Users"
            secondaryTitle="Active status"
            isOpen={isMultiUserStatusUpdateModelOpen}
            onCancel={() => setIsMultiUserStatusUpdateModelOpen(false)}
            onSuccess={() => multiUserStatusUpdate(multiUserStatusUpdateValue)}
            isLoading={disableActions}
            defaultOptions={{
              onSuccessButtonText: multiUserStatusUpdateValue === "ACTIVATE" ? "Activate" : "Deactivate",
            }}>
            <div className="mt-4 grid gap-y-3">
              <div className="flex h-9 max-w-[140px] items-center rounded-full border border-gray-200 shadow">
                <Members group={{ _id: multiUserStatusUpdateValue === "ACTIVATE" ? "activate" : "deactivate", users: users?.filter((user) => checkedUsers.includes(user._id)) }} />
              </div>
              <div className="flex whitespace-nowrap text-sm text-gray-500">
                Are you sure you want to <span className="px-1 font-black">{multiUserStatusUpdateValue}</span> the ({checkedUsers.length}) selected {checkedUsers.length > 1 ? "users" : "user"}?
              </div>
            </div>
          </Modal>
          {/* Users assign to workspace modal */}
          <Modal
            title={`Assign Workspace`}
            isOpen={workspaceModalOpen}
            onCancel={() => {
              setWorkspaceModalOpen(false);
              setSelectedWorkspaces([]);
            }}
            onSuccess={assignWorkspaceUsers}
            defaultStyles={{
              overFlowYVisible: true,
            }}
            defaultOptions={{
              onSuccessLoaderStart: disableActions,
              onSuccessLoaderVisible: disableActions,
            }}>
            <div className="relative min-h-[200px] py-4">
              <MultiSelectObject
                defaultOptions={allWorkspaces?.map((workspace) => {
                  return { key: workspace._id, value: workspace.name, selected: selectedWorkspaces.includes(workspace?._id), object: workspace };
                })}
                searchableFields={["name"]}
                title="Assign workspace"
                onChange={(workspacesArray) => {
                  let updatedSelectedWorkspaces = workspacesArray.reduce((workspaces, workspace) => (workspace.selected ? [...workspaces, workspace.key] : workspaces), []);
                  setSelectedWorkspaces(updatedSelectedWorkspaces);
                }}
              />
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, {
  deleteUser,
  getGroupData,
  addUser,
  manageUserRole,
})(UsersList);
