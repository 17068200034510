import { apiUrl } from "src/config/host";
import axios from "axios";
import { toast } from "react-toastify";
import { urlBuilder } from "src/helpers/urlBuilder";
import { refreshTokenVerify } from "src/actions/auth";

const builderQueryString = (queries = []) => {
  let queryString = "";
  if (queries) {
    const filteredQueries = Object.fromEntries(Object.entries(queries).filter(([key, value]) => value != null));
    queryString = new URLSearchParams(filteredQueries);
    queryString = "?" + queryString.toString();
  }

  return queryString;
};

let refreshTokenPromise = null;
const ongoingRequests = new Map(); // Map to track ongoing requests by dynamic URL

const refreshAccessToken = async () => {
  if (!refreshTokenPromise) {
    refreshTokenPromise = refreshTokenVerify();
    refreshTokenPromise
      .then(() => {
        refreshTokenPromise = null;
      })
      .catch(() => {
        refreshTokenPromise = null;
      });
  }
  return refreshTokenPromise;
};

const apiRequest = async (method = "put", url = "", payload = { body: null, params: {}, queries: null }, options = { useCustomUrl: false, showToastMessage: false, signal: null, headers: {}, onSuccess: () => {}, responseType: "json", onFailure: () => {} }) => {
  try {
    let queryString = "";
    if (payload?.queries != null) {
      queryString = builderQueryString(payload?.queries);
    }

    let accessToken = localStorage.getItem("access_token") || payload?.body?.token || options.headers?.Authorization || null;

    let headers = { "Content-Type": "application/json", Accept: "application/json" };

    if (accessToken) {
      headers = {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
        ...options.headers,
      };
    }

    // Construct the dynamic URL
    const dynamicUrl = urlBuilder(url, payload?.params || {});

    // Check if there's an ongoing request for the same dynamic URL
    if (ongoingRequests.has(dynamicUrl)) {
      // Abort the ongoing request associated with the dynamic URL
      ongoingRequests.get(dynamicUrl).abort();
    }

    const controller = new AbortController();
    ongoingRequests.set(dynamicUrl, controller);

    const signal = options.signal || controller.signal;

    const request = axios.create({
      baseURL: options.useCustomUrl ? "" : apiUrl,
      headers,
      responseType: options.responseType || "json",
      signal,
    });

    const res = await request[method.toLowerCase()](dynamicUrl + queryString, payload?.body);

    if (res.status === 200) {
      if (options.showToastMessage) {
        if (res.data?.message) {
          toast.success(res.data?.message);
          options.onSuccess(res);
        }
        if (res.data?.data?.message) {
          toast.success(res.data?.data?.message);
          options.onSuccess(res);
        }
      }
      ongoingRequests.delete(dynamicUrl); // Remove the dynamic URL from ongoing requests
      return res;
    } else {
      if (options.showToastMessage) {
        if (res.data?.message) {
          toast.error(res.data?.message);
          options.onFailure();
        }
        if (res.data?.data?.message) {
          toast.error(res.data?.data?.message);
          options.onFailure();
        }
      }
      throw new Error(res?.message || res);
    }
  } catch (error) {
    if (error.response && error.response.status === 401 && error.response.data.expired) {
      await refreshAccessToken();
      return apiRequest(method, url, payload, options);
    } else {
      if (options.showToastMessage) {
        toast.error(error?.response?.data.message || error?.response?.data);
      }
      throw new Error(error?.response?.data.message || error?.response?.data || error);
    }
  }
};

// Function to abort all ongoing requests
const abortOngoingRequests = () => {
  // eslint-disable-next-line no-unused-vars
  for (let [url, controller] of ongoingRequests.entries()) {
    controller.abort();
  }
  ongoingRequests.clear();
};

export { builderQueryString, apiRequest, abortOngoingRequests };
