import React, { Fragment } from "react";
import NavItem from "./NavItem";
import Button from "src/components/Shared/Buttons/Button";
import { isSVGString } from "src/helpers";
import Image from "src/components/Shared/Image";
import { noFavicon } from "src/config/host";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { classNames } from "src/helpers/classNames";

const LeftNavigationPanel = ({ pageList, currentNavigation, updateNavigation = () => {}, setPageList = () => {} }) => {
  return (
    <nav className="flex h-full flex-col justify-between bg-leftNavColor px-2 py-4 shadow-md">
      <div className="grid gap-y-1">
        {pageList.map((item) => (
          <Fragment key={item.key}>
            {item.permissions.length === 1 ? (
              <NavItem
                item={item.permissions[0]}
                activeItem={currentNavigation}
                group={item}
                onClick={updateNavigation}
              />
            ) : item.permissions.length > 0 ? (
              <div className="relative">
                <Button
                  onClick={() => {
                    setPageList(
                      pageList.map((page) => {
                        if (page.key === item.key) {
                          return { ...page, toggle: !item.toggle };
                        } else {
                          return page;
                        }
                      }),
                    );
                  }}
                  type="button"
                  version="default"
                  className="flex w-full items-center rounded-md px-2 py-2 text-leftNavTextColor transition-all duration-300">
                  {item.image && (
                    <div className="nav-icon mr-3 h-6 w-6 flex-shrink-0">
                      {isSVGString(item.image) ? (
                        <div
                          className=""
                          dangerouslySetInnerHTML={{ __html: item.image }}
                        />
                      ) : (
                        <Image
                          image={item.image}
                          failImage={noFavicon}
                          alt={"Groups"}
                        />
                      )}
                    </div>
                  )}
                  <div className="w-full text-left font-medium transition-all duration-300">{item.name}</div>
                  <div className="flex h-8 w-4 min-w-[16px] items-center justify-center text-sm">
                    <ChevronRightIcon className={classNames(!item.toggle ? "" : "rotate-90")} />
                  </div>
                </Button>
                <div className={"scrollbar max-h-[400px] space-y-2 overflow-auto pl-3 transition-all duration-300 " + (!item.toggle ? "h-0" : "")}>
                  {item.permissions.map((permission) => (
                    <Fragment key={permission.key}>
                      <NavItem
                        item={permission}
                        activeItem={currentNavigation}
                        onClick={updateNavigation}
                      />
                    </Fragment>
                  ))}
                </div>
              </div>
            ) : (
              <NavItem
                item={item}
                activeItem={currentNavigation}
                onClick={updateNavigation}
              />
            )}
          </Fragment>
        ))}
      </div>
    </nav>
  );
};

export default LeftNavigationPanel;
