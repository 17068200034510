import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject/MultiSelectObject";
import Modal from "src/components/Shared/Modal";
import { apiRequest } from "src/async/apiUtils";
import { useParams } from "react-router";

const GroupAssignToUserModal = ({ isOpen = false, title = "", secondaryTitle = "", defaultWorkspaceId = "", setRefresh = () => {}, ...props }) => {
  const { id } = useParams();
  const [selectedGroup, setSelectedGroup] = useState([]);

  const handleSelectedGroups = (groupsArray) => {
    let updatedSelectedGroups = groupsArray.reduce((groups, group) => (group.selected ? [...groups, group.key] : groups), []);
    setSelectedGroup(updatedSelectedGroups);
  };

  const onSubmit = async () => {
    try {
      const params = {workspace_id: defaultWorkspaceId, user_id: id};
      const { data } = await apiRequest("post", `/workspaces/:workspace_id/users/:user_id/group-assign`, {
        body: {
          groups: selectedGroup,
        },
        params
      });
      if (data.status === 200) {
        setRefresh();
        props.setIsOpen(!isOpen);
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setSelectedGroup(props?.defaultGroups);
  }, [props?.defaultGroups]);

  return (
    <Modal
      title={title}
      secondaryTitle={secondaryTitle}
      isOpen={isOpen}
      onCancel={() => {
        props.setIsOpen(!isOpen);
      }}
      onSuccess={onSubmit}
      defaultOptions={{
        onSuccessButtonText: "Save",
      }}>
      <div className="relative w-full space-y-4">
        <div className="relative w-full mb-4">
          <MultiSelectObject
            defaultOptions={props?.groups?.map((group) => {
              return { key: group._id, value: group.name, selected: selectedGroup.includes(group?._id), object: group };
            })}
            searchableFields={["name"]}
            title="Assign groups"
            onChange={handleSelectedGroups}
            inline={true}
          />
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};
export default connect(mapStateToProps, {})(GroupAssignToUserModal);
