import React from "react";

const BetaHeader = ({ title, subtitle, horizontalRow = true, isBeta = true }) => {
  return (
    <div className="grid gap-y-4">
      {horizontalRow && <hr className="mb-5" />}
      <div className="mb-4 w-full space-y-2">
        <h3 className="pb-1 text-xl font-semibold text-gray-500">
          {title} <span className="font-normal text-gray-300">{isBeta && "(BETA)"}</span>
        </h3>
        {subtitle && <div className="max-w-3xl font-medium leading-tight text-gray-600">{subtitle}</div>}
      </div>
    </div>
  );
};

export default BetaHeader;
