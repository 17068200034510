import React, { useEffect, useState } from "react";
import { getPreviousPageDetails } from "src/actions/page";
import Modal from "src/components/Shared/Modal";
import ContentError from "src/components/impersonate/ContentError";
import ContentLoading from "src/components/impersonate/ContentLoading";
import ContentTypesRouter from "src/components/impersonate/ContentTypesRouter";

const PagePreviewModal = ({ isOpen = false, pageDetails = null, onCancel = () => {} }) => {
  const [loadingPage, setLoadingPage] = useState(true);
  const [content, setContent] = useState(null);
  const [contentError, setContentError] = useState(null);

  const handlePageGet = async () => {
    try {
      setContentError(null);
      setContent(null);
      setLoadingPage(true);
      const { data } = await getPreviousPageDetails(pageDetails?.body);
      setContent(data);
      setLoadingPage(false);
    } catch (error) {
      setLoadingPage(false);
      setContentError("page_error");
    }
  };

  const handleExternalPage = () => {
    if (pageDetails?.page_link_newtab) {
      window.open(pageDetails?.page_link);
    } else {
      window.open(pageDetails?.page_link, "_self");
    }
  };

  useEffect(() => {
    if (isOpen && pageDetails?.body) {
      if (pageDetails?.page_type === "EXTERNAL_LINK") {
        handleExternalPage();
      }
      handlePageGet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, pageDetails]);

  return (
    <Modal
      title="Page"
      secondaryTitle="Preview"
      isOpen={isOpen}
      onCancel={onCancel}
      size="xxxxxl"
      defaultOptions={{
        onCancelButtonVisible: false,
        onSuccessButtonVisible: false,
      }}>
      <div className="h-[calc(100vh-10rem)]">
        {contentError ? (
          <ContentError
            pageError={contentError}
            showRefreshButton={false}
          />
        ) : (
          <>
            {loadingPage ? (
              <ContentLoading menuType={""} />
            ) : (
              <div className="w-full">
                <ContentTypesRouter
                  content={content}
                  setError={() => {}}
                />
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default PagePreviewModal;
