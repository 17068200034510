import { Navigate } from "react-router";
import { FeatureFlags } from "src/context/FeatureFlagContext";

export const FeatureFlagRoute = ({ children, feature = "" }) => {
    return (
      <FeatureFlags.Consumer>
        {(flags) => {
          if (Array.isArray(flags)) {
            let foundFlag = flags?.find((flag) => flag.name?.toLowerCase() === feature?.toLowerCase());
            return <>{typeof foundFlag === "undefined" || foundFlag.active === true ? <>{children}</> : <Navigate replace to="/404" />}</>;
          }
          return null;
        }}
      </FeatureFlags.Consumer>
    );
  };