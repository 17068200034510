import { WindowIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { getAuthorizedUser } from "src/actions/auth";
import { getOperators } from "src/actions/operator";
import { getWorkspacePages } from "src/actions/page";
import { editWorkspaceGroupDetails, manageGroupUser } from "src/actions/workspace";
import { apiRequest } from "src/async/apiUtils";
import DisplayPageForm from "src/components/PageForm/DisplayPageForm";
import Button from "src/components/Shared/Buttons/Button";
import IconsWithPlus from "src/components/Shared/Icons/IconsWithPlus";
import { classNames } from "src/helpers/classNames";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import NoData from "src/components/Shared/NoData/NoData";
import { v4 } from "uuid";
import PagePreviewModal from "src/components/Pages/PageModals/PagePreviewModal";

const WorkspaceIndividualPermission = ({ getAuthorizedUser, viewOnly = false, userId = "", workspaceId = "", allTrustedAttributes = [], ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const [permissionState, setPermissionState] = useState([]);
  const [defaultPermissionState, setDefaultPermissionState] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [permissionChanges, setPermissionChanges] = useState(0);

  // Page preview
  const [prevModalOpen, setPrevModalOpen] = useState(false);
  const [prevPageDetails, setPrevPageDetails] = useState(null);

  const permissionJson = {
    _id: null,
    id: v4(),
    page_id: null,
    filters: [],
  };

  const loadPermissionData = async (ac = {}) => {
    try {
      let workspaceGroupsData = {};

      try {
        workspaceGroupsData = await apiRequest("GET", `/workspaces/:workspace_id/users/:user_id/permissions`, {
          params: { workspace_id: workspaceId, user_id: userId },
        });
        workspaceGroupsData = workspaceGroupsData.data;
        if (workspaceGroupsData.status === 200) {
          setDefaultPermissionState(
            workspaceGroupsData.data.length > 0
              ? workspaceGroupsData.data.map((permission) => {
                  return {
                    ...permission,
                    id: v4(),
                    displayNameShow: permission?.page_alias ? true : false,
                    displayNameChanges: true,
                    filters: permission.filters.map((filter) => {
                      return {
                        ...filter,
                      };
                    }),
                  };
                })
              : [],
          );
          setPermissionState(
            workspaceGroupsData.data.length > 0
              ? workspaceGroupsData.data.map((permission) => {
                  return {
                    ...permission,
                    id: v4(),
                    displayNameShow: permission?.page_alias ? true : false,
                    displayNameChanges: true,
                    filters: permission.filters.map((filter) => {
                      return {
                        ...filter,
                      };
                    }),
                  };
                })
              : [],
          );
          setLoaded(true);
          return workspaceGroupsData.data;
        } else {
          setLoaded(true);
          throw new Error(workspaceGroupsData.message);
        }
      } catch (error) {
        setLoaded(true);
        throw new Error(error.message);
      }
    } catch (error) {
      setLoaded(true);
      // console.dir("ERROR:", error);
    }
  };

  useEffect(() => {
    const ac = new AbortController();
    const loadPageAndOperatorData = async () => {
      props
        .getWorkspacePages({ workspace_id: workspaceId }, ac.signal)
        .then((data) => {
          data = data.filter((page) => page.page_type !== "LANDING_PAGE");
          setPageList(data);
          props
            .getOperators({}, ac.signal)
            .then((oprtrs) => {
              loadPermissionData();
            })
            .catch((error) => setLoaded(true));
        })
        .catch((error) => setLoaded(true));
    };
    if (workspaceId) {
      loadPageAndOperatorData();
    }
    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, props.me]);

  const addNewPermission = (e) => {
    e.preventDefault();
    setPermissionState([...permissionState, permissionJson]);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(permissionState, result.source.index, result.destination.index);
    setPermissionState([...items]);
    setPermissionChanges(permissionChanges + 1);
  };

  const onClear = () => {
    setPermissionState(defaultPermissionState);
    setPermissionChanges(0);
  };

  const savePermissionChanges = async () => {
    try {
      const { data } = await apiRequest(
        "put",
        `/workspaces/:workspace_id/users/:user_id/permissions`,
        {
          body: {
            permissions: permissionState,
          },
          params: {
            workspace_id: workspaceId,
            user_id: userId,
          },
        },
        { showToastMessage: true, onSuccess: () => {}, onFailure: () => {} },
      );
      setDefaultPermissionState([
        ...permissionState.map((permission, index) => {
          let newData = data.data.find((dt, i) => i === index);
          return {
            ...permission,
            filters: permission.filters.map((filter) => {
              return {
                ...filter,
              };
            }),
            _id: newData?._id,
          };
        }),
      ]);
      setPermissionState([
        ...permissionState.map((permission, index) => {
          let newData = data.data.find((dt, i) => i === index);
          return {
            ...permission,
            filters: permission.filters.map((filter) => {
              return {
                ...filter,
              };
            }),
            _id: newData?._id,
          };
        }),
      ]);
      setPermissionChanges(0);
    } catch (error) {}
  };

  const assignDisplayName = async (permission) => {
    try {
      const { data } = await apiRequest(
        "put",
        `/workspaces/:workspace_id/users/:user_id/display-name`,
        {
          body: {
            permission: permission,
          },
          params: {
            workspace_id: workspaceId,
            user_id: userId,
          },
        },
        { showToastMessage: true, onSuccess: () => {}, onFailure: () => {} },
      );
      setDefaultPermissionState([
        ...permissionState.map((p, index) => {
          if (index === permission?.ordering) {
            return {
              ...p,
              displayNameShow: permission?.page_alias ? true : false,
              _id: data?.data?._id,
              displayNameChanges: true,
            };
          } else {
            return p;
          }
        }),
      ]);
      setPermissionState([
        ...permissionState.map((p, index) => {
          if (index === permission?.ordering) {
            return {
              ...p,
              displayNameShow: permission?.page_alias ? true : false,
              _id: data?.data?._id,
              displayNameChanges: true,
            };
          } else {
            return p;
          }
        }),
      ]);
    } catch (error) {}
  };

  const pagePreview = async (page) => {
    setPrevPageDetails({ page, body: { user_id: userId, page_id: page?.page_id, permission_id: page?._id, workspace_id: workspaceId } });
    setPrevModalOpen(true);
  };

  return (
    <>
      {loaded ? (
        <div className="relative grid w-full gap-y-4">
          {authorizeUserComponentAccess(props.me, workspaceId, "user", ["update"]) && (
            <div className="flex justify-end gap-2">
              <Button
                version="secondary"
                onClick={addNewPermission}>
                <IconsWithPlus
                  strokeColor={"stroke-highlightColor"}
                  item={{ icon: WindowIcon }}
                />
              </Button>
            </div>
          )}
          {permissionState?.length > 0 ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    className="space-y-4"
                    {...provided.droppableProps}
                    ref={provided.innerRef}>
                    {permissionState.map((permission, index) => {
                      return (
                        <Draggable
                          key={permission?._id || permission?.id}
                          draggableId={permission?._id || permission?.id}
                          index={index}>
                          {(provided, snapshot) => (
                            <DisplayPageForm
                              id={permission?._id || permission?.id}
                              ordering={index}
                              permission={permission}
                              setPermission={(e) => {
                                setPermissionState((preData) =>
                                  preData.map((p, i) => {
                                    if (i === index) {
                                      return e;
                                    } else {
                                      return p;
                                    }
                                  }),
                                );
                              }}
                              pageOptions={pageList}
                              operatorOptions={props.operators}
                              removePermission={(e) => {
                                setPermissionChanges(permissionChanges + 1);
                                setPermissionState(permissionState.filter((p, i) => i !== index));
                              }}
                              pagePreview={pagePreview}
                              trustedAttributeArr={allTrustedAttributes}
                              innerRef={provided.innerRef}
                              draggableProps={provided.draggableProps}
                              dragHandleProps={provided.dragHandleProps}
                              authorizeUserComponentAccessPermission={authorizeUserComponentAccess(props.me, workspaceId, "user", ["update"])}
                              setDataChanges={setPermissionChanges}
                              dataChanges={permissionChanges}
                              assignDisplayName={assignDisplayName}
                            />
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <div className="h-[60px]">
              <NoData />
            </div>
          )}
          {authorizeUserComponentAccess(props.me, workspaceId, "user", ["update"]) && (
            <div className="mt-20 flex w-full justify-end gap-x-4 sm:mt-4">
              <Button
                version="gray"
                onClick={onClear}
                disabled={permissionChanges < 1}>
                Cancel
              </Button>
              <Button
                onClick={savePermissionChanges}
                disabled={permissionChanges < 1}>
                Save
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div className={classNames("h-20 transition-all duration-300")}>
          <Preloader
            className="h-[80px]"
            circleDimension="10"
            size="lg"
          />
        </div>
      )}
      {/* { Page Preview} */}
      <PagePreviewModal
        pageDetails={prevPageDetails}
        isOpen={prevModalOpen}
        onCancel={() => {
          setPrevModalOpen(false);
          setPrevPageDetails(null);
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    pages: Object.values(state.pages),
    operators: Object.values(state.operators),
    me: state.auth.user,
  };
};
export default connect(mapStateToProps, {
  editWorkspaceGroupDetails,
  getAuthorizedUser,
  getWorkspacePages,
  manageGroupUser,
  getOperators,
})(WorkspaceIndividualPermission);
