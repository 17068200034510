import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import Section from "src/components/Shared/Containers/Section";
import { H3, H5 } from "src/components/Shared/Text/Headers";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { apiRequest } from "src/async/apiUtils";
import Input from "src/components/Shared/Forms/FormikForms/Input";
import { manageSiteSettings } from "src/actions/site";
import TextAccordion from "src/components/Shared/Accordions/TextAccordion";
import Button from "src/components/Shared/Buttons/Button";
import { toast } from "react-toastify";
import { manageWorkspaceSmtpSettings, getWorkspaceSmtpSettings } from "src/actions/workspace";

const SmtpSettings = ({ siteData, manageSiteSettings, workspaceId = null }) => {
  const [siteEmail, setSiteEmail] = useState("");
  const [smtpEmail, setSmtpEmail] = useState("");
  const [smtpHost, setSmtpHost] = useState("");
  const [smtpUser, setSmtpUser] = useState("");
  const [smtpPassword, setSmtpPassword] = useState("");
  const [smtpPort, setSmtpPort] = useState("");
  const [smtpSecure, setSmtpSecure] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const [isEmailSendLoading, setIsEmailSendLoading] = useState(false);
  const [isEmailSendDisabled, setIsEmailSendDisabled] = useState(true);
  const [email, setEmail] = useState("");

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);
      setIsDisabled(true);

      let body = {
        site_email: values.siteEmail,
        smtp_email: values.smtpEmail,
        smtp_host: values.smtpHost,
        smtp_user: values.smtpUser,
        smtp_port: values.smtpPort,
        smtp_secure: values.smtpSecure,
      };

      if (values.smtpPassword) {
        body = { ...body, smtp_password: values.smtpPassword };
      }
      let message;
      if (workspaceId) {
        message = await manageWorkspaceSmtpSettings({ workspace_id: workspaceId, ...body });
      } else {
        message = await manageSiteSettings(body);
      }
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsDisabled(true);
      setIsLoading(false);
    }
  };

  const sendTestEmail = async () => {
    try {
      setIsEmailSendLoading(true);
      setIsEmailSendDisabled(true);

      let body = {
        email,
      };
      await apiRequest("PUT", workspaceId ? `/workspaces/:workspace_id/send/test-email` : `/send/test-email`, { body, params: { workspace_id: workspaceId } }, { showToastMessage: true, onSuccess: () => {}, onFailure: () => {} });
      setEmail("");
    } catch (error) {
    } finally {
      setIsEmailSendDisabled(true);
      setIsEmailSendLoading(false);
    }
  };

  const onClear = () => {
    setIsDisabled(true);
  };

  const getDefaultValue = (data) => {
    setSiteEmail(data?.site_email || "");
    setSmtpEmail(data?.smtp_email || "");
    setSmtpHost(data?.smtp_host || "");
    setSmtpUser(data?.smtp_user || "");
    setSmtpPassword(data?.smtp_password || "");
    setSmtpPort(data?.smtp_port || "");
    setSmtpSecure(data?.smtp_secure);
  };

  const getWorkspaceSmtp = async () => {
    const data = await getWorkspaceSmtpSettings({ workspace_id: workspaceId });
    getDefaultValue(data);
  };

  useEffect(() => {
    if (workspaceId) {
      getWorkspaceSmtp();
    } else {
      getDefaultValue(siteData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteData, workspaceId]);

  const getValidationSchema = (prevSmtpPassword) => {
    return Yup.object().shape({
      siteEmail: Yup.string().required("Required"),
      smtpEmail: Yup.string().required("Required"),
      smtpHost: Yup.string().required("Required"),
      smtpUser: Yup.string().required("Required"),
      smtpPort: Yup.number().required("Required"),
      smtpSecure: Yup.boolean().required("Required"),
      smtpPassword: prevSmtpPassword ? Yup.string().notRequired() : Yup.string().required("Required"),
    });
  };

  const initialValues = {
    siteEmail,
    smtpEmail,
    smtpHost,
    smtpUser,
    smtpPassword: "",
    smtpPort,
    smtpSecure,
    email: email,
    prevSmtpPassword: smtpPassword, // Assuming smtpPassword is the previous password
  };

  const validationSchema = getValidationSchema(initialValues.prevSmtpPassword);

  return (
    <Section>
      <div className="grid gap-y-8">
        <H3 caption="Adjust settings to allow for your clients to recieve emails from your domain.">SMTP Server Settings</H3>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            onSubmit(values);
          }}>
          {({ values, errors, touched, setFieldValue, resetForm }) => {
            const handleOnChange = (e) => {
              if (e.target.name === "email") {
                setEmail(e.target.value);
                setIsEmailSendDisabled(e.target.value ? false : true);
              } else {
                setIsDisabled(false);
              }
            };
            return (
              <Form onChange={handleOnChange}>
                <div className="grid space-y-4 sm:space-y-8">
                  <div className="flex w-full flex-wrap justify-between">
                    <div className="flex w-full flex-wrap gap-y-6">
                      {/* Contact email */}
                      <div className="flex w-full flex-col gap-3 sm:flex-row">
                        <div className="w-full sm:w-1/2">
                          <Input
                            name="smtpEmail"
                            label="SMTP email"
                            inline={true}
                          />
                        </div>

                        <div className="w-full sm:w-1/2">
                          <Input
                            name="siteEmail"
                            inline={true}
                            label={
                              <p>
                                Support contact <span className="pl-2 text-gray-300">*optional email</span>
                              </p>
                            }
                          />
                        </div>
                      </div>
                      <div className="flex w-full flex-col gap-3 sm:flex-row">
                        {/* SMTP user */}
                        <div className="w-full sm:w-1/2">
                          <Input
                            name="smtpUser"
                            label="User"
                            inline={true}
                          />
                        </div>
                        {/* SMTP password */}
                        <div className="w-full sm:w-1/2">
                          <Input
                            placeholder={smtpPassword}
                            name="smtpPassword"
                            label="Password"
                            type="password"
                            autoComplete="new-password"
                            inline={true}
                          />
                        </div>
                      </div>
                      <div className="flex w-full flex-col gap-3 sm:flex-row">
                        {/* SMTP host */}
                        <div className="w-full sm:w-1/2">
                          <Input
                            name="smtpHost"
                            label="Host"
                            inline={true}
                          />
                        </div>
                        {/* SMTP port */}
                        <div className="relative w-full sm:w-1/2">
                          {/* SMTP secure */}
                          <div className="absolute right-4 top-2 z-30 ml-2 flex cursor-pointer items-center gap-x-2 text-xs text-gray-700 opacity-70">
                            <input
                              className="cursor-pointer rounded text-gray-300 focus:ring-highlightColor"
                              type="checkbox"
                              checked={values.smtpSecure}
                              name="smtpSecure"
                              onChange={(e) => setFieldValue("smtpSecure", e.target.checked)}
                            />
                            <label className="cursor-pointer">SMTP Secure</label>
                          </div>
                          <div className="w-full">
                            <Input
                              name="smtpPort"
                              label="SMTP Port"
                              inline={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex w-full flex-col items-start justify-between gap-4 sm:flex-row">
                    <div className="w-full max-w-4xl flex-shrink">
                      <TextAccordion
                        headerTextPosition="left"
                        headerText="Advanced details">
                        <div className="-ml-3 flex w-full flex-col justify-between  gap-y-3">
                          <H5 caption="Enter in a testing email address and see if an email comes through.">Test your SMTP connection</H5>
                          <div className="ml-3 flex w-full max-w-2xl items-center  gap-5">
                            <div className="relative w-full">
                              <Input
                                name="email"
                                label="Email address"
                                inline={true}
                              />
                            </div>
                            <div className="relative">
                              <Button
                                className="px-3"
                                disabled={isEmailSendDisabled}
                                onClick={sendTestEmail}
                                type="button">
                                Submit{isEmailSendLoading ? <ArrowPathIcon className="ml-1 h-5 w-5 animate-spin" /> : <></>}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </TextAccordion>
                    </div>
                    <div className="flex flex-shrink-0 justify-between gap-x-4 pt-2">
                      <Button
                        version="gray"
                        disabled={isDisabled}
                        onClick={() => {
                          onClear();
                          resetForm();
                        }}>
                        Cancel
                      </Button>
                      <Button
                        disabled={isDisabled}
                        type="submit">
                        Save{isLoading ? <ArrowPathIcon className="ml-1 h-5 w-5 animate-spin" /> : <></>}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Section>
  );
};

const mapStateToProps = (state) => {
  return {
    siteData: state.site,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(SmtpSettings);
