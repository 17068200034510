import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";

export default function MultiRowSelectedCountBubble({ count = 0, clear = () => {}, position = "-top-[10px] left-[15px]" }) {
  return (
    <>
      {count > 0 && (
        <div className={`absolute z-10 ${position} pr-6 h-5 px-1 flex gap-x-1 items-center text-xs font-light rounded-full bg-highlightColor text-white`}>
          <div className="flex gap-x-1 pl-1">
            <div>{count}</div>
            <div>Selected</div>
          </div>
          <XMarkIcon
            onClick={clear}
            className="absolute cursor-pointer top-0 right-0 p-[2px] rounded-full h-5 w-5 hover:bg-white/20 transition-all duration-100"
          />
        </div>
      )}
    </>
  );
}
