import { ArrowLongRightIcon, Bars3Icon, Cog6ToothIcon, PencilIcon, PlusIcon, Square2StackIcon, TrashIcon, WindowIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Form, Formik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/FormikForms/Input";
import QuickMenu from "src/components/Shared/QuickMenu";
import WorkspaceIntegrationFiltersItem from "src/components/Workspaces/Workspace/Settings/WorkspaceIntegration/WorkspaceIntegrationFiltersItem";
import { useDomo } from "src/context/DomoProvider";
import { classNames } from "src/helpers/classNames";
import { v4 } from "uuid";
import * as Yup from "yup";
import PillEntry from "src/components/Workspaces/PillEntry";
import DatasetRedirectsContent from "./DatasetRedirectsContent";
import AddWhereContent from "./AddWhereContent";

const DisplayPageForm = ({
  pageOptions = [],
  operatorOptions = [],
  permission = {},
  setPermission = () => {},
  updatePermission = () => {},
  removePermission = () => {},
  pagePreview = () => {},
  setDataChanges = () => {},
  assignDisplayName = () => {},
  dataChanges = 0,
  trustedAttributeArr = [],
  draggableProps,
  dragHandleProps,
  innerRef,
  workspaceDetails,
  authorizeUserComponentAccessPermission = true,
  showNestedData = true,
  hideAliasManageButton = false,
  ...props
}) => {
  const [pageType, setPageType] = useState("");
  const [embedType, setEmbedType] = useState("");
  const [pageCopied, setPageCopied] = useState(false);
  const [items, setItems] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const { onPageChange } = useDomo();

  useEffect(() => {
    if (pageCopied) {
      setTimeout(() => {
        setPageCopied(false);
      }, 6000);
    }
  }, [pageCopied]);

  const handleConditionAdd = () => {
    setPermission({
      ...permission,
      filters: [
        ...permission.filters,
        {
          id: v4(),
          column_name: "",
          operator_id: null,
          value_type: "value",
          column_value: "",
          trusted_attribute: "",
          datasource_id: "",
        },
      ],
    });
    setDataChanges(dataChanges + 1);
  };

  useEffect(() => {
    let index = pageOptions.findIndex((op) => op._id === permission?.page_id);
    if (index !== -1) {
      setPageType(pageOptions[index].page_type);
      setEmbedType(pageOptions[index].embed_type);
      onPageChange({ workspaceId: workspaceDetails?._id, pageId: pageOptions[index]._id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOptions, permission?.page_id]);

  const selectedPage = pageOptions.find((page) => page._id === permission.page_id);

  useEffect(() => {
    if (selectedPage?.page_type) {
      let itemList = [];
      if (props?.me?.type === "admin") {
        itemList.push({
          name: isEditing ? "Close" : "Change Page",
          onClick: () => {
            setIsEditing(!isEditing);
          },
        });
      }
      if (selectedPage?.page_type === "DOMO" && selectedPage?.embed_type !== "EMBED_PLATFORM") {
        itemList.push({
          name: "Add Dataset Redirects",
          component: (
            <DatasetRedirectsContent
              onClick={(data) => {
                setPermission({
                  ...permission,
                  dataset_redirects: [...(permission.dataset_redirects || []), data],
                });
                setDataChanges(dataChanges + 1);
              }}
            />
          ),
        });
      }
      if (selectedPage?.page_type === "DOMO" && selectedPage?.embed_type !== "EMBED_PLATFORM") {
        itemList.push({
          name: "Assign Datasets",
          component: (
            <AddWhereContent
              data={{ datasource_id: permission?.datasource_id }}
              onClick={(data) => {
                setPermission({
                  ...permission,
                  ...data,
                });
                setDataChanges(dataChanges + 1);
              }}
            />
          ),
        });
      }
      if (!permission?.displayNameShow) {
        itemList.push({
          name: "Add Alias",
          onClick: () => {
            setPermission({
              ...permission,
              displayNameShow: true,
            });
          },
        });
      }
      itemList.push({
        name: "Preview Page",
        disabled: permission.page_id && permission._id ? false : true,
        onClick: () => {
          if (permission.page_id && permission._id) {
            pagePreview(permission);
          }
        },
      });
      // if (props?.me?.type === "admin") {
      //   itemList.push({
      //     name: "Remove",
      //     onClick: () => {
      //       removePermission();
      //     },
      //   });
      // }
      setItems(itemList);
    } else {
      setItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataChanges, permission, selectedPage?.page_type, isEditing, props?.me?.type, props.groupId]);

  useEffect(() => {
    if (permission && !permission.page_id) {
      setIsEditing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={"relative w-full max-w-9xl " + (props?.className ? props?.className : "")}
      ref={innerRef}
      {...draggableProps}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          page_id: permission?.page_id || "",
          page_alias: permission?.page_alias || "",
        }}
        validationSchema={Yup.object({
          page_id: Yup.string().required("Required"),
        })}>
        {({ values, errors, touched, setFieldValue }) => {
          const handleOnChange = (e) => {
            if (e.target.name) {
              if (e.target.name === "page_id") {
                const page = pageOptions.find((op) => op._id === e.target.value);
                setPageType(page?.page_type || "");
                setEmbedType(page?.embed_type || "");
                setPermission({
                  ...permission,
                  filters: [],
                  datasource_id: "",
                  [e.target.name]: e.target.value || null,
                });
              } else {
                setPermission({
                  ...permission,
                  [e.target.name]: e.target.value,
                });
              }
              setDataChanges(dataChanges + 1);
            }
          };
          return (
            <Form
              onChange={handleOnChange}
              className="relative  w-full max-w-9xl rounded bg-white">
              <div className={`relative flex w-full flex-shrink-0 rounded-md border border-gray-200  ${pageType === "DOMO" ? "rounded-br-none" : ""}`}>
                {!props?.viewOnly && authorizeUserComponentAccessPermission && (
                  <Button
                    dragHandleProps={dragHandleProps}
                    type="button"
                    version="default"
                    className="flex !h-auto w-8 flex-shrink-0 cursor-move items-center justify-center border-r border-gray-200 bg-gray-100 text-gray-700 outline-none ring-0 sm:min-w-[32px]">
                    <Bars3Icon className="h-5 w-5 stroke-2" />
                  </Button>
                )}
                <div className={classNames("relative flex w-full items-start justify-between bg-white px-3 py-3 lg:items-center 2xl:px-4", props?.viewOnly ? "rounded-md" : "rounded-r-md")}>
                  <div className="flex w-full flex-shrink flex-wrap space-y-2 xl:flex-nowrap xl:space-y-0">
                    <div className="flex w-full items-center pr-3 lgx:max-w-[245px] xl:max-w-[240px] 2xl:max-w-[300px] ">
                      <div className="w-full flex-shrink">
                        {!isEditing || props?.viewOnly ? (
                          <div className="flex h-[47px] w-full max-w-60 flex-shrink items-center truncate sm:max-w-full md:h-[40px] 2xl:h-[47px]">
                            <WindowIcon className="h-7 w-7 flex-shrink-0 stroke-2 text-highlightColor" />
                            <p className="ml-3 flex-shrink truncate rounded pb-1 pt-1.5 font-semibold leading-none text-highlightColor xl:text-lg 2xl:text-xl">{pageOptions.find((pageOption) => pageOption._id === values.page_id)?.name || permission?.page_name}</p>
                          </div>
                        ) : (
                          <Input
                            name="page_id"
                            as="select"
                            inline={true}
                            label={"Select Page"}
                            value={values.page_id}
                            className="min-w-full !pr-8 2xl:min-w-[280px]">
                            <option value="">-- Select --</option>
                            {pageOptions.map((pageOption) => (
                              <option
                                key={pageOption._id}
                                value={pageOption._id}>
                                {pageOption.name}
                              </option>
                            ))}
                          </Input>
                        )}
                      </div>
                    </div>
                    {permission?.displayNameShow && (
                      <>
                        <div className="relative flex w-full flex-col pl-8 pr-2 lgx:max-w-[245px] xl:min-w-[240px] xl:max-w-[360px]">
                          <div className="absolute left-0 top-3 xl:top-4">
                            <ArrowLongRightIcon className="h-5 w-5" />
                          </div>
                          <div className="relative flex items-center space-x-1">
                            <div className="group w-full">
                              <Input
                                inline={true}
                                disabled={props?.viewOnly}
                                label="Display Name"
                                name="page_alias"
                                placeholder=""
                                value={values.page_id}
                              />
                            </div>
                            {!props?.viewOnly && authorizeUserComponentAccessPermission && (
                              <div className="flex flex-shrink-0">
                                <Button
                                  type="button"
                                  version="default"
                                  className={"flex aspect-square w-8 flex-shrink-0 items-center justify-center rounded-md text-gray-400 outline-none ring-0 transition-all duration-150 hover:bg-gray-50 hover:text-gray-500 2xl:w-10"}
                                  onClick={() => {
                                    setPermission({
                                      ...permission,
                                      page_alias: "",
                                      displayNameShow: false,
                                      displayNameChanges: true,
                                    });
                                    setDataChanges(dataChanges + 1);
                                    // if (permission?._id) {
                                    //   assignDisplayName({ ...permission, ordering: props?.ordering, page_alias: "" });
                                    // }
                                  }}>
                                  <TrashIcon className="h-5 w-5" />
                                </Button>
                              </div>
                            )}
                          </div>
                          {/* <div className="flex items-center space-x-2 my-auto">
                            <div className="text-sm font-semibold">{values.page_id}</div>
                            <div className="text rounded-full border-[1px] border-gray-100 bg-white p-1 px-2 py-1 text-xs font-semibold leading-none text-gray-400">Display Name</div>
                          </div> */}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="flex flex-shrink-0 items-center space-x-1 2xl:space-x-2">
                    {props?.me?.type === "admin" && !props?.viewOnly && (
                      <button
                        onClick={() => {
                          setIsEditing(!isEditing);
                        }}
                        className="hidden h-8 w-8 items-center justify-center gap-x-2 rounded-md text-gray-400 hover:bg-gray-50 md:flex 2xl:h-auto 2xl:w-auto 2xl:rounded-full 2xl:px-2.5 2xl:py-1">
                        {!isEditing ? (
                          <>
                            <PencilIcon className="h-5 w-5" />
                            <span className="hidden 2xl:inline-block">Change</span>
                          </>
                        ) : (
                          <>
                            <XMarkIcon className="h-5 w-5" />
                            <span className="hidden 2xl:inline-block">Close</span>
                          </>
                        )}
                      </button>
                    )}
                    {!props?.viewOnly && authorizeUserComponentAccessPermission && items.length > 0 && (
                      <QuickMenu
                        icon={
                          <div className="flex h-8 w-8 items-center justify-center rounded-md text-gray-400 hover:bg-gray-50 hover:text-gray-500 2xl:h-10 2xl:w-10">
                            <Cog6ToothIcon className="h-6 w-6" />
                          </div>
                        }
                        position="LEFT"
                        items={items}
                      />
                    )}
                    {workspaceDetails?.layout_type === "NO_NAVIGATION" && permission.page_id && (
                      <>
                        <Button
                          data-tooltip-id={`${props?.id}-custom-link`}
                          type="button"
                          version="default"
                          onClick={() => {
                            navigator.clipboard.writeText(`${props?.site?.site_domain}/workspaces/${props?.workspaceId}/page/${permission?.page_id}`);
                            setPageCopied(true);
                          }}
                          className="hidden h-8 w-8 items-center justify-center rounded-md border-gray-200 text-gray-400 outline-none ring-0 transition-all duration-200 hover:bg-gray-50 hover:text-gray-500 sm:flex 2xl:h-10 2xl:w-10">
                          <div className="relative">
                            <Square2StackIcon className={classNames("h-6 w-6 transition-all duration-150", pageCopied ? "text-green-600/70" : "")} />
                            <div className={classNames("absolute -right-2 -top-2 rounded-full bg-white opacity-0 transition-all duration-150", pageCopied ? "opacity-100" : "")}>
                              <CheckCircleIcon className="h-5 w-5 text-green-600/50" />
                            </div>
                          </div>
                        </Button>
                        <ReactTooltip
                          id={`${props?.id}-custom-link`}
                          delayShow={200}
                          positionStrategy="fixed"
                          className="rounded bg-gray-700 px-2 py-2 opacity-100">
                          <div className="font-normal leading-[10px]">Copy page link.</div>
                        </ReactTooltip>
                      </>
                    )}
                    {props?.me?.type === "admin" && !props?.viewOnly && authorizeUserComponentAccessPermission && (
                      <Button
                        type="button"
                        version="default"
                        onClick={removePermission}
                        className="my-1 flex h-8 w-8 items-center justify-center rounded-md border-gray-200 text-gray-400 outline-none ring-0 transition-all duration-200 hover:bg-gray-50 hover:text-gray-500 2xl:my-0 2xl:h-10 2xl:w-10">
                        <TrashIcon className="h-5 w-5" />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              {permission?.dataset_redirects?.length > 0 && (
                <div className="relative ml-8 grid border border-t-0 border-gray-200 bg-white px-3 py-2 sm:ml-[26px] md:ml-[32px] xl:px-4">
                  <p>
                    <span className="rounded-sm bg-gray-100 px-2 py-1 text-sm text-gray-400">Dataset switching</span>
                  </p>
                  {permission?.dataset_redirects?.map((dataset_redirect, index) => (
                    <div
                      className="flex w-full items-center gap-4"
                      key={index}>
                      <div className="mt-2 flex items-center gap-2">
                        <div className="flex flex-col opacity-50">
                          <p className="pl-1 text-sm text-gray-400">Original</p>
                          <PillEntry
                            index={index}
                            tag={dataset_redirect.original_dataset_id}
                            deleteEnabled={false}
                          />
                        </div>
                        <div className="mt-5 flex-shrink-0">
                          <ArrowLongRightIcon className="h-5 w-5 text-gray-500" />
                        </div>
                        <div className="flex flex-col">
                          <p className="pl-1 text-sm text-gray-400">Replacement</p>
                          <PillEntry
                            index={index}
                            tag={dataset_redirect.target_dataset_id}
                            deleteEnabled={false}
                          />
                        </div>
                      </div>
                      {!props?.viewOnly && authorizeUserComponentAccessPermission && (
                        <div className="ml-auto flex">
                          <QuickMenu
                            icon={
                              <div className="flex h-8 w-8 items-center justify-center rounded-md text-gray-400 hover:bg-gray-50 hover:text-gray-500 2xl:h-10 2xl:w-10">
                                <Cog6ToothIcon className="h-6 w-6" />
                              </div>
                            }
                            position="LEFT"
                            items={[
                              {
                                name: "Edit",
                                component: (
                                  <DatasetRedirectsContent
                                    data={dataset_redirect}
                                    onClick={(data) => {
                                      setPermission({
                                        ...permission,
                                        dataset_redirects: [
                                          ...permission?.dataset_redirects?.map((item, i) => {
                                            if (i === index) {
                                              return { ...item, ...data };
                                            } else {
                                              return item;
                                            }
                                          }),
                                        ],
                                      });
                                      setDataChanges(dataChanges + 1);
                                    }}
                                  />
                                ),
                              },
                              {
                                name: "Delete",
                                color: "text-red-500",
                                onClick: () => {
                                  const updatedRedirects = permission.dataset_redirects.filter((_, i) => i !== index);
                                  setPermission({
                                    ...permission,
                                    dataset_redirects: updatedRedirects,
                                  });
                                  setDataChanges(dataChanges + 1);
                                },
                              },
                            ]}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
              {permission?.datasource_id && (
                <div className="relative ml-8 grid gap-y-2 border border-t-0 border-gray-200 bg-white px-3 py-2 sm:ml-[26px] md:ml-[32px] xl:px-4">
                  <div className="flex w-full items-center">
                    <div className="relative flex flex-shrink flex-grow items-end">
                      <div className="relative ml-2 flex flex-shrink flex-grow flex-col flex-wrap items-start gap-1 whitespace-nowrap pb-2">
                        <div className="text-sm text-gray-400">Filters apply only to the following datasets:</div>
                        <div className="group relative flex flex-shrink-0 flex-wrap gap-1">
                          {permission?.datasource_id?.split(",")?.map((item, index) => (
                            <PillEntry
                              index={index}
                              wrapperClass="border border-gray-400 bg-white rounded-full "
                              tag={item}
                              onDelete={() => {
                                setDataChanges(dataChanges + 1);
                                setPermission({
                                  ...permission,
                                  datasource_id: permission?.datasource_id
                                    ?.split(",")
                                    .filter((_, i) => i !== index)
                                    ?.toString(),
                                });
                              }}
                              deleteEnabled={!props?.viewOnly && authorizeUserComponentAccessPermission}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>

      {(pageType === "DOMO" || pageType === "QUICKSIGHT" || pageType === "METABASE") && embedType !== "EMBED_PLATFORM" && (
        <>
          {(permission?.filters?.length > 0 || (!props?.viewOnly && authorizeUserComponentAccessPermission)) && (
            <div className="border-b-rounded relative ml-8 grid gap-y-2 rounded-b-md border border-t-0 border-gray-200 bg-white px-4 py-4 sm:ml-[26px] md:ml-[32px] lg:px-2 lg:py-2 xl:px-4 xl:py-4">
              {permission.filters.map((condition, index) => (
                <WorkspaceIntegrationFiltersItem
                  index={index}
                  page_id={permission?.page_id}
                  filters={permission.filters}
                  key={condition?._id || condition?.id}
                  viewOnly={props?.viewOnly}
                  from="group"
                  state={condition}
                  operators={operatorOptions}
                  trustedAttributeArr={trustedAttributeArr}
                  setState={(e) => {
                    setPermission({
                      ...permission,
                      filters: permission.filters.map((p, i) => {
                        if (i === index) {
                          return e;
                        } else {
                          return p;
                        }
                      }),
                    });
                    setDataChanges(dataChanges + 1);
                  }}
                  removeFilter={() => {
                    setDataChanges(dataChanges + 1);
                    setPermission({
                      ...permission,
                      filters: permission.filters.filter((p, i) => i !== index),
                    });
                  }}
                  isAddWhere={pageType === "DOMO" ? true : false}
                  authorizeUserComponentAccessPermission={authorizeUserComponentAccessPermission}
                />
              ))}
              {!props?.viewOnly && authorizeUserComponentAccessPermission && (
                <div className="relative flex">
                  <Button
                    onClick={handleConditionAdd}
                    type="button"
                    version="default"
                    className="py-.5 flex h-8 items-center justify-center gap-2 rounded-full px-3 text-base font-medium text-highlightColor hover:bg-gray-50 xl:my-1 xl:ml-1 xl:h-10">
                    <PlusIcon className="h-4 w-4 stroke-2" /> Add Condition
                  </Button>
                </div>
              )}
              {/* Nested Pages */}
              {selectedPage?.nested_pages && Array.isArray(selectedPage.nested_pages) && selectedPage?.nested_pages.length > 0 && (
                <>
                  {/* <div className="relative pt-4 border-t border-dashed  border-gray-300">*/}
                  <div className="relative">
                    <div className="relative mt-4 rounded-md border border-gray-200 px-3 py-3 pt-4">
                      <div className="absolute -top-3 left-8 bg-white px-2 text-sm font-semibold text-gray-500">Nested Pages</div>
                      <div className="space-y-2">
                        {selectedPage.nested_pages.map((page, i) => {
                          const pageItem = pageOptions.find((item) => item._id === page.page_id);
                          return (
                            <Fragment key={i}>
                              {!pageItem ? (
                                <></>
                              ) : (
                                <div className="flex items-center justify-between">
                                  {/* {props?.viewOnly ? (
                                  <div className="h-10 w-48 whitespace-nowrap rounded-md border border-gray-300 py-2 px-4 shadow-sm focus:border-highlightColor focus:outline-none focus:ring-highlightColor sm:text-sm inline-flex items-center">{pageItem.name}</div>
                                ) : (
                                  <select
                                    className="w-[30%] h-10 block rounded-md border border-gray-300 py-2 px-4 shadow-sm focus:border-highlightColor focus:outline-none focus:ring-highlightColor sm:text-sm"
                                    disabled
                                    value={pageItem.page_id}>
                                    <option value={pageItem.page_id}>{pageItem.name}</option>
                                  </select>
                                )} */}
                                  <div className="inline-flex h-10 w-full  max-w-xs items-center rounded-md border border-gray-300 px-4 py-2 focus:border-highlightColor focus:outline-none focus:ring-highlightColor sm:text-sm">
                                    <span className="inline-block truncate whitespace-nowrap">{pageItem.name}</span>
                                  </div>
                                </div>
                              )}
                            </Fragment>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(DisplayPageForm);
