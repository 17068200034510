import TableRow from "src/components/Shared/Table/TableRow";
import QuickMenu from "src/components/Shared/QuickMenu";
import { classNames } from "src/helpers/classNames";
import Button from "src/components/Shared/Buttons/Button";

const UserCustomFieldItem = ({ item, onEdit = () => {}, onDelete = () => {}, manageCustomFieldReuired = () => {} }) => {
  const menuItems = [
    {
      name: "Edit",
      hideWhen: "",
      onClick: onEdit,
    },
    {
      name: "Delete",
      hideWhen: "",
      color: "text-red-500",
      onClick: onDelete,
    },
  ];

  return (
    <TableRow keyId={item?._id}>
      <td className="whitespace-nowrap px-6 py-3 text-sm">{item?.label || ""}</td>
      <td className="whitespace-nowrap px-6 py-3 text-sm">{item?.default_value ? item?.default_value : <span className="rounded-full bg-gray-100 px-2 py-1 text-xs font-semibold text-gray-400">N/A</span>}</td>
      <td className="whitespace-nowrap px-6 py-3 text-sm">
        <Button
          type="button"
          onClick={manageCustomFieldReuired}
          version="default"
          className={classNames("!h-6 rounded-full px-2 py-1 text-xs font-semibold", item.is_required ? "bg-green-200 text-green-700 opacity-50" : "bg-gray-50 text-gray-500")}>
          {item.is_required ? "Yes" : "No"}
        </Button>
      </td>
      <td className="flex justify-end whitespace-nowrap px-6 py-3 text-sm">
        <QuickMenu items={menuItems} />
      </td>
    </TableRow>
  );
};

export default UserCustomFieldItem;
