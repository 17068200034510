import TableRow from "src/components/Shared/Table/TableRow";
import { formatDate } from "src/helpers/formatDate";
import WorkspaceSubscriberInstance from "src/components/Workspaces/WorkspaceSubscriberInstance";

function ActivityEntry({ activity }) {
  let activityTypes = {
    LOGIN: "Login",
    PAGE_VIEW: "Page View",
  };

  return (
    <TableRow keyId={activity?._id}>
      <td className="w-1/3 whitespace-pre-wrap break-all px-3 py-2 pl-4 text-gray-500 sm:table-cell sm:py-3 sm:pl-6 sm:text-sm md:w-1/5">
        {activity?.user_id ? (
          <>
            <div className="text-gray-900">{activity?.user_id?.name ? activity?.user_id?.name : "N/A"}</div>
            <div className="text-gray-400">{activity?.user_id?.email ? activity?.user_id?.email : "No email listed"}</div>
          </>
        ) : (
          <div className="text-gray-400">*User Deleted*</div>
        )}
      </td>
      <td className="hidden w-1/5 whitespace-pre-wrap break-all px-3 py-2 text-gray-500 sm:py-3 md:text-sm lg:table-cell">
        <div className="text-gray-900">
          {activityTypes[activity?.type]}
          <div className="text-gray-300">{activity?.data?.login_portal === "ADMIN_PORTAL" ? "Admin Portal" : "User Portal"}</div>
        </div>
      </td>
      <td className="break-word hidden w-1/3 whitespace-pre-wrap px-3 py-2 text-gray-500 sm:table-cell sm:py-3 sm:text-sm md:w-1/5">
        {activity?.type === "PAGE_VIEW" ? (
          <>
            {activity?.data?.page_id?.name && activity?.data?.workspace_id?.name ? (
              <div className="flex flex-col text-gray-900">
                {activity?.data?.page_id?.name || ""}
                <span className="text-gray-300">{activity?.data?.workspace_id?.name}</span>
              </div>
            ) : (
              <>
                {activity?.data?.workspace_id?.name && (!activity?.data?.page_id?.name || activity?.data?.workspace_id?.workspace_type === "JWT_FULL_EMBED") && (
                  <div className="flex flex-col text-gray-900">
                    <div className="items-center text-gray-400">{activity?.data?.workspace_id?.workspace_type === "JWT_FULL_EMBED" ? <WorkspaceSubscriberInstance /> : "*Page Deleted*"}</div>
                    <span className="text-gray-300">{activity?.data?.workspace_id?.name}</span>
                  </div>
                )}
                {!activity?.data?.workspace_id?.name && <div className="text-gray-400">*Workspace Deleted*</div>}
              </>
            )}
          </>
        ) : (
          <span className="text-gray-300">Authentication</span>
        )}
      </td>
      <td className="w-1/4 whitespace-pre-wrap break-all px-3 py-2 text-gray-500 sm:table-cell sm:py-3 sm:text-sm lg:w-1/5">
        <div className="hidden text-gray-900 2xl:block">{formatDate(activity?.created_at, "LLLL")}</div>
        <div className="flex flex-col text-gray-900 2xl:hidden">
          {formatDate(activity?.created_at, "MM/DD/YYYY")} <span className="text-gray-400">{formatDate(activity?.created_at, "h:mm:ssA")}</span>
        </div>
      </td>
    </TableRow>
  );
}

export default ActivityEntry;
