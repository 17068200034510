import { GET_SITE_SETTINGS_DATA, GET_AUTO_SITE_SETTINGS_DATA, UPDATE_SITE_SETTINGS_API_STATE } from "src/actions/type";
import { objectToFormData } from "src/helpers";
import { apiRequest } from "src/async/apiUtils";

export const handleUpdateDomoData = async (type = "pull") => {
  try {
    let url = `/update-domo-dataset/pull`;
    if (type === "push") {
      url = `/update-domo-dataset/push`;
    }
    const { data: resData } = await apiRequest("POST", url, {});

    if (resData.status === 200) {
      return resData.message;
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const manageAutoSiteSettings = (body) => (dispatch) => {
  dispatch({ type: GET_AUTO_SITE_SETTINGS_DATA, payload: body });
};

export const manageDevToDomo = async (body) => {
  try {
    const { data } = await apiRequest("POST", `/create-portal`, { body });

    if (data.status === 200) {
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const manageSiteSettings = (body) => async (dispatch) => {
  try {
    const { data } = await apiRequest(
      "PUT",
      `/site-settings`,
      {
        body: objectToFormData(body),
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );

    if (data.status === 200) {
      dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
      localStorage.setItem("siteSettings", JSON.stringify(data.data));
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const manageDigitalOceanSubdomain = (body) => async (dispatch) => {
  try {
    const { data } = await apiRequest(
      "PUT",
      `/site-settings/digital-ocean-subdomain`,
      {
        body: objectToFormData(body),
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );

    if (data.status === 200) {
      if (!data?.isCredentialInvalidOrMissing) {
        dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
        localStorage.setItem("siteSettings", JSON.stringify(data.data));
      }
      return data;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const manageDigitalOceanCredential = (body) => async (dispatch) => {
  try {
    const { data } = await apiRequest(
      "PUT",
      `/site-settings/digital-ocean-credential`,
      {
        body: objectToFormData(body),
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );

    if (data.status === 200) {
      dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
      localStorage.setItem("siteSettings", JSON.stringify(data.data));
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const createStripeCredential = (body) => async (dispatch) => {
  try {
    const { data } = await apiRequest("POST", `/billing/stripe/settings`, {
      body,
    });

    if (data.status === 200) {
      dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
      localStorage.setItem("siteSettings", JSON.stringify(data.data));
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const deleteStripeCredential = () => async (dispatch) => {
  try {
    const { data } = await apiRequest("DELETE", `/billing/stripe/settings`);

    if (data.status === 200) {
      dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
      localStorage.setItem("siteSettings", JSON.stringify(data.data));
      return data.message;
    } else {
      throw new Error(data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getSiteSettingsCache = () => (dispatch) => {
  const checkData = localStorage.getItem("siteSettings");

  if (checkData) {
    const cacheData = JSON.parse(localStorage.getItem("siteSettings"));
    dispatch({ type: GET_SITE_SETTINGS_DATA, payload: cacheData });
  }
};

export const getOpenSiteSettings =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const { data } = await apiRequest("GET", `/site-settings/unprotected`, { body }, { signal });

      if (data.status === 200) {
        dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const getSiteSettings =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const { data } = await apiRequest("GET", `/site-settings`, { body }, { signal });

      if (data.status === 200) {
        dispatch({ type: GET_SITE_SETTINGS_DATA, payload: data.data });
        localStorage.setItem("siteSettings", JSON.stringify(data.data));
        return data.data;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const pushDataPotal = async (body = null, signal, headers, url) => {
  try {
    const { data } = await apiRequest("PUT", url, { body }, { signal, headers });

    if (!data?.access_token) {
      throw new Error(JSON.stringify(data));
    } else {
      return data;
    }
  } catch (error) {
    return { status: 400, message: error.message };
  }
};

export const createDataset = async (body = null, signal, headers, url) => {
  try {
    const { data } = await apiRequest("POST", url, { body }, { signal, headers });

    if (!data?.access_token) {
      throw new Error(JSON.stringify(data));
    } else {
      return data;
    }
  } catch (error) {
    return error.message;
  }
};

export const getDatasetQuery = async (body, signal, headers, url) => {
  try {
    const { data } = await apiRequest("POST", url, { body }, { signal, headers });

    if (!data?.datasource) {
      throw new Error(`Error: ${JSON.stringify(data)}`);
    } else {
      // dispatch({ type: GET_DATASET_QUERY, payload: JSON.stringify(data) });
      return data;
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const updateSiteSettingsApiState = (body) => (dispatch) => {
  dispatch({ type: UPDATE_SITE_SETTINGS_API_STATE, payload: body.api_state });
};
