import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { userPermissionCheckboxManage, userPermissionList, usersPermissionManage } from "src/actions/workspace";
import WorkspaceManageUsersModal from "src/components/Workspaces/Workspace/WorkspaceManageUsersModal";
import useFetch from "src/hooks/useFetch";
import { apiRequest } from "src/async/apiUtils";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import WarningModal from "src/components/Shared/WarningModal";
import MultiColumnContent from "src/components/Shared/MultiColumnContent/MultiColumnContent";
import WorkspacePermissionHeader from "./WorkspacePermissionHeader";
import WorkspacePermissionSelectedItem from "./WorkspacePermissionSelectedItem";

const WorkspacePermissions = ({ workspaceId, workspaceDetails = {}, ...props }) => {
  const [selectedUser, setSelectedUser] = useState([]);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [workspaceUsers, setWorkspaceUsers] = useState([]);
  const [userList, setUserList] = useState([]);
  const [disableStatus, setDisableStatus] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [activeUser, setActiveUser] = useState(null);
  const [permissionChanges, setPermissionChanges] = useState(0);
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(null);
  const [deleteEditorId, setDeleteEditorId] = useState(null);
  const [listToggle, setListToggle] = useState(false);

  const handleAddUserModal = (value) => {
    setIsAddUserModalOpen(value);
    resetUserIds();
  };

  const resetUserIds = () => {
    let userIds = editorUsers.map((user) => user?.user_id?._id);
    setSelectedUser(userIds);
  };

  const {
    response: { data: editorUsers },
    status: { done: usersLoaded },
    refreshData: refreshEditorUsers,
  } = useFetch(`/workspaces/:workspace_id/users/permissions/list`, { params: { workspace_id: workspaceId } });

  const getUsers = async () => {
    refreshEditorUsers();
  };

  const loadWorkspaceUserData = async () => {
    try {
      const res = await apiRequest("get", `/workspaces/:workspace_id/users`, { queries: { type: "user,editor" }, params: { workspace_id: workspaceId } });

      if (res.data) {
        setWorkspaceUsers(res.data.data);
      }
    } catch (error) {
      // console.dir("ERROR:", error);
    }
  };

  const userAdd = async (selectedUser) => {
    try {
      setDisableStatus(true);
      const data = await props.usersPermissionManage({ user_id: selectedUser, workspace_id: workspaceId });
      getUsers();
      setIsAddUserModalOpen(false);
      setSelectedUser([]);
      toast.success(data.message);
      setDisableStatus(false);
      setDeleteEditorId(null);
    } catch (error) {
      setDisableStatus(false);
      toast.error(error.message);
    }
  };

  const savePermissionChanges = async () => {
    try {
      const resp = await props.userPermissionCheckboxManage({ user_id: activeUser?.user_id?._id, permissions: activeUser, workspace_id: workspaceId });
      setUserList((prevData) => [
        ...prevData.map((data) => {
          if (data?.user_id?._id === activeUser?.user_id?._id) {
            return {
              ...data,
              ...activeUser,
            };
          } else {
            return data;
          }
        }),
      ]);
      setPermissionChanges(0);
      toast.success(resp);
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const onClear = () => {
    setActiveUser((prevData) => ({
      ...prevData,
      ...userList.find((user) => user?.user_id?._id === prevData?.user_id?._id),
    }));
    setPermissionChanges(0);
  };

  const deleteEditor = (user) => {
    setDeleteEditorId(user?._id);
  };

  const handleDeleteEditor = () => {
    userAdd(selectedUser.filter((data) => data !== deleteEditorId));
  };

  useEffect(() => {
    resetUserIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorUsers]);

  useEffect(() => {
    loadWorkspaceUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId]);

  useEffect(() => {
    if (keyword) {
      setUserList(editorUsers.filter((user) => user.user_id.name.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())));
    } else {
      setUserList(editorUsers ? editorUsers : []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorUsers, keyword]);

  useEffect(() => {
    if (userList.length > 0) {
      if (!activeUser) {
        setActiveUser(userList[0]);
      }
      if (activeUser && !userList.map((user) => user?._id).includes(activeUser?._id)) {
        setActiveUser(userList[0]);
      }
    } else {
      setActiveUser(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList]);

  return (
    <>
      <MultiColumnContent
        headerContentConfig={{
          headerEnabled: true,
          searchEnabled: true,
          keyword: keyword,
          onKeywordChange: (value) => setKeyword(value),
          addButtonEnabled: authorizeUserComponentAccess(props?.user, workspaceId, "permission", ["update"]) || authorizeUserComponentAccess(props?.user, workspaceId, "permission", ["editor_create"]),
          addButtonText: `Add editor`,
          onAddButtonClick: () => {
            setIsAddUserModalOpen(true);
          },
        }}
        listContentConfig={{
          listLoaded: usersLoaded,
          listData: userList,
          listComponent: (props) => (
            <WorkspacePermissionHeader
              {...props}
              activeUser={activeUser}
              setActiveUser={setActiveUser}
              permissionChanges={permissionChanges}
              setWarningModalIsOpen={setWarningModalIsOpen}
              setListToggle={setListToggle}
            />
          ),
        }}
        selectedContentConfig={{
          selectedData: activeUser,
          selectedComponent: () => (
            <WorkspacePermissionSelectedItem
              permissionChanges={permissionChanges}
              workspaceId={workspaceId}
              onClear={onClear}
              savePermissionChanges={savePermissionChanges}
              activeUser={activeUser}
              setActiveUser={setActiveUser}
              setPermissionChanges={setPermissionChanges}
              disabled={!authorizeUserComponentAccess(props?.user, workspaceId, "permission", ["editor_update"]) || (props?.user?.type === "editor" && props?.user?._id.toString() === activeUser?.user_id?._id?.toString())}
              deleteEditor={deleteEditor}
              listToggle={listToggle}
              setListToggle={setListToggle}
            />
          ),
        }}
        listToggle={listToggle}
      />
      <WorkspaceManageUsersModal
        title="Workspace Editors"
        secondaryTitle="Manage"
        isPermissionPage={true}
        isOpen={isAddUserModalOpen}
        setIsOpen={handleAddUserModal}
        pSelectedUser={selectedUser}
        defaultSelectedUsers={selectedUser}
        setSelectedUser={setSelectedUser}
        onSuccess={userAdd}
        onCancel={() => setIsAddUserModalOpen(false)}
        users={editorUsers}
        workspaceUsers={workspaceUsers}
        disableStatus={disableStatus}
        workspaceDetailsData={workspaceDetails}
      />
      <WarningModal
        isOpen={warningModalIsOpen?._id ? true : false}
        onSuccess={() => {
          setActiveUser(warningModalIsOpen);
          setPermissionChanges(0);
          setWarningModalIsOpen(null);
        }}
        onCancel={() => setWarningModalIsOpen(null)}
      />
      <WarningModal
        isOpen={!!deleteEditorId}
        onSuccess={handleDeleteEditor}
        onCancel={() => setDeleteEditorId(null)}
        type="DELETE_DATA"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};
export default connect(mapStateToProps, { userPermissionList, usersPermissionManage, userPermissionCheckboxManage })(WorkspacePermissions);
