import { GET_2FA_DETAILS, CLEAR_2FA_DETAILS } from "src/actions/type";

const twoFactorReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_2FA_DETAILS:
      return { ...action.payload };
    case CLEAR_2FA_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export default twoFactorReducer;
