import React from "react";
import TextArea from "src/components/Shared/Forms/Inputs/TextArea";

const HtmlPage = ({ newPage = {}, setNewPage = () => {} }) => {
  return (
    <TextArea
      label="Custom HTML"
      inline={true}
      value={newPage.custom_html}
      onChange={(e) => setNewPage({ ...newPage, custom_html: e.target.value })}
      placeholder="<iframe> ... </iframe>"
      textType="code"
    />
  );
};

export default HtmlPage;
