import { XMarkIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { classNames } from "src/helpers/classNames";
import Button from "./Buttons/Button";

const Search = ({ label, labelClasses, keyword, setKeyword, placeholder, clearIcon = false, inline = false, isFocused, setIsFocused }) => {
  const [focused, setFocused] = useState(isFocused || false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [text, setText] = useState("");

  useEffect(() => {
    setText(keyword);
  }, [keyword]);

  const handleInputChange = (event) => {
    const newValue = event.target.value;

    setText(newValue);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      setKeyword(newValue);
    }, 50);

    setTimeoutId(newTimeoutId);
  };

  return (
    <div className="relative flex w-full flex-col">
      {label && <label className={"block text-sm font-medium text-gray-700 " + labelClasses}>{label}</label>}
      <div className={classNames(inline ? "relative overflow-hidden rounded-md border border-gray-300 px-0 pt-2 focus-within:border-highlightColor md:pt-1 2xl:pt-2" : "w-full")}>
        {inline && <div className="m-0 flex justify-between pl-3 text-xs font-semibold uppercase leading-none text-gray-400 md:pl-2 md:text-[10px] 2xl:pl-3">{placeholder}</div>}
        <div className={classNames("group relative flex w-full", inline ? "rounded-none" : "rounded-md")}>
          <Button
            version="default"
            className={classNames("pointer-events-none flex items-center pl-3", inline ? "!h-8 md:!h-7 2xl:!h-8" : "absolute inset-y-0 left-0")}
            aria-hidden="true">
            <MagnifyingGlassIcon
              className={classNames("mr-3 h-4", focused ? "text-highlightColor" : "text-gray-400")}
              aria-hidden="true"
            />
          </Button>
          <input
            type="text"
            name="search"
            id="search"
            onBlur={() => {
              setFocused(false);
            }}
            onFocus={() => {
              setFocused(true);
              setIsFocused(true);
            }}
            value={text}
            className={classNames(
              inline
                ? "h-8 w-full rounded-none border-none px-3 py-0 !pl-0 text-sm font-normal leading-none text-gray-700 shadow-none focus:ring-0 md:h-7 md:px-2 2xl:h-8 2xl:px-3"
                : "block h-[47px] w-full rounded-md border-gray-300 pl-9 text-sm focus:border-highlightColor focus:ring-0 md:h-[40px] 2xl:h-[47px]",
            )}
            placeholder={!inline && placeholder}
            onChange={handleInputChange}
          />
        </div>
      </div>
      {clearIcon && text && (
        <Button
          version="default"
          onClick={() => handleInputChange({ target: { value: "" } })}
          className="pointer-cursor group absolute bottom-1.5 right-1 z-20 flex h-7 cursor-pointer items-center justify-center gap-x-1 rounded-full border-[1px] border-transparent bg-gray-50 p-1.5 transition-all duration-200 hover:border-gray-200 focus:border-highlightColor focus:ring-0">
          <XMarkIcon
            className="h-4 w-4 text-gray-400 group-hover:bg-gray-100 group-hover:text-gray-500"
            aria-hidden="true"
          />
          <p className="pr-1 text-sm text-gray-400 group-hover:text-gray-500">clear</p>
        </Button>
      )}
    </div>
  );
};

export default Search;
