import CustomAttributeList from "./CustomAttributeList";
import UserSettings from "./UserSettings";

const PrivacyAndSecurity = () => {
  return (
    <>
      <CustomAttributeList />
      <UserSettings />
    </>
  );
};

export default PrivacyAndSecurity;
