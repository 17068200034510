import { FeatureFlags } from "src/context/FeatureFlagContext";

export const FeatureFlag = ({ children, feature = "" }) => {
  return (
    <FeatureFlags.Consumer>
      {(flags) => {
        if (Array.isArray(flags)) {
          let foundFlag = flags?.find((flag) => flag.name?.toLowerCase() === feature?.toLowerCase());
          return <>{typeof foundFlag === "undefined" || foundFlag.active === true ? <div>{children}</div> : null}</>;
        }
        return null;
      }}
    </FeatureFlags.Consumer>
  );
};
