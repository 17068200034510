import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject/MultiSelectObject";
import Modal from "src/components/Shared/Modal";
import { Tooltip as ReactTooltip } from "react-tooltip";
import WorkspaceImportUserModal from "src/components/Workspaces/Workspace/WorkspaceImportUserModal";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";

const WorkspaceManageUsersModal = ({
  workspaceDetails,
  workspaceDetailsData = {},
  title = "",
  secondaryTitle = "",
  user,
  defaultSelectedUsers = [],
  isOpen,
  onCancel = () => {},
  onSuccess = () => {},
  isPermissionPage = false,
  isImportModalOpen = false,
  setIsImportModalOpen = () => {},
  sendWelcomeEmail = false,
  setSendWelcomeEmail = () => {},
  password = "",
  setPassword = () => {},
  confirmPassword = "",
  setConfirmPassword = () => {},
  hideCrossIcon = true,
  workspaceUsers = [],
  disableStatus = false,
  ...props
}) => {
  const [selectedUsers, setSelectedUsers] = useState(defaultSelectedUsers || props.selectedUser);

  useEffect(() => {
    if (isOpen) {
      setSelectedUsers(defaultSelectedUsers);
    }
  }, [isOpen, defaultSelectedUsers]);

  useEffect(() => {
    if (Array.isArray(props.selectedUser) && props.selectedUser.length > 0) {
      setSelectedUsers(props.selectedUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedUser?.length]);

  const handleSelectedUsers = (updatedSelectedUsers = []) => {
    let userIds = [];
    if (isPermissionPage) {
      if (user?.type === "admin" || (workspaceDetails?.user_permissions && (workspaceDetails?.user_permissions?.permission_update || workspaceDetails?.user_permissions?.permission_editor_create))) {
        userIds = [];
      } else if (authorizeUserComponentAccess(user, workspaceDetailsData?._id, "permission", ["update"]) || authorizeUserComponentAccess(user, workspaceDetailsData?._id, "permission", ["editor_create"])) {
        userIds = [];
      } else {
        userIds = defaultSelectedUsers;
      }
    }
    for (let i = 0; i < updatedSelectedUsers.length; i++) {
      const updatedSelectedUser = updatedSelectedUsers[i];
      if (updatedSelectedUser.selected) {
        userIds.push(updatedSelectedUser.key);
      }
    }
    setSelectedUsers(userIds);
  };

  return (
    <>
      <Modal
        title={title}
        secondaryTitle={secondaryTitle}
        isOpen={isOpen}
        onCancel={onCancel}
        onSuccess={() => {
          onSuccess(selectedUsers);
        }}
        defaultOptions={{
          onSuccessButtonText: "Save",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: disableStatus,
        }}
        defaultStyles={{
          overFlowYVisible: true,
        }}>
        <div className="relative min-h-[200px] py-4 overflow-visible">
          <MultiSelectObject
            defaultOptions={workspaceUsers.map((user) => {
              return { key: user._id || user.id, value: user.name, selected: selectedUsers.includes(user._id || user.id), object: user };
            })}
            searchableFields={["name", "email"]}
            drowdownWindowMaxWidth="max-w-[200px]"
            title="Assign users"
            inline={true}
            searchComponent={(object) => {
              return (
                <div className="flex relative items-center justify-center gap-x-2">
                  <div>{object.name}</div>
                  <div className="text-sm text-gray-400">{object.email}</div>
                  {typeof object?.portalUser === "boolean" && !object?.portalUser && (
                    <div
                      data-tooltip-id={`${object.email}-not_portal_user_search`}
                      className="flex gap-x-1 text-amber-400 font-light bg-amber-50/50 border border-amber-300 rounded-full px-1">
                      <ExclamationTriangleIcon className="stroke-[2px] h-5 w-5" /> Alert
                    </div>
                  )}
                  <ReactTooltip
                    id={`${object.email}-not_portal_user_search`}
                    delayShow={200}
                    positionStrategy="fixed"
                    className="z-[200] opacity-100 bg-gray-700 rounded px-2 py-2">
                    <div className="font-normal">
                      <div>This user was created by a Domo admin, but has not yet been give an account to access the application.</div>
                      <div>If you add this user a welcome email will be issued.</div>
                    </div>
                  </ReactTooltip>
                </div>
              );
            }}
            bubbleComponent={(object) => {
              return (
                <div className="relative px-1 flex flex-col gap-x-2">
                  {typeof object?.portalUser === "boolean" && !object?.portalUser && (
                    <div className="absolute -top-3 -right-2 text-xs text-amber-500 bg-amber-50 border border-amber-400 flex items-center justify-center p-2 rounded-full h-6">
                      <ExclamationTriangleIcon
                        data-tooltip-id={`${object.email}-not_portal_user_bubble`}
                        className="stroke-2 h-5 w-5"
                      />
                    </div>
                  )}
                  <div className="leading-4">{object.name}</div>
                  <div className="leading-4 text-xs text-gray-400">{object.email}</div>
                  <ReactTooltip
                    id={`${object.email}-not_portal_user_bubble`}
                    delayShow={200}
                    positionStrategy="fixed"
                    className="z-[200] opacity-100 bg-gray-700 rounded px-2 py-2">
                    <div className="font-normal">
                      <div>This user was created by a Domo admin, but has not yet been give an account to access the application.</div>
                      <div>If you add this user a welcome email will be issued.</div>
                    </div>
                  </ReactTooltip>
                </div>
              );
            }}
            onChange={handleSelectedUsers}
          />
        </div>
      </Modal>
      {/* Group import user modal open */}
      <WorkspaceImportUserModal
        isImportModalOpen={isImportModalOpen}
        setIsImportModalOpen={setIsImportModalOpen}
        hideCrossIcon={hideCrossIcon}
        selectedUsers={selectedUsers}
        workspaceUsers={workspaceUsers}
        sendWelcomeEmail={sendWelcomeEmail}
        setSendWelcomeEmail={setSendWelcomeEmail}
        password={password}
        setPassword={setPassword}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
        onSuccess={onSuccess}
        workspaceDetailsData={props.workspaceDetailsData}
        disableStatus={disableStatus}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, {})(WorkspaceManageUsersModal);
