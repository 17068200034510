import Modal from "src/components/Shared/Modal";

const WorkspaceDomainDeleteModal = ({ isOpen = false, disable = false, onDelete = () => {}, onCancel = () => {} }) => {
  return (
    <Modal
      title={"Remove"}
      secondaryTitle="Domain"
      isOpen={isOpen}
      onCancel={onCancel}
      onSuccess={onDelete}
      defaultOptions={{
        onSuccessButtonText: "Delete",
        onSuccessLoaderVisible: true,
        onSuccessLoaderStart: disable,
      }}>
      <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete this domain?</div>
    </Modal>
  )
}

export default WorkspaceDomainDeleteModal