import { PaperClipIcon } from "@heroicons/react/24/outline";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { addSSO, getSso, getXmlData, updateSso } from "src/actions/sso";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Select from "src/components/Shared/Forms/Selects/Select";
import TextArea from "src/components/Shared/Forms/Inputs/TextArea";
import { classNames } from "src/helpers/classNames";
// eslint-disable-next-line no-unused-vars

const certificateText = "-----BEGIN CERTIFICATE----- \n (certificate goes here) \n -----END CERTIFICATE-----";

const SsoServiceProvider = ({ site, addSSO, getSso, updateSso, getXmlData, ...props }) => {
  const { id } = useParams();
  const [uploadType, setUploadType] = useState("manual");
  const location = useLocation();
  const [providerType, setProviderType] = useState(location.state && typeof location.state.providerType !== "undefined" ? location.state.providerType : "general");
  const [providerName, setProviderName] = useState(location.state && typeof location.state.providerName !== "undefined" ? location.state.providerName : "");
  const [domain, setDomain] = useState(location.state && typeof location.state.domain !== "undefined" ? location.state.domain : "");
  const [initClientId, setInitClientId] = useState("");
  const [clientId, setClientId] = useState(location.state && typeof location.state.clientId !== "undefined" ? location.state.clientId : "");
  const [initClientSecret, setInitClientSecret] = useState("");
  const [clientSecret, setClientSecret] = useState(location.state && typeof location.state.clientSecret !== "undefined" ? location.state.clientSecret : "");
  const [identityURL, setIdentityURL] = useState(location.state && typeof location.state.entityID !== "undefined" ? location.state.entityID : "");
  const [loginURL, setLoginURL] = useState(location.state && typeof location.state.entryPoint !== "undefined" ? location.state.entryPoint : "");
  const [logoutURL, setLogoutURL] = useState(location.state && typeof location.state.logoutUrl !== "undefined" ? location.state.logoutUrl : "");
  const [certificate, setCertificate] = useState(location.state && typeof location.state.cert !== "undefined" ? location.state.cert : "");
  const [buttonText, setButtonText] = useState("");
  const [buttonColor, setButtonColor] = useState("#000073");
  const [buttonLoader, setButtonLoader] = useState(false);
  let navigate = useNavigate();
  const [metaURL, setMetaURL] = useState("");
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [previouslyUploadedCert, setPreviouslyUploadedCert] = useState(false);

  useEffect(() => {
    const ac = new AbortController();
    const loadSSO = async () => {
      try {
        const data = await getSso({ id }, ac.signal);
        setProviderType(data.provider_type || "general");
        setProviderName(data.provider_name);
        setDomain(data.domain);
        setInitClientId(data.client_id);
        // setClientId(data.client_id);
        setInitClientSecret(data.client_secret);
        // setClientSecret(data.client_secret);
        setIdentityURL(data.identity_url);
        setLoginURL(data.login_url);
        setLogoutURL(data.logout_url);
        setCertificate(data.certificate);
        setPreviouslyUploadedCert(data.certificate ? true : false);
        setButtonText(data.button_text);
        setButtonColor(data.button_color ? data.button_color : "#000073");
      } catch (error) {
        // console.dir("ERROR:", error);
      }
    };
    if (id) {
      loadSSO();
    }

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createSSO = async () => {
    try {
      setButtonLoader(true);
      const message = await addSSO({
        provider_type: providerType,
        provider_name: providerName,
        domain: domain,
        client_id: clientId,
        client_secret: clientSecret,
        identity_url: identityURL,
        login_url: loginURL,
        logout_url: logoutURL,
        certificate: certificate,
        type: true,
        button_text: buttonText,
        button_color: buttonColor,
        permissions: Object.values({}),
      });
      setTimeout(() => {
        setButtonLoader(false);
        toast.success(message);
        navigate("/sso");
      }, 300);
    } catch (error) {
      setTimeout(() => {
        setButtonLoader(false);
        toast.error(error.message);
      }, 300);
    }
  };

  const updateSSO = async () => {
    try {
      setButtonLoader(true);
      let permission = Object.values({});
      let per = [];
      for (let index = 0; index < permission.length; index++) {
        const element = permission[index];
        per.push({
          page_id: element.page_id,
          operator_id: element.operator_id,
          column_name: element.column_name,
          column_value: element.column_value,
          is_default: element.is_default,
        });
      }

      let body = {};

      if (providerType === "auth0") {
        body = {
          id: id,
          provider_type: providerType,
          provider_name: providerName,
          domain: domain,
          client_id: clientId,
          client_secret: clientSecret,
          type: true,
        };
      } else {
        body = {
          id: id,
          provider_type: providerType,
          provider_name: providerName,
          identity_url: identityURL,
          login_url: loginURL,
          logout_url: logoutURL,
          certificate: certificate,
          type: true,
          button_text: buttonText,
          button_color: buttonColor,
          permissions: per,
        };
      }

      const msg = await updateSso(body);
      setTimeout(() => {
        toast.success(msg);
        navigate("/sso");
      }, 300);
    } catch (error) {
      setTimeout(() => {
        toast.error(error.message);
      }, 300);
    } finally {
      setTimeout(() => {
        setButtonLoader(false);
      }, 300);
    }
  };

  const getXMLDataByUrl = async () => {
    if (providerName) {
      try {
        // eslint-disable-next-line no-unused-vars
        const data = await getXmlData({ url: metaURL, type: "url" });
        if (data) {
          setMetaURL("");
          setFile(null);
          setUploadType("manual");
          setIdentityURL(data.entryPoint);
          setLoginURL(data.identityProviderUrl);
          setCertificate(data.cert);
          setLogoutURL(data?.logoutUrl || "");
        }
        // if (id) {
        //   navigate("/sso/" + id + "/manual", { state: { ...data, providerName } });
        // } else {
        //   navigate("/sso/manual", { state: { ...data, providerName } });
        // }
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 1700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const getXMLDataByFile = async () => {
    if (providerName) {
      if (file) {
        getBase64(file, async (result) => {
          try {
            // eslint-disable-next-line no-unused-vars
            const data = await getXmlData({ file: result, type: "file" });
            if (data) {
              setFile(null);
              setMetaURL("");
              setUploadType("manual");
              setIdentityURL(data.entryPoint);
              setLoginURL(data.identityProviderUrl);
              setCertificate(data.cert);
            }

            // navigate("/sso/manual", { state: { ...data, providerName } });
          } catch (error) {
            toast.error(error.message, {
              position: "top-right",
              autoClose: 1700,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
      } else {
        toast.error("Please select a XML file");
      }
    }
  };

  const getBase64 = async (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      // console.dir("Error: ", error);
    };
  };

  return (
    <>
      <div className="mt-4 rounded-md bg-white p-6 shadow">
        <div className="w-ful mb-8 max-w-4xl border-b border-solid border-gray-200 pb-8">
          <h5 className="mb-4 text-base font-medium">How will you setup up the metadata?</h5>
          <div className="grid grid-cols-2 flex-wrap gap-3 sm:flex sm:gap-6">
            <Button
              version="default"
              className={classNames("!h-auto w-full max-w-80 !flex-col !items-start space-y-2 rounded-md border bg-white px-4 py-4 text-left", uploadType === "manual" ? "border-transparent ring-2 ring-highlightColor ring-offset-2" : "border-gray-200")}
              onClick={() => setUploadType("manual")}>
              <div className="text-base font-semibold text-gray-800">Manually</div>
              <div className="text-sm text-gray-400">Fill out this form from your IDP and find the information in its SSO setup.</div>
            </Button>
            <Button
              version="default"
              className={classNames("!h-auto w-full max-w-80 !flex-col !items-start space-y-2 rounded-md border  bg-white px-4 py-4 text-left", uploadType === "upload" ? "border-transparent ring-2 ring-highlightColor ring-offset-2" : "border-gray-200")}
              onClick={() => setUploadType("upload")}>
              <div className="text-base font-semibold text-gray-800">Upload File</div>
              <div className="text-sm text-gray-400">Your IDP may also have a metadata file with all this data that you can upload here.</div>
            </Button>
          </div>
        </div>

        {uploadType === "manual" && (
          <div className="relative">
            <div className="w-ful max-w-4xl">
              <div className="grid grid-cols-4 gap-4">
                <div className="col-span-4 sm:col-span-4 lg:col-span-3">
                  <Select
                    inline={true}
                    label="Identity Provider type"
                    options={[
                      { _id: "general", name: "General" },
                      { _id: "auth0", name: "Auth0" },
                    ]}
                    selected={{
                      _id: providerType,
                      name: providerType === "auth0" ? "Auth0" : "General",
                    }}
                    setSelected={(val) => setProviderType(val._id)}
                  />
                </div>
                <div className="col-span-4 sm:col-span-4 lg:col-span-3">
                  <Input
                    inline={true}
                    name="provider-name"
                    label="Identity Provider Name"
                    value={providerName || ""}
                    onChange={(e) => setProviderName(e.target.value)}
                  />
                </div>
                {providerType === "auth0" ? (
                  <>
                    <div className="col-span-4 sm:col-span-4 lg:col-span-3">
                      <Input
                        inline={true}
                        name="domain"
                        label="Domain"
                        value={domain || ""}
                        onChange={(e) => setDomain(e.target.value)}
                      />
                      <div className="mt-1 text-xs text-gray-400">*You can find the Domain in Your application settings as entityID</div>
                    </div>
                    <div className="col-span-4 sm:col-span-4 lg:col-span-3">
                      <Input
                        name="client-id"
                        label="Client ID"
                        value={clientId || ""}
                        placeholder={initClientId}
                        onChange={(e) => setClientId(e.target.value)}
                      />
                      <div className="mt-1 text-xs text-gray-400">*You can find the Client ID in Your application settings as Client ID</div>
                    </div>
                    <div className="col-span-4 sm:col-span-4 lg:col-span-3">
                      <Input
                        inline={true}
                        name="client-secret"
                        label="Client Secret"
                        value={clientSecret || ""}
                        placeholder={initClientSecret}
                        onChange={(e) => setClientSecret(e.target.value)}
                      />
                      <div className="mt-1 text-xs text-gray-400">*You can find the Client Secret in Your application settings as Client Secret</div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-span-4 sm:col-span-4 lg:col-span-3">
                      <Input
                        inline={true}
                        name="identity-url"
                        label="IDP Entity ID or Issuer"
                        value={identityURL || ""}
                        onChange={(e) => setIdentityURL(e.target.value)}
                      />
                      <div className="mt-1 text-xs text-gray-400">*You can find the EntityID in Your IdP-Metadata XML file enclosed in EntityDescriptor tag having attribute as entityID</div>
                    </div>
                    <div className="col-span-4 sm:col-span-4 lg:col-span-3">
                      <Input
                        inline={true}
                        name="login-url"
                        label="SAMS Login URL"
                        value={loginURL || ""}
                        onChange={(e) => setLoginURL(e.target.value)}
                      />
                      <div className="mt-1 text-xs text-gray-400">*You can find the SAML Login URL in Your IDP-Metadata XML file enclosed in SingleSignOnService tag (Binding type: HTTP-Redirect)</div>
                    </div>
                    <div className="col-span-4 sm:col-span-4 lg:col-span-3">
                      <Input
                        inline={true}
                        name="logout-url"
                        label="SAMS Logout URL"
                        value={logoutURL || ""}
                        onChange={(e) => setLogoutURL(e.target.value)}
                      />
                      <div className="mt-1 text-xs text-gray-400">*You can find the SAML Logout URL in Your IDP-Metadata XML file enclosed in SingleSignOnService tag (Binding type: HTTP-Redirect)</div>
                    </div>
                    <div className="col-span-4 sm:col-span-4 lg:col-span-4">
                      <TextArea
                        inline={true}
                        inputClassNames="w-full h-40 text-sm rounded border-gray-300 border outline-none no-shadow py-2 px-3 focus:border-highlightColor"
                        label={"X.509 Certificate"}
                        value={certificate || (!previouslyUploadedCert ? certificateText : "")}
                        onChange={(e) => setCertificate(e.target.value)}
                      />
                    </div>
                    <div className="col-span-4 sm:col-span-4 lg:col-span-4">
                      <div className="flex rounded bg-gray-100 p-4">
                        <input
                          id="comments"
                          name="comments"
                          type="checkbox"
                          className="text-color-06 mt-1 h-5 w-5 cursor-pointer rounded border-gray-300 focus:border-highlightColor focus:ring-0"
                          defaultChecked={true}
                          disabled={true}
                        />
                        <div className="ml-3">
                          <div className="text-sm font-medium text-gray-800">Character Encoding</div>
                          <div className="text-xs text-gray-400">Uses iconv encoding to convert X509 certificate into correct encoding.</div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        {uploadType === "upload" && (
          <div className="relative">
            <div className="w-ful max-w-4xl">
              <div className="grid grid-cols-4 gap-4">
                <div className="col-span-4 sm:col-span-4 lg:col-span-3">
                  <Input
                    inline={true}
                    name="column-name"
                    label="Identity Provider Name"
                    value={providerName || ""}
                    onChange={(e) => setProviderName(e.target.value)}
                  />
                </div>
                <div className="col-span-4 sm:col-span-4 lg:col-span-3">
                  <Input
                    inline={true}
                    name="column-name"
                    label="Metadata URL"
                    value={metaURL || ""}
                    onChange={(e) => setMetaURL(e.target.value)}
                  />
                  <div className="mt-1 text-xs text-gray-400">*You can find the EntityID in Your IdP-Metadata XML file enclosed in EntityDescriptor tag having attribute as entityID</div>
                </div>
                <div className="col-span-4 sm:col-span-4 lg:col-span-1">
                  <Button
                    version="primary"
                    type="button"
                    onClick={getXMLDataByUrl}>
                    Fetch Metadata
                  </Button>
                </div>
                <div className="col-span-4 sm:col-span-4 lg:col-span-3">
                  <div className="relative z-0 flex items-center justify-center py-8">
                    <span className="absolute left-0 top-1/2 -z-10 block w-full border-b border-gray-200"></span>
                    <span className="bg-white px-5 text-center text-sm text-gray-500">Or continue with</span>
                  </div>
                </div>
                <div className="col-span-4 sm:col-span-4 lg:col-span-3">
                  <div className="relative flex overflow-hidden rounded-md border border-gray-300 px-0 shadow-sm focus-within:border-highlightColor">
                    <div className="relative flex-shrink flex-grow">
                      <label className="m-0 pl-3 text-[10px] font-semibold uppercase leading-none text-gray-400">Metadata File</label>
                      <div className="flex h-8 items-center px-3">
                        <PaperClipIcon className="h-6 w-6 text-gray-500" />
                        <input
                          type="text"
                          value={fileName || ""}
                          readOnly
                          className="no-shadow h-10 w-full border-0  px-3 py-2 text-sm outline-none focus:ring-transparent"
                          placeholder=""
                          name=""
                        />
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 items-center border-l border-gray-200">
                      <Button
                        type="button"
                        version="default"
                        className="relative h-full overflow-hidden px-3 text-sm font-semibold text-highlightColor">
                        <input
                          type="file"
                          className="absolute right-0 top-0 h-full w-96 cursor-pointer opacity-0"
                          onChange={(e) => {
                            setFile(e.target.files[0]);
                            setFileName(e.target.files[0] ? e.target.files[0].name : "");
                          }}
                          value={undefined}
                        />
                        <span>Choose file</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex w-full items-center justify-end space-x-4 pt-6">
        <Button
          version="gray"
          type="button"
          disabled={buttonLoader}
          onClick={() => navigate("/sso")}>
          Cancel
        </Button>
        {uploadType === "upload" ? (
          <Button
            version="gray"
            type="button"
            onClick={getXMLDataByFile}>
            Update
          </Button>
        ) : id ? (
          <Button
            version="primary"
            type="button"
            disabled={buttonLoader}
            onClick={updateSSO}>
            Save
            {buttonLoader && <ArrowPathIcon className="ml-1 h-5 w-5 animate-spin" />}
          </Button>
        ) : (
          <Button
            type="button"
            disabled={buttonLoader}
            onClick={createSSO}>
            Create
            {buttonLoader && <ArrowPathIcon className="ml-1 h-5 w-5 animate-spin" />}
          </Button>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};
export default connect(mapStateToProps, { addSSO, getSso, updateSso, getXmlData })(SsoServiceProvider);
