import React from "react";
import { classNames } from "src/helpers/classNames";
import { Tooltip as ReactTooltip } from "react-tooltip";

const MultiRowSelectContainer = ({ disabled = true, disabledToolTip = { id: 1, text: "" }, children }) => {
  return (
    <div
      data-tooltip-id={disabledToolTip.id}
      className={classNames("py-1 bg-gray-50/80 rounded-full px-2 sm:ml-3 flex items-center gap-x-1 transition-all duration-200", disabled ? "cursor-not-allowed opacity-40 overflow-x-hidden" : "max-w-[400px] opacity-100")}>
      {disabled && (
        <ReactTooltip
          id={disabledToolTip.id}
          delayShow={200}
          positionStrategy="fixed"
          className="mt-2 -ml-5 opacity-100 bg-gray-700 rounded px-2 py-2">
          <div className="font-normal leading-[10px]">{disabledToolTip.text}</div>
        </ReactTooltip>
      )}
      {children}
    </div>
  );
};

export default MultiRowSelectContainer;
