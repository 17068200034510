import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Image from "src/components/Shared/Image";
import { noFavicon } from "src/config/host";

export default function DomoIntegrationClient({ credential = {}, getAttributeValue = () => {} }) {
  const [displayName, setDisplayName] = useState("");
  const [displayDescription, setDisplayDescription] = useState("");
  const [displayURL, setDisplayURL] = useState("");
  const [displayKey, setDisplayKey] = useState("");
  const [integrationType, setIntegrationType] = useState("");

  useEffect(() => {
    setDisplayName(getAttributeValue("name"));
    setDisplayDescription(getAttributeValue("description"));

    if (credential?.workspace_id) {
      setDisplayURL(getAttributeValue("subscriber_instance_url"));
      setDisplayKey(getAttributeValue("key_attribute_value"));
      if (credential?.integration_type === "IFRAME_EMBED") {
        setIntegrationType("Iframe Embeded");
      } else {
        setIntegrationType("Subscriber Instance");
      }
    } else {
      setDisplayURL(getAttributeValue("embed_url"));
      setDisplayKey(getAttributeValue("key_attribute"));
      if (credential?.integration_type === "IFRAME_EMBED") {
        setIntegrationType("Iframe Embeded");
      } else {
        setIntegrationType("JWT Embeded");
      }
    }
  }, [credential, getAttributeValue]);

  return (
    <div className="flex flex-col lg:flex-row lg:items-center gap-3 lg:gap-1 w-full">
      <div className="w-full lg:w-96 sm:truncate space-y-2 sm:space-y-0">
        <div className="text-base text-gray-800 font-semibold leading-none">{displayName || displayURL}</div>
        <div className="text-sm text-gray-400">{displayDescription || displayKey}</div>
      </div>
      <div className="flex gap-1 flex-shrink-0">
        {integrationType && <div className="lg:ml-5 w-34 text-xs font-semibold text-highlightColor px-3 h-8 flex items-center justify-center rounded border shadow">{integrationType}</div>}

        <div className="h-8">
          <div className="h-full min-w-34 text-xs font-semibold text-highlightColor px-1 flex items-center justify-center gap-x-2 rounded border shadow">
            {credential?.workspace ? (
              <div className="w-6 h-6">
                <Image
                  image={credential?.workspace?.square_logo || credential?.workspace?.image_favicon || credential?.workspace?.image_logo}
                  failImage={noFavicon}
                  alt={credential?.workspace?.name}
                  className="w-full h-full"
                />
              </div>
            ) : (
              <div className="h-full flex gap-x-1 items-center">
                <GlobeAltIcon className="w-6 h-6 text-gray-400" />
                <div className="flex-shrink text-xs font-semibold text-gray-400">Global</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
