import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MultiColumnContent from "src/components/Shared/MultiColumnContent/MultiColumnContent";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import useFetch from "src/hooks/useFetch";
import WorkspaceSSOHeader from "src/components/Workspaces/Workspace/SSO/WorkspaceSSOHeader";
import WarningModal from "src/components/Shared/WarningModal";
import WorkspaceSSOSelectedItem from "src/components/Workspaces/Workspace/SSO/WorkspaceSSOSelectedItem";
import WorkspaceSSOAddModal from "src/components/Workspaces/WorkspacesModals/WorkspaceSSOAddModal";
import { apiRequest } from "src/async/apiUtils";
import { toast } from "react-toastify";
import WorkspaceSSOMappingToWorkspacesModal from "src/components/Workspaces/WorkspacesModals/WorkspaceSSOMappingToWorkspacesModal";
import WorkspaceSSOMappingToUserAttributesModal from "src/components/Workspaces/WorkspacesModals/WorkspaceSSOMappingToUserAttributesModal";

const WorkspaceSSOList = ({ workspaceId = null, ...props }) => {
  const [keyword, setKeyword] = useState("");
  const [activeItem, setActiveItem] = useState(null);
  const [itemModifiedCount, setItemModifiedCount] = useState(0);
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(null);
  const [actionItem, setActionItem] = useState(null);
  const [isAddSSOModalOpen, setIsAddSSOModalOpen] = useState(false);
  const [ssoList, setSsoList] = useState([]);
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(false);
  const [listToggle, setListToggle] = useState(false);

  const providerType = {
    general: "General",
    auth0: "Auth0",
  };

  const {
    response: { data: ssos },
    status: { done: ssosLoaded },
    refreshData: refreshSsos,
  } = useFetch(workspaceId ? `/workspaces/:workspace_id/sso/list` : `/sso/list`, {
    query: { keyword: keyword },
    params: { workspace_id: workspaceId },
  });

  const onDelete = async () => {
    try {
      setDeleteButtonDisabled(true);
      const { data } = await apiRequest("POST", workspaceId ? `/workspaces/:workspace_id/sso/remove` : `/sso/remove`, {
        body: { id: actionItem?.state?._id },
        params: { workspace_id: workspaceId },
      });
      setActionItem(null);
      setDeleteButtonDisabled(false);
      toast.success(data.message);
      refreshSsos();
    } catch (error) {
      setDeleteButtonDisabled(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setSsoList(ssos);
  }, [ssos]);

  useEffect(() => {
    refreshSsos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  useEffect(() => {
    if (ssoList?.length > 0) {
      if (!activeItem) {
        setActiveItem(ssoList[0]);
      }
      if (activeItem && !ssoList.map((item) => item?._id).includes(activeItem?._id)) {
        setActiveItem(ssoList[0]);
      } else if (activeItem && ssoList.map((item) => item?._id).includes(activeItem?._id)) {
        setActiveItem(ssoList.find((item) => item?._id?.toString() === activeItem?._id?.toString()));
      }
    } else {
      setActiveItem(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ssoList]);

  return (
    <>
      <MultiColumnContent
        headerContentConfig={{
          headerEnabled: true,
          searchEnabled: true,
          keyword: keyword,
          onKeywordChange: (value) => setKeyword(value),
          addButtonEnabled: authorizeUserComponentAccess(props?.user, workspaceId, "settings", ["update"]) || authorizeUserComponentAccess(props?.user, workspaceId, "sso", ["update"]),
          addButtonText: `Add SSO`,
          onAddButtonClick: () => {
            setIsAddSSOModalOpen(true);
          },
        }}
        listContentConfig={{
          listLoaded: ssosLoaded,
          listData: ssoList,
          listComponent: (props) => (
            <WorkspaceSSOHeader
              {...props}
              providerType={providerType}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
              itemModifiedCount={itemModifiedCount}
              setWarningModalIsOpen={setWarningModalIsOpen}
              setListToggle={setListToggle}
            />
          ),
        }}
        selectedContentConfig={{
          selectedData: activeItem,
          selectedComponent: () => (
            <WorkspaceSSOSelectedItem
              itemModifiedCount={itemModifiedCount}
              workspaceId={workspaceId}
              providerType={providerType}
              onAfterSave={refreshSsos}
              activeItem={activeItem}
              setActiveItem={setActiveItem}
              setItemModifiedCount={setItemModifiedCount}
              setActionItem={setActionItem}
              listToggle={listToggle}
              setListToggle={setListToggle}
            />
          ),
        }}
        listToggle={listToggle}
      />
      <WorkspaceSSOAddModal
        title="SSO"
        secondaryTitle="Add"
        workspaceId={workspaceId}
        isOpen={isAddSSOModalOpen}
        onSuccess={() => {
          setIsAddSSOModalOpen(false);
          refreshSsos();
        }}
        onCancel={() => setIsAddSSOModalOpen(false)}
      />
      <WorkspaceSSOMappingToWorkspacesModal
        title="SSO"
        secondaryTitle={`Mapping to Groups`}
        workspaceId={workspaceId}
        item={actionItem?.state}
        isOpen={actionItem?.action === "MAP_TO_WORKSPACE" ? true : false}
        onSuccess={() => {
          setActionItem(null);
          refreshSsos();
        }}
        onCancel={() => setActionItem(null)}
      />
      <WorkspaceSSOMappingToUserAttributesModal
        title="SSO"
        secondaryTitle="Map SSO to User fields"
        workspaceId={workspaceId}
        item={actionItem?.state}
        isOpen={actionItem?.action === "MAP_TO_USER_ATTRIBUTES" ? true : false}
        onSuccess={() => {
          setActionItem(null);
          refreshSsos();
        }}
        onCancel={() => setActionItem(null)}
      />
      <WarningModal
        isOpen={warningModalIsOpen?._id ? true : false}
        onSuccess={() => {
          setActiveItem(warningModalIsOpen);
          setItemModifiedCount(0);
          setWarningModalIsOpen(null);
        }}
        onCancel={() => setWarningModalIsOpen(null)}
      />
      <WarningModal
        isOpen={actionItem?.action === "DELETE" ? true : false}
        disabled={deleteButtonDisabled}
        onSuccess={onDelete}
        onCancel={() => setActionItem(null)}
        type="DELETE_DATA"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    user: state.auth.user,
    workspaceDetails: state.workspaceDetails,
  };
};
export default connect(mapStateToProps, {})(WorkspaceSSOList);
