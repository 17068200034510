import { ArrowPathIcon, ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { handleUpdateDomoData, manageSiteSettings } from "src/actions/site";
import Button from "src/components/Shared/Buttons/Button";
import FrontLabeledInput from "src/components/Shared/Forms/Inputs/FrontLabeledInput";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import QuickMenu from "src/components/Shared/QuickMenu";
import { classNames } from "src/helpers/classNames";
import { replacePrivateStringWithStars } from "src/helpers/replacePrivateStringWithStars";
import { SecureSettingsContext } from "src/context/SecureSettingsContext";
import { updateSiteSettingsApiState } from "src/actions/site";
import { apiRequest } from "src/async/apiUtils";

const UserImportDataset = ({ updateSiteSettingsApiState }) => {
  const { site, loadSite: loadSecureSiteData } = useContext(SecureSettingsContext);

  const [updateChanges, setUpdateChanges] = useState(0);
  const [apiState, setApiState] = useState("PORTAL_CREATED");
  const [isSync, setSync] = useState(false);
  const [datasetClientId, setDatasetClientId] = useState("");
  const [datasetClientSecret, setDatasetClientSecret] = useState("");
  const [userImportDatasetId, setUserImportDatasetId] = useState("");
  const [domoLoginUrl, setDomoLoginUrl] = useState("");
  const [passwordState, setPasswordState] = useState(false);
  const [individualPageAccess, setIndividualPageAccess] = useState(false);
  const [isUpdateDisable, setIsUpdateDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadSecureSiteData();
  }, [loadSecureSiteData]);

  const handleStateUpdate = (data) => {
    setDatasetClientId(data.dataset_client_id || "");
    setDatasetClientSecret(data.dataset_client_secret || "");
    setApiState(data.api_state || "");
    setUserImportDatasetId(data.user_import_dataset_id || "");
    setPasswordState(data.password_state || false);
    setDomoLoginUrl(data.domo_login_url || "");
    setIndividualPageAccess(data?.individual_page_access || false);
  };

  useEffect(() => {
    if (site._id) {
      handleStateUpdate(site);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  const onClear = () => {
    handleStateUpdate(site);
    setUpdateChanges(0);
  };

  const onSubmit = async () => {
    try {
      setIsUpdateDisable(true);
      let body = {
        password_state: passwordState,
        user_import_dataset_id: userImportDatasetId,
        domo_login_url: domoLoginUrl,
        api_state: apiState,
        individual_page_access: individualPageAccess,
      };
      if (datasetClientId) {
        body.dataset_client_id = typeof datasetClientId === "number" ? undefined : datasetClientId;
      }
      if (datasetClientSecret) {
        body.dataset_client_secret = typeof datasetClientSecret === "number" ? undefined : datasetClientSecret;
      }

      await apiRequest("PUT", `/site-settings/user_import_dataset`, { body }, { showToastMessage: true, onSuccess: () => {}, onFailure: () => {} });
      loadSecureSiteData();
      updateSiteSettingsApiState({ api_state: apiState });

      setUpdateChanges(0);
    } catch (error) {
    } finally {
      setIsUpdateDisable(false);
    }
  };

  const updateDomo = async (requestType = "pull") => {
    try {
      setIsLoading(true);
      setSync(true);
      let message = await handleUpdateDomoData(requestType);
      toast.success(message);
      setSync(false);

      setUpdateChanges(0);
    } catch (error) {
      toast.error(error.message);
      setSync(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="right-3 top-6 -ml-4 sm:absolute">
        <ToggleHeader>
          <Toggle
            checked={"DOMO_DATASET" === apiState}
            onChange={(e) => {
              setApiState(e ? "DOMO_DATASET" : "PORTAL_CREATED");
              setUpdateChanges((cur) => cur + 1);
            }}
          />
        </ToggleHeader>
      </div>
      <div className="flex w-full flex-col justify-between gap-y-2 lg:gap-x-12">
        <div className="relative w-full rounded-md">
          <div className={classNames("absolute z-20 flex h-full w-full items-center justify-center rounded-xl bg-gray-100 opacity-80", apiState !== "DOMO_DATASET" ? "absolute" : "hidden")}>
            <div className="flex w-full rotate-[-10deg] select-none justify-center text-center text-4xl font-extrabold text-gray-300 md:text-4xl lg:text-5xl xl:text-7xl">For Domo Dataset Use</div>
          </div>
          <div className="flex w-full flex-col gap-y-6 px-4 pb-6 pt-12">
            <div className="grid space-y-8 text-sm ">
              <div className="grid space-y-2 text-sm">
                <div className="relative flex w-full items-center justify-end gap-x-4">
                  <div className="absolute -top-10 right-0 flex items-center">
                    <div className="flex justify-end">
                      <QuickMenu
                        disabled={site?.api_state !== "DOMO_DATASET" || apiState !== "DOMO_DATASET"}
                        items={[
                          {
                            name: "Upload users to dataset",
                            onClick: () => updateDomo("push"),
                            color: "text-red-600",
                          },
                        ]}
                      />
                    </div>
                    <Button
                      className="!h-10"
                      disabled={site?.api_state !== "DOMO_DATASET" || apiState !== "DOMO_DATASET" || isLoading}
                      version="gray"
                      onClick={() => updateDomo("pull")}>
                      Sync
                      <ArrowPathIcon
                        className={classNames("h-5 w-5", !isSync ? "" : "animate-spin")}
                        role="status"
                      />
                    </Button>
                  </div>
                </div>
                <FrontLabeledInput
                  label="Client ID"
                  type="password"
                  disabled={apiState !== "DOMO_DATASET"}
                  value={replacePrivateStringWithStars(typeof datasetClientId === "number" ? datasetClientId : datasetClientId.length)}
                  onChange={(e) => {
                    setDatasetClientId(e.target.value);
                    setUpdateChanges((cur) => cur + 1);
                  }}
                  onFocus={(e) => {
                    if (e.target.value.includes("*")) {
                      e.target.value = "";
                      setDatasetClientId("");
                    }
                  }}
                />
                <FrontLabeledInput
                  label="Client Secret"
                  type="password"
                  disabled={apiState !== "DOMO_DATASET"}
                  value={replacePrivateStringWithStars(typeof datasetClientSecret === "number" ? datasetClientSecret : datasetClientSecret.length)}
                  onChange={(e) => {
                    setDatasetClientSecret(e.target.value);
                    setUpdateChanges((cur) => cur + 1);
                  }}
                  onFocus={(e) => {
                    if (e.target.value.includes("*")) {
                      e.target.value = "";
                      setDatasetClientSecret("");
                    }
                  }}
                />
              </div>
              <div className="grid space-y-2 text-sm">
                {apiState !== "DOMO_DATASET" ? (
                  <FrontLabeledInput
                    label="Dataset ID"
                    type="text"
                    disabled={apiState !== "DOMO_DATASET"}
                    value={""}
                    onChange={() => {}}
                  />
                ) : (
                  <FrontLabeledInput
                    label="Dataset ID"
                    type="text"
                    disabled={apiState !== "DOMO_DATASET"}
                    value={userImportDatasetId}
                    onChange={(e) => {
                      setUserImportDatasetId(e.target.value);
                      setUpdateChanges((cur) => cur + 1);
                    }}
                  />
                )}
                <div className="relative w-full">
                  {apiState !== "DOMO_DATASET" ? (
                    <FrontLabeledInput
                      label="Access URL"
                      type="text"
                      value={""}
                      onChange={() => {}}
                    />
                  ) : (
                    <FrontLabeledInput
                      label="Access URL"
                      type="text"
                      value={domoLoginUrl}
                      onChange={(e) => {
                        setDomoLoginUrl(e.target.value);
                        setUpdateChanges((cur) => cur + 1);
                      }}
                    />
                  )}

                  <a
                    href={domoLoginUrl + "/datasources/" + userImportDatasetId + "/details/data/table"}
                    className="text-regular absolute right-2 top-1/2 -translate-y-1/2 z-10 flex items-center gap-x-2 rounded bg-white px-2 py-1 font-medium text-gray-500 shadow hover:text-highlightColor hover:underline"
                    target="_blank"
                    rel="noreferrer">
                    <p>View dataset</p>
                    <ArrowTopRightOnSquareIcon className="h-6 w-6" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {updateChanges > 0 && (
          <div className="flex w-full justify-end gap-x-4">
            <Button
              disabled={isUpdateDisable || updateChanges === 0}
              onClick={onSubmit}>
              Save
            </Button>
            <Button
              disabled={isUpdateDisable || updateChanges === 0}
              version="gray"
              onClick={onClear}>
              Cancel
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    siteData: state.site,
  };
};

export default connect(mapStateToProps, {
  manageSiteSettings,
  updateSiteSettingsApiState,
})(UserImportDataset);
