import React from 'react';

const CompanyItems = ({ data }) => {
  return (
    <>
      <div className="group overflow-hidden hover:text-highlightColor border rounded-md relative bg-white focus-within:ring-2 focus-within:ring-inset focus-within:ring-highlightColor hover:bg-gray-100 transition-all duration-200">
        <button className="relative flex items-center justify-between w-full focus:outline-none gap-x-2 py-4 px-3">
          <div className="h-6 w-5/12">
            <div className="w-full h-full">
              <img className="w-full h-full object-contain object-left" src={data.logo} alt={data.name}/>
            </div>
          </div>
          <div className="w-7/12">
            <div className="w-full text-right text-xs font-medium text-gray-500">{data.adminReportUrl}</div>
            <div className="w-full text-right text-xs font-medium text-gray-500">{data.reportUrl}</div>
          </div>
        </button>
      </div>
    </>
  );
};

export default CompanyItems;