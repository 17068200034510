import React from "react";
import { classNames } from "src/helpers/classNames";

export default function TRBody({ className = "", children, onClick = null }) {
  return (
    <td
      className={classNames("break-all px-4 py-2 text-sm text-gray-500 transition-all duration-200 group-hover/tableRow:bg-gray-50 sm:py-2 sm:pl-6 sm:pr-4", className, onClick ? "cursor-pointer" : "")}
      onClick={() => (onClick ? onClick() : {})}>
      {children}
    </td>
  );
}
