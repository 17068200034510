import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import Input from "src/components/Shared/Forms/Inputs/Input";

const LinkPage = ({ newPage = {}, setNewPage = () => {} }) => {
  return (
    <>
      <Input
        type="text"
        inline={true}
        name="link-url"
        label="Link URL"
        value={newPage.page_link}
        onChange={(e) => setNewPage({ ...newPage, page_link: e.target.value })}
      />

      <div className="mb-4 flex w-full mt-4">
        <input
          id="card-embed"
          checked={newPage.page_link_newtab}
          onChange={(e) => {
            setNewPage({
              ...newPage,
              page_link_newtab: e.target.checked,
            });
          }}
          aria-describedby="card-embed"
          name="card-embed"
          type="checkbox"
          className="h-5 w-5 rounded border-gray-300 text-highlightColor focus:ring-highlightColor"
        />
        <div className="ml-3 max-w-[400px]">
          <label
            htmlFor="card-embed"
            className="text-sm text-gray-500 flex items-center gap-x-2">
            Open link in new tab <ArrowTopRightOnSquareIcon className="h-5 w-5" />
          </label>
        </div>
      </div>
    </>
  );
};

export default LinkPage;
