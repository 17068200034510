import { ArrowsUpDownIcon, CircleStackIcon } from "@heroicons/react/24/outline";
import Input from "src/components/Shared/Forms/Inputs/Input";
import { useEffect, useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
import { Menu } from "@headlessui/react";

const DatasetRedirectsContent = ({ data = null, onClick = () => {} }) => {
  const [originalDatasetId, setOriginalDatasetId] = useState("");
  const [targetDatasetId, setTargetDatasetId] = useState("");

  useEffect(() => {
    setOriginalDatasetId(data?.original_dataset_id || "");
    setTargetDatasetId(data?.target_dataset_id || "");
  }, [data]);

  return (
    <div className="w-full space-y-4">
      <div className="relative">
        <div className="mb-1 text-xs text-gray-700">Use dataset swapping to replace the default dataset</div>
        <div className="flex w-full max-w-lg items-center space-x-4">
          <div className="relative flex-shrink flex-grow">
            <Input
              inline={true}
              label={"Original dataset id"}
              value={originalDatasetId}
              onChange={(e) => setOriginalDatasetId(e.target.value)}
            />
          </div>
          <div className="relative flex-shrink-0">
            <CircleStackIcon className="h-7 w-7 text-gray-300" />
          </div>
        </div>
      </div>
      <div className="relative w-full max-w-lg">
        <div className="absolute right-0 top-1/2 z-[1] mt-2 -translate-y-1/2">
          <ArrowsUpDownIcon className="h-6 w-6 stroke-2 text-gray-300" />
        </div>
      </div>
      <div className="relative">
        <div className="mb-1 text-xs text-gray-700">With a new target dataset</div>
        <div className="flex w-full max-w-lg items-center space-x-4">
          <div className="relative flex-shrink flex-grow">
            <Input
              inline={true}
              label={"Target dataset id"}
              value={targetDatasetId}
              onChange={(e) => setTargetDatasetId(e.target.value)}
            />
          </div>
          <div className="relative flex-shrink-0">
            <CircleStackIcon className="h-7 w-7 text-gray-300" />
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <Menu.Item>
          <Button
            version="default"
            className="!h-8 rounded !bg-highlightColor px-2 text-sm text-white"
            onClick={(e) => {
              if (originalDatasetId && targetDatasetId) {
                onClick({ original_dataset_id: originalDatasetId, target_dataset_id: targetDatasetId });
              } else {
                e.preventDefault();
              }
            }}>
            {data?.original_dataset_id ? `Edit` : `Add`}
          </Button>
        </Menu.Item>
      </div>
    </div>
  );
};

export default DatasetRedirectsContent;
