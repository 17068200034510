import { CloudArrowDownIcon, Cog6ToothIcon, UsersIcon } from "@heroicons/react/24/outline";
import { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuthorizedUser } from "src/actions/auth";
import { apiRequest } from "src/async/apiUtils";
import PrivacyAndSecurity from "src/components/Activity/PrivacyAndSecurity";
import Tabs from "src/components/Shared/Tabs/Tabs";
import { H1 } from "src/components/Shared/Text/Headers";
import ImportUsers from "src/components/Users/Add/ImportUsers/ImportUsers";
import UsersList from "src/components/Users/UsersList";
import { SecureSettingsContext } from "src/context/SecureSettingsContext";

const Users = ({ ...props }) => {
  const router = useLocation();
  const navigate = useNavigate();

  const [site, setSite] = useState({})

  const loadSecureSiteData = useCallback(() => {
    (async () => {
      try {
        const {data: resData} = await apiRequest(
          "GET",
          `/site-settings/user_import_dataset`,
        );

        if (resData.status === 200) {
          setSite(resData.data)
        }
      } catch (err) {

      }
    })()
  }, [])

  useEffect(() => {
    getAuthorizedUser();
  }, []);

  const existsPath = props.menus.some((obj) => obj.path === router.pathname);
  if (Object.keys(props.menus).length && !existsPath) {
    navigate("/");
  }

  const tabs = [
    {
      name: "User List",
      icon: UsersIcon,
      component: (
        <UsersList
          title="User"
          filterUserType={["user", "editor"]}
        />
      ),
    },
    { name: "User Settings (BETA)", icon: Cog6ToothIcon, component: <PrivacyAndSecurity />, hidden: props?.user?.type !== "admin" },
    { name: "Import Users", icon: CloudArrowDownIcon, component: <ImportUsers />, hidden: props?.user?.type !== "admin" },
  ];

  if (!props?.user?._id) return <></>;

  if (props?.user?.type === "editor" && props?.user?.workspaces?.length === 1 && !localStorage.getItem("impersonatedUserId")) {
    window.location.href = `/workspaces/${props.user.workspaces[0]._id}?tab=Users`;
    return <></>;
  }

  return (
    <>
      {Object.keys(props.menus).length > 0 && existsPath && (
        <>
          <H1>Users</H1>
          <SecureSettingsContext.Provider
            value={{ site, setSite, loadSite: loadSecureSiteData }}
          >
            <Tabs tabs={tabs} />
          </SecureSettingsContext.Provider>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    menus: Object.values(state.menus),
    site: state.site,
  };
};

export default connect(mapStateToProps, { getAuthorizedUser })(Users);
