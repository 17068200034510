import React from 'react';
import { CloudArrowDownIcon, ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import Button from "src/components/Shared/Buttons/Button";

const BulkUpload2 = () => {
  return (
    <>
      <div className="relative py-5">
        <div className="text-gray-500 mb-1">Download template, fill and upload here to import users</div>
        <div className="relative overflow-hidden rounded-xl border-2 border-dashed border-gray-300 bg-white flex flex-col items-center justify-center gap-4 h-60">
          <input type={"file"} className="absolute top-0 right-0 w-[200%] h-full opacity-0 z-10 cursor-pointer"/>
          <CloudArrowDownIcon className="w-10 h-10 text-gray-500"/>
          <div className="text-gray-500">Drag and drop or browse the files you want to upload from your computer</div>
          <div className="text-highlightColor font-semibold">Browse from Computer</div>
        </div>
        <div className="flex mt-4">
          <Button
          className="!text-highlightColor !bg-transparent !p-0 !border-0 !font-semibold !shadow-none"
          onClick={() => {}}
          >
            <ArrowDownTrayIcon className="w-5 h-5 stroke-2"/> Download Template
          </Button>
        </div>
      </div>
    </>
  );
};

export default BulkUpload2;