import React from 'react';
import { CalendarDaysIcon } from "@heroicons/react/24/outline";

const UserDateTime = () => {
  return (
    <>
      <button type="button" className="flex justify-center items-center px-3 h-10 space-x-2 bg-gray-200/30 rounded-md">
        <CalendarDaysIcon className="w-8 h-8 stroke-1 text-gray-500"/>
        <span className="flex flex-col text-left leading-tight">
          <span className="font-semibold text-sm text-gray-700">Saturday, 29th July</span>
          <span className="text-[10px] font-semibold text-gray-400">8:00 - 9:00 AM</span>
        </span>
      </button>
    </>
  );
};

export default UserDateTime;