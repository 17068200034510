import { useLocation } from "react-router-dom";

function useUrlQueryParams() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  // Extract "group" and "page" parameters
  const group = searchParams.get("group");
  const page = searchParams.get("page");

  return { group, page };
}

export default useUrlQueryParams;
