import { useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { changePassword } from "src/actions/auth";
import AuthContainer from "src/components/Auth/AuthContainer";
import AuthHeader from "src/components/Auth/AuthHeader";
import Button from "src/components/Shared/Buttons/Button";
import ResetPasswordForm from "./ResetPasswordForm";

const ResetPasswordContent = ({ token, changePassword, site, seoTitle }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();

  const resetPassword = async () => {
    try {
      setDisabled(true);
      const message = await changePassword({
        password,
        confirm_password: confirmPassword,
        token,
      });

      toast.success(message);
      setDisabled(false);
      return navigate("/");
    } catch (error) {
      setDisabled(false);
      toast.error(error.message);
    }
  };

  return (
    <>
      <AuthHeader
        seoTitle={seoTitle}
        title="Create a new password"
        switchTo={{
          startText: "Enter your new password and let's get the ball rolling!",
          location: site?.site_domain || "/",
        }}
      />
      <AuthContainer site={site}>
        <div className="grid gap-y-3">
          <ResetPasswordForm
            password={password}
            confirmPassword={confirmPassword}
            setPassword={(e) => setPassword(e.target.value)}
            setConfirmPassword={(e) => setConfirmPassword(e.target.value)}
          />

          <div className="mt-4 w-full">
            <Button
              onClick={resetPassword}
              disabled={disabled}
              loading={disabled}
              width="w-full">
              Reset Password
            </Button>
          </div>
        </div>
      </AuthContainer>
      <p className="text-regular font-md mt-4 text-center text-gray-600">
        Return to
        <Link
          to="/login"
          className="pl-1 text-highlightColor underline">
          login page
        </Link>
      </p>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { changePassword })(ResetPasswordContent);
