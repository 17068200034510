import { useEffect, useState } from "react";
import { connect } from "react-redux";
import QuickMenu from "src/components/Shared/QuickMenu";
import TRBody from "src/components/Shared/Table/TRBody";
import TableRow from "src/components/Shared/Table/TableRow";
import { isSVGString } from "src/helpers";
import Image from "src/components/Shared/Image";
import { noFavicon } from "src/config/host";

const GroupEntry = ({ user, group, setDeleteId, site, ...props }) => {
  const [items, setItems] = useState([]);
  const [image, setImage] = useState(null);

  useEffect(() => {
    let item = [];
    if (user?.type === "admin" || (user?.user_permissions && user?.user_permissions?.group_update)) {
      item.push({
        name: "Edit",
        onClick: () => {
          // navigate(`/groups/edit/${group._id}`);
          props.setEditId(group._id);
          props.setGroupModalOpen(true);
          props.setEditGroupStatus(true);
          props.setAddGroupStatus(false);
        },
      });
    }
    if (user?.type === "admin" || (user?.user_permissions && user?.user_permissions?.group_delete)) {
      item.push({
        name: "Delete",
        onClick: () => {
          setDeleteId(group._id);
        },
        color: "text-red-500",
      });
    }
    setItems(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setImage(group.image)
  }, [group.image])

  return (
    <TableRow keyId={group?._id + "group"}>
      <TRBody className="whitespace-pre-wrap">
        <div className="flex items-center h-11">
          {image &&
            <div className="w-8 h-8 p-1 overflow-hidden mr-2">
              {isSVGString(image) ? (
                <div className="" dangerouslySetInnerHTML={{ __html: image }} />
              ) : (
                <Image
                  image={image}
                  failImage={noFavicon}
                  alt={"Groups"}
                  onError={() => {
                    setImage(null)
                  }}
                />
              )}
            </div>
          }
          <div className="text-gray-900 font-medium">{group?.name}</div>
        </div>
      </TRBody>

      <TRBody className="whitespace-pre-wrap">
        <div className="text-gray-900 font-medium">{group?.permissions.length}</div>
      </TRBody>
      <td className="whitespace-nowrap py-2 pl-3 pr-4 text-sm font-medium">
        {(user?.type === "admin" || (user?.user_permissions && (user?.user_permissions?.group_update || user?.user_permissions?.group_delete))) && (
          <div className="flex justify-end">
            <QuickMenu items={items} />
          </div>
        )}
      </td>
    </TableRow>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(GroupEntry);
