import { useEffect, useState } from "react";
import Modal from "src/components/Shared/Modal";
import { apiRequest } from "src/async/apiUtils";
import { toast } from "react-toastify";
import NoDataDashedContainer from "src/components/Shared/NoData/NoDataDashedContainer";
import Button from "src/components/Shared/Buttons/Button";
import { PlusIcon } from "@heroicons/react/24/outline";
import { classNames } from "src/helpers/classNames";
import SSOAttributeMapper from "src/components/Workspaces/Workspace/SSO/SSOAttributeMapper";

const WorkspaceSSOMappingToWorkspacesModal = ({ title = "", secondaryTitle = "", item = null, workspaceId = null, isOpen, onCancel = () => {}, onSuccess = () => {} }) => {
  let mapAttributeDropdownArray = [
    { value: "workspace", key: "workspaces" },
    { value: "group", key: "groups" },
  ];
  if (workspaceId) mapAttributeDropdownArray = [{ value: "group", key: "groups" }];
  const workspaceIncludedDropdownArray = [
    { value: "tags", key: "tags" },
    { value: "name", key: "name" },
  ];
  const groupAttributeJson = {
    included_in: "name",
    trusted_attribute: "",
  };
  const workspaceGroupAttributeJson = {
    type: workspaceId ? "group" : "workspace",
    included_in: "name",
    trusted_attribute: "",
    group_trusted_attribute: [],
  };
  const [workspaceGroupAttribute, setWorkspaceGroupAttribute] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const onUpdate = async () => {
    try {
      setDisabled(true);
      const { data } = await apiRequest("POST", workspaceId ? `/workspaces/:workspace_id/sso/workspace-group/settings/edit` : `/sso/workspace-group/settings/edit`, {
        body: {
          id: item?._id,
          workspace_group_attribute: workspaceGroupAttribute,
        },
        params: { workspace_id: workspaceId },
      });
      setDisabled(false);
      toast.success(data.message);
      onSuccess();
    } catch (error) {
      setDisabled(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setWorkspaceGroupAttribute(item?.workspace_group_attribute || []);
  }, [item?.workspace_group_attribute]);

  return (
    <>
      <Modal
        title={title}
        secondaryTitle={secondaryTitle}
        isOpen={isOpen}
        size={`xxl`}
        onCancel={onCancel}
        onSuccess={onUpdate}
        defaultOptions={{
          onSuccessButtonText: "Save",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: disabled,
        }}
        defaultStyles={{
          overFlowYVisible: false,
        }}>
        <div className="relative min-h-[200px] overflow-visible py-4">
          {workspaceGroupAttribute?.length === 0 ? (
            <NoDataDashedContainer
              addButton={
                <Button
                  version="secondary"
                  hoverText="Map attribute to role"
                  onClick={() => setWorkspaceGroupAttribute((prevData) => [...prevData, workspaceGroupAttributeJson])}>
                  <PlusIcon className="h-4 w-4 stroke-2" /> Add SSO attribute
                </Button>
              }
            />
          ) : (
            <div className="space-y-4">
              {workspaceGroupAttribute.map((item, index) => (
                <div
                  key={item?._id || item?.id}
                  className="flex gap-y-2">
                  <div className="flex flex-col">
                    <div className="relative flex h-full w-full items-center">
                      <SSOAttributeMapper
                        value={item?.trusted_attribute}
                        setValue={(value) => {
                          setWorkspaceGroupAttribute((prevData) => [
                            ...prevData.map((data, prevIndex) =>
                              prevIndex === index
                                ? {
                                    ...data,
                                    trusted_attribute: value,
                                  }
                                : data,
                            ),
                          ]);
                        }}
                        text={item?.type}
                        includedDropdownData={item?.type === "workspace" ? workspaceIncludedDropdownArray : []}
                        includedIndex={workspaceIncludedDropdownArray.findIndex((includedItem) => includedItem?.value === item?.included_in) || 0}
                        setIncludedOption={(option) => {
                          setWorkspaceGroupAttribute((prevData) => [
                            ...prevData.map((data, prevIndex) =>
                              prevIndex === index
                                ? {
                                    ...data,
                                    included_in: option.value,
                                  }
                                : data,
                            ),
                          ]);
                        }}
                        mapAttributeDropdownData={mapAttributeDropdownArray}
                        mapAttributeIndex={mapAttributeDropdownArray.findIndex((attributeItem) => attributeItem?.value === item?.type) || 0}
                        setMapAttributeOption={(option) => {
                          setWorkspaceGroupAttribute((prevData) => [
                            ...prevData.map((data, prevIndex) =>
                              prevIndex === index
                                ? {
                                    ...data,
                                    type: option.value,
                                    group_trusted_attribute: option.value === "workspace" ? data.group_trusted_attribute : [],
                                    included_in: option.value === "workspace" ? data.included_in : "name",
                                  }
                                : data,
                            ),
                          ]);
                        }}
                        removeData={() => {
                          setWorkspaceGroupAttribute((prevData) => [...prevData.filter((_, prevIndex) => prevIndex !== index)]);
                        }}
                      />
                    </div>
                    <div className="flex flex-col justify-end pl-8">
                      <div className="relative flex flex-col gap-y-2 pl-1 pt-2">
                        {item?.type === "workspace" &&
                          item?.group_trusted_attribute?.length > 0 &&
                          item?.group_trusted_attribute?.map((subItem, subIndex) => (
                            <div
                              className="flex items-center"
                              key={subItem?._id || subItem?.id}>
                              <SSOAttributeMapper
                                value={subItem?.trusted_attribute}
                                subgroup={true}
                                setValue={(value) => {
                                  setWorkspaceGroupAttribute((prevData) => [
                                    ...prevData.map((data, prevIndex) =>
                                      prevIndex === index
                                        ? {
                                            ...data,
                                            group_trusted_attribute: [
                                              ...data.group_trusted_attribute.map((subData, subPrevindex) =>
                                                subIndex === subPrevindex
                                                  ? {
                                                      ...subData,
                                                      trusted_attribute: value,
                                                    }
                                                  : subData,
                                              ),
                                            ],
                                          }
                                        : data,
                                    ),
                                  ]);
                                }}
                                text="group"
                                removeData={() => {
                                  setWorkspaceGroupAttribute((prevData) => [
                                    ...prevData.map((data, prevIndex) =>
                                      prevIndex === index
                                        ? {
                                            ...data,
                                            group_trusted_attribute: [...data.group_trusted_attribute.filter((_, subPrevIndex) => subPrevIndex !== subIndex)],
                                          }
                                        : data,
                                    ),
                                  ]);
                                }}
                              />
                            </div>
                          ))}
                        {item?.type === "workspace" && (
                          <div className={classNames("flex items-center justify-start", item?.group_trusted_attribute?.length > 0 ? "pl-9" : "")}>
                            <Button
                              version="secondary"
                              hoverText="Add group mapping"
                              onClick={() => {
                                setWorkspaceGroupAttribute((prevData) => [
                                  ...prevData.map((data, prevIndex) =>
                                    prevIndex === index
                                      ? {
                                          ...data,
                                          group_trusted_attribute: [...data.group_trusted_attribute, groupAttributeJson],
                                        }
                                      : data,
                                  ),
                                ]);
                              }}>
                              <PlusIcon className="h-4 w-4 stroke-2" />
                              Add group
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="flex w-full items-center justify-end space-x-4 pr-4 pt-6">
                <Button
                  version="secondary"
                  hoverText="Map attribute to role"
                  onClick={() => {
                    setWorkspaceGroupAttribute((prevData) => [...prevData, workspaceGroupAttributeJson]);
                  }}>
                  <PlusIcon className="h-4 w-4 stroke-2" /> Add SSO attribute
                </Button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default WorkspaceSSOMappingToWorkspacesModal;
