import { classNames } from "src/helpers/classNames";
import { useField } from "formik";

const Input = ({ children, inline = false, ...props }) => {
  const [field, meta] = useField(props);
  let defaultFieldStyles = classNames(
    "block w-full sm:text-sm truncate",
    inline
      ? "px-2 2xl:px-4 h-8 md:h-7 2xl:h-8 rounded-none border-none focus:ring-0 ring-0 py-0 shadow-none bg-transparent focus:border-none"
      : "h-[47px] md:h-[40px] 2xl:h-[47px] rounded-md border border-gray-300 py-2 px-4 focus:border-highlightColor focus:outline-none focus:ring-highlightColor",
    inline && props.disabled ? "text-gray-400" : "",
  );

  return (
    <div
      className={classNames(
        inline ? "relative overflow-hidden rounded-md border px-0 pt-1 focus-within:border-highlightColor 2xl:pt-2" : "relative",
        inline && props.disabled ? "border-gray-100 bg-gray-50" : "border-gray-300",
        !!meta.touched && !!meta.error ? "border-red-400" : "",
      )}>
      <label
        htmlFor={props.name}
        className={classNames("block ", inline ? "m-0 pl-2 text-[10px] font-semibold uppercase leading-none text-gray-400 2xl:pl-3" : "text-sm font-medium text-gray-700")}>
        {props.label}
      </label>
      {props.as === "select" ? (
        <select
          {...props}
          {...field}
          className={classNames(defaultFieldStyles, props.className)}>
          {children}
        </select>
      ) : (
        <input
          {...props}
          {...field}
          className={classNames(defaultFieldStyles)}
        />
      )}
    </div>
  );
};

export default Input;
