import { GET_WORKSPACE_DETAILS, CLEAR_WORKSPACE_DETAILS } from "src/actions/type";

const workspaceDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_WORKSPACE_DETAILS:
      return { ...action.payload };
    case CLEAR_WORKSPACE_DETAILS:
      return action.payload;
    default:
      return state;
  }
};

export default workspaceDetailsReducer;
