import SubArrowIcon from "src/components/Shared/Icons/SubArrowIcon";
import Input from "src/components/Shared/Forms/Inputs/Input";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import TextCode from "src/components/Shared/Text/TextCode";
import { TrashIcon } from "@heroicons/react/24/outline";

const SSOAttributeMapper = ({
  type = "content",
  text = "",
  value = "",
  mapAttributeDropdownData = [],
  includedDropdownData = [],
  includedIndex = 0,
  mapAttributeIndex = 0,
  customFieldAttributeDropdownData = [],
  customFieldAttributeIndex = 0,
  setValue = () => { },
  removeData = () => { },
  setIncludedOption = () => { },
  setMapAttributeOption = () => { },
  setCustomFieldAttributeOption = () => { },
}) =>
{
  return (
    <div className="flex items-center">
      <SubArrowIcon />
      <div className="relative flex min-h-14 space-x-3 rounded border border-gray-200 pl-4">
        <div className="flex flex-wrap items-center gap-x-3 gap-y-2 sm:gap-y-1 py-1.5">
          {type === "user" ? (
            <>
              <div className="relative flex items-center flex-wrap sm:flex-nowrap gap-2 sm:gap-1 text-sm font-medium text-gray-700">The incoming SSO user will have an attribute named</div>
              <div className="relative w-full sm:w-48">
                <Input
                  name={text}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  inputClassNames={"!h-10 border-gray-300 rounded-md focus:ring-0 focus:border-highlightColor"}
                />
              </div>
              <div className="relative py-2.5 text-sm font-medium text-gray-700">
                that will be assign to the user's
                <div className="inline-flex px-2">
                  <SelectMenu
                    size="sm"
                    classes="!h-10"
                    options={customFieldAttributeDropdownData}
                    startIndex={customFieldAttributeIndex}
                    setOption={setCustomFieldAttributeOption}
                  />
                </div>
                field
              </div>
            </>
          ) : (
            <>
              <div className="relative flex items-center flex-wrap sm:flex-nowrap gap-2 sm:gap-1 text-sm font-medium text-gray-700">
                Add the incoming user to all
                {mapAttributeDropdownData.length > 1 ? (
                  <div className="px-2">
                    <SelectMenu
                      size="sm"
                      classes={"!h-10"}
                      options={mapAttributeDropdownData}
                      startIndex={mapAttributeIndex}
                      setOption={setMapAttributeOption}
                    />
                  </div>
                ) : (
                  <TextCode text={`${text}s`} />
                )}
                where the incoming SSO user attribute of
              </div>
              <div className="relative w-full sm:w-48">
                <Input
                  name={text}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  inputClassNames={"!h-10 w-full border-gray-300 rounded-md focus:ring-0 focus:border-highlightColor text-gray-700"}
                />
              </div>
              <div className="relative text-sm font-medium text-gray-700">
                is equal to an existing {text}'s
                {includedDropdownData.length > 1 ? (
                  <div className="ml-2 inline-flex">
                    <SelectMenu
                      size="sm"
                      classes={"!h-10"}
                      options={includedDropdownData}
                      startIndex={includedIndex}
                      setOption={setIncludedOption}
                    />
                  </div>
                ) : (
                  <TextCode text={`name`} />
                )}
              </div>
            </>
          )}
        </div>
        <div className="flex flex-shrink-0 overflow-hidden rounded pl-6">
          <div
            onClick={removeData}
            className="flex h-full w-10 cursor-pointer items-center justify-center border-gray-200 bg-gray-50 text-gray-500 outline-none ring-0 transition-all duration-200 hover:bg-gray-100 hover:text-gray-600">
            <TrashIcon className="h-5 w-5" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SSOAttributeMapper;
