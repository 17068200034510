// import { EnvelopeIcon, PaintBrushIcon, SquaresPlusIcon, BanknotesIcon } from "@heroicons/react/24/outline";
import { EnvelopeIcon, PaintBrushIcon, SquaresPlusIcon } from "@heroicons/react/24/outline";

import { useEffect } from "react";
import { connect } from "react-redux";
import { getSiteSettings } from "src/actions/site";

import Tabs from "src/components/Shared/Tabs/Tabs";
import { H1 } from "src/components/Shared/Text/Headers";
import SiteEmailManager from "src/components/Site/SiteEmailManager";
import GlobalSettings from "src/components/Site/GlobalSettings";
import SiteThemeAndStyles from "src/components/Site/SiteThemeAndStyles";
// import BillingSettings from "./BillingSettings/BillingSettings";

const SiteSection = ({ ...props }) => {
  const tabs = [
    { name: "Themes & Styles", icon: PaintBrushIcon, component: <SiteThemeAndStyles /> },
    { name: "Email Provider", icon: EnvelopeIcon, component: <SiteEmailManager /> },
    // { name: "Billing Settings", icon: BanknotesIcon, component: <BillingSettings /> },
    { name: "Global Settings", icon: SquaresPlusIcon, component: <GlobalSettings /> },
  ];

  const loadSiteSettings = async () => await props.getSiteSettings();

  useEffect(() => {
    const ac = new AbortController();

    loadSiteSettings({}, ac);

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <H1>Site Settings</H1>
      <Tabs tabs={tabs} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { getSiteSettings })(SiteSection);
