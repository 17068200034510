import { Menu } from "@headlessui/react";
import { Fragment, useContext } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout, getAuthorizedUser } from "src/actions/auth";
import { classNames } from "src/helpers/classNames";
import { resetWorkspaceDetails } from "src/actions/page";
import { PortalWorkspaceLoadContext } from "src/context/PortalWorkspaceLoad";
import Button from "src/components/Shared/Buttons/Button";

function AdminNavDesktop({ userNavigation, resetWorkspaceDetails, getAuthorizedUser, logout }) {
  const { setShowLoader } = useContext(PortalWorkspaceLoadContext)
  const navigate = useNavigate();

  const updateLoadedUser = async (ac) => {
    await getAuthorizedUser(ac);
    resetWorkspaceDetails()
  };

  const handleRemovePreviewUser = async () => {
    resetWorkspaceDetails()
    localStorage.removeItem('impersonatedUserId')

    const ac = new AbortController();
    await updateLoadedUser(ac.signal);
    setShowLoader(true)
  };

  return (
    <>
      {userNavigation.map((item, site, updateNavigation) => (
        <Menu.Item key={item.name}>
          {({ active }) => (
            <Fragment key={item.name}>
              {item.name !== "Sign out" ? (
                <div onClick={(navItem) => (updateNavigation.includes(navItem.name) ? navigate(navItem.name) : null)}>
                  {(item.path.includes("http") || item.reloadLink) ? (
                    <a
                      href={item.path}
                      target={item.reloadLink ? "_self" : "_blank"}
                      rel="noopener noreferrer"
                      className={classNames(active ? "bg-color-06 bg-gray-100" : "", "flex px-4 py-2 text-sm text-gray-700 hover:text-highlightColor")}
                    >
                      {item.name} {item.icon && <div className="flex pl-2">{item.icon}</div>}
                    </a>
                  ) : (
                    <Link
                      to={item.path}
                      className={classNames(active ? "bg-color-06 bg-gray-100" : "", "flex px-4 py-2 text-sm text-gray-700 hover:text-highlightColor")}>
                      {item.name} {item.icon && <div className="flex pl-2">{item.icon}</div>}
                    </Link>
                  )}
                </div>
              ) : (
                <>
                  { userNavigation.length > 1 && <hr className="py-.5 mt-1" /> }
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      if (!localStorage.getItem('impersonatedUserId')) {
                        logout(site)
                      } else {
                        handleRemovePreviewUser()
                      }
                    }}
                    version="default"
                    className="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:text-highlightColor">
                    {item.name}
                  </Button>
                </>
              )}
            </Fragment>
          )}
        </Menu.Item>
      ))}
    </>
  );
}

export default connect(null, { logout, resetWorkspaceDetails, getAuthorizedUser })(AdminNavDesktop);
