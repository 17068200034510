import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import { classNames } from "src/helpers/classNames";
import { toast } from "react-toastify";
import Modal from "src/components/Shared/Modal";
import { CheckIcon, NoSymbolIcon } from "@heroicons/react/24/outline";
import { manageDigitalOceanCredential, manageDigitalOceanSubdomain } from "src/actions/site";
import Input from "src/components/Shared/Forms/Inputs/Input";
import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";

const DomainSection = ({ siteData, ...props }) => {
  const [allowSubdomains, setAllowSubdomains] = useState(false);
  const [digitalOceanAppId, setDigitalOceanAppId] = useState("");
  const [digitalOceanAppSecret, setDigitalOceanAppSecret] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [credentialIsDisabled, setCredentialIsDisabled] = useState(false);

  const handleSubDomainUpdate = async () => {
    try {
      const data = await props.manageDigitalOceanSubdomain({ allow_subdomains: allowSubdomains });
      setDigitalOceanAppId("");
      setDigitalOceanAppSecret("");
      if (data?.isCredentialInvalidOrMissing) {
        setIsOpen(true);
      } else {
        toast.success(data?.message);
      }
    } catch (error) {
      setDigitalOceanAppId("");
      setDigitalOceanAppSecret("");
      setAllowSubdomains(siteData?.allow_subdomains);
    }
  };

  const handleSubDomainCredential = async () => {
    try {
      setCredentialIsDisabled(true);
      const message = await props.manageDigitalOceanCredential({ digital_ocean_app_id: digitalOceanAppId, digital_ocean_app_secret: digitalOceanAppSecret, allow_subdomains: allowSubdomains });
      setIsOpen(false);
      setDigitalOceanAppId("");
      setDigitalOceanAppSecret("");
      toast.success(message);
      setCredentialIsDisabled(false);
    } catch (error) {
      setCredentialIsDisabled(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setAllowSubdomains(siteData?.allow_subdomains || false);
  }, [siteData]);

  return (
    <>
      <EditContainer
        title="Allow subdomains"
        preview={
          <div className="flex h-10 w-56 items-center">
            <p className={classNames("flex gap-x-2 rounded px-2 py-1 opacity-60", !allowSubdomains ? "bg-gray-50" : "bg-green-50 text-green-700")}>
              {!allowSubdomains ? (
                <>
                  <NoSymbolIcon className="h-5 w-5" /> Disabled
                </>
              ) : (
                <>
                  <CheckIcon className="h-5 w-5" /> Enabled
                </>
              )}
            </p>
          </div>
        }
        onSuccess={handleSubDomainUpdate}
        onCancel={() => {
          setAllowSubdomains(siteData?.allow_subdomains);
          setDigitalOceanAppId("");
          setDigitalOceanAppSecret("");
        }}>
        <div className="flex w-full flex-wrap gap-x-4 gap-y-6">
          <div className="min-w-[260px] px-2">
            <ToggleHeader
              title="Allow subdomains"
              subtitle="Allow workspace editors to create sub domain for each workspace. Make sure this feature has been enabled before toggling."
              position="left">
              <Toggle
                checked={allowSubdomains}
                onChange={() => {
                  setAllowSubdomains(!allowSubdomains);
                }}
              />
            </ToggleHeader>
          </div>
        </div>
      </EditContainer>
      <Modal
        title="Credential"
        secondaryTitle="Add"
        isOpen={!!isOpen}
        onCancel={() => {
          setIsOpen(false);
          setAllowSubdomains(siteData?.allow_subdomains || false);
        }}
        onSuccess={handleSubDomainCredential}
        defaultOptions={{
          onSuccessButtonText: "Save",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: credentialIsDisabled,
        }}>
        <div className="flex w-full flex-wrap gap-x-4 gap-y-6">
          <div className="w-full px-2">
            <Input
              name="app-id"
              type="password"
              label="App ID"
              autoComplete="new-password"
              placeholder={"App ID"}
              value={digitalOceanAppId}
              onChange={(e) => {
                setDigitalOceanAppId(e.target.value);
              }}
            />
          </div>
          <div className="w-full px-2">
            <Input
              name="app-secret-key"
              type="password"
              label="App Secret Key"
              autoComplete="new-password"
              placeholder={"App Secret Key"}
              value={digitalOceanAppSecret}
              onChange={(e) => {
                setDigitalOceanAppSecret(e.target.value);
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    siteData: state.site,
  };
};

export default connect(mapStateToProps, { manageDigitalOceanSubdomain, manageDigitalOceanCredential })(DomainSection);
