import { useCallback, useEffect, useState } from "react";
import Modal from "src/components/Shared/Modal";
import { apiRequest } from "src/async/apiUtils";
import { toast } from "react-toastify";
import NoDataDashedContainer from "src/components/Shared/NoData/NoDataDashedContainer";
import Button from "src/components/Shared/Buttons/Button";
import { PlusIcon } from "@heroicons/react/24/outline";
import SSOAttributeMapper from "src/components/Workspaces/Workspace/SSO/SSOAttributeMapper";

const WorkspaceSSOMappingToUserAttributesModal = ({ title = "", secondaryTitle = "", item = null, workspaceId = null, isOpen, onCancel = () => {}, onSuccess = () => {} }) => {
  const customFieldAttributeJson = {
    custom_field_id: null,
    value: "",
  };
  const [customFieldsAttribute, setCustomFieldsAttribute] = useState([]);
  const [dynamicAttributes, setDynamicAttributes] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const onUpdate = async () => {
    try {
      setDisabled(true);
      const { data } = await apiRequest("POST", workspaceId ? `/workspaces/:workspace_id/sso/custom-field/settings/edit` : `/sso/custom-field/settings/edit`, {
        body: {
          id: item?._id,
          custom_fields_attribute: customFieldsAttribute,
        },
        params: { workspace_id: workspaceId },
      });
      setDisabled(false);
      toast.success(data.message);
      onSuccess();
    } catch (error) {
      setDisabled(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setCustomFieldsAttribute((prevData) => [
      ...prevData?.map((item) => ({
        ...item,
        dynamicAttributes: dynamicAttributes || [],
      })),
    ]);
  }, [dynamicAttributes]);

  useEffect(() => {
    setCustomFieldsAttribute(
      item?.custom_fields_attribute?.map((item) => ({
        ...item,
        dynamicAttributes: dynamicAttributes || [],
      })) || [],
    );
  }, [item?.custom_fields_attribute, dynamicAttributes]);

  const loadDynamicAttributes = useCallback(() => {
    (async () => {
      try {
        const { data: resData } = await apiRequest("GET", `/users/custom-fields`);

        if (resData.status === 200 && resData.data) {
          setDynamicAttributes(resData.data);
        }
      } catch (err) {
        console.dir("err", err);
      }
    })();
  }, []);

  useEffect(() => {
    loadDynamicAttributes();
  }, [loadDynamicAttributes]);

  return (
    <>
      <Modal
        title={title}
        secondaryTitle={secondaryTitle}
        isOpen={isOpen}
        size={`xxl`}
        onCancel={onCancel}
        onSuccess={onUpdate}
        defaultOptions={{
          onSuccessButtonText: "Save",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: disabled,
        }}
        defaultStyles={{
          overFlowYVisible: false,
        }}>
        <div className="relative min-h-[200px] overflow-visible py-4">
          {customFieldsAttribute?.length === 0 ? (
            <div>
              <NoDataDashedContainer
                addButton={
                  <Button
                    disabled={!dynamicAttributes.filter((dynamicAttribute) => !customFieldsAttribute.map((data) => data?.custom_field_id?.toString()).includes(dynamicAttribute?._id?.toString()))?.length}
                    version="secondary"
                    hoverText="Map attribute to user attribute"
                    onClick={() => {
                      setCustomFieldsAttribute((prevData) => [
                        ...prevData,
                        {
                          ...customFieldAttributeJson,
                          dynamicAttributes,
                          custom_field_id:
                            dynamicAttributes.filter((dynamicAttribute) => !customFieldsAttribute.map((data) => data?.custom_field_id?.toString()).includes(dynamicAttribute?._id?.toString()))?.length > 0
                              ? dynamicAttributes.filter((dynamicAttribute) => !customFieldsAttribute.map((data) => data?.custom_field_id?.toString()).includes(dynamicAttribute?._id?.toString()))[0]?._id
                              : customFieldAttributeJson?.custom_field_id,
                        },
                      ]);
                    }}>
                    <PlusIcon className="h-4 w-4 stroke-2" /> Add SSO attribute
                  </Button>
                }
              />
            </div>
          ) : (
            <div>
              {customFieldsAttribute.map((field, index) => (
                <div key={field?._id || field?.id}>
                  <SSOAttributeMapper
                    type={"user"}
                    value={field.value}
                    setValue={(value) => {
                      setCustomFieldsAttribute((prevData) => [
                        ...prevData.map((data, prevIndex) =>
                          prevIndex === index
                            ? {
                                ...data,
                                value,
                              }
                            : data,
                        ),
                      ]);
                    }}
                    customFieldAttributeDropdownData={field?.dynamicAttributes
                      .filter((dynamicAttribute) => field.custom_field_id?.toString() === dynamicAttribute?._id?.toString() || !customFieldsAttribute.map((data) => data?.custom_field_id?.toString()).includes(dynamicAttribute?._id?.toString()))
                      .map((dynamicAttribute) => ({
                        value: dynamicAttribute?._id,
                        key: dynamicAttribute?.label,
                      }))}
                    customFieldAttributeIndex={
                      field?.dynamicAttributes
                        .filter((dynamicAttribute) => field.custom_field_id?.toString() === dynamicAttribute?._id?.toString() || !customFieldsAttribute.map((data) => data?.custom_field_id?.toString()).includes(dynamicAttribute?._id?.toString()))
                        .findIndex((dynamicAttribute) => dynamicAttribute?._id?.toString() === field?.custom_field_id?.toString()) || 0
                    }
                    setCustomFieldAttributeOption={(option) => {
                      setCustomFieldsAttribute((prevData) => [
                        ...prevData.map((prevItem, prevIndex) =>
                          prevIndex === index
                            ? {
                                ...prevItem,
                                custom_field_id: option.value,
                              }
                            : prevItem,
                        ),
                      ]);
                    }}
                    removeData={() => {
                      setCustomFieldsAttribute((prevData) => [...prevData.filter((_, prevIndex) => prevIndex !== index)]);
                    }}
                  />
                </div>
              ))}
              <div className="flex w-full items-center justify-end space-x-4 pr-4 pt-2">
                <Button
                  disabled={!dynamicAttributes.filter((dynamicAttribute) => !customFieldsAttribute.map((data) => data?.custom_field_id?.toString()).includes(dynamicAttribute?._id?.toString()))?.length}
                  version="secondary"
                  hoverText="Map attribute to user attribute"
                  onClick={() => {
                    setCustomFieldsAttribute((prevData) => [
                      ...prevData,
                      {
                        ...customFieldAttributeJson,
                        dynamicAttributes,
                        custom_field_id:
                          dynamicAttributes.filter((dynamicAttribute) => !customFieldsAttribute.map((data) => data?.custom_field_id?.toString()).includes(dynamicAttribute?._id?.toString()))?.length > 0
                            ? dynamicAttributes.filter((dynamicAttribute) => !customFieldsAttribute.map((data) => data?.custom_field_id?.toString()).includes(dynamicAttribute?._id?.toString()))[0]?._id
                            : customFieldAttributeJson?.custom_field_id,
                      },
                    ]);
                  }}>
                  <PlusIcon className="h-4 w-4 stroke-2" /> Add SSO attribute
                </Button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default WorkspaceSSOMappingToUserAttributesModal;
