import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalIcon } from "@heroicons/react/24/outline";

const Pagination = () => {
  return (
    <>
      <div className="flex items-center justify-center gap-4 pt-4">
        <button
          type="button"
          className="text-gray-700 flex items-center justify-center w-10 h-10 p-0 font-semibold bg-white rounded-full border border-gray-200 shadow-sm">
          <ChevronLeftIcon className="w-5 h-5 stroke-2" />
        </button>
        <div className="flex items-center justify-center gap-2">
          <button
            type="button"
            className="flex items-center justify-center w-8 h-8 p-0 font-bold bg-white rounded-full hover:highlightColor bg-highlightColor text-white">1
          </button>
          <button
            type="button"
            className="flex items-center justify-center w-8 h-8 p-0 font-bold bg-white rounded-full hover:bg-gray-100 bg-transparent text-gray-600">2
          </button>
          <button
            type="button"
            className="flex items-center justify-center w-8 h-8 p-0 font-bold bg-white rounded-full hover:bg-gray-100 bg-transparent text-gray-600">3
          </button>
          <button
            type="button"
            className="flex items-center justify-center w-8 h-8 p-0 font-bold bg-white rounded-full hover:bg-gray-100 bg-transparent text-gray-600">
            <EllipsisHorizontalIcon className="w-5 h-5 stroke-2" />
          </button>
          <button
            type="button"
            className="flex items-center justify-center w-8 h-8 p-0 font-bold bg-white rounded-full hover:bg-gray-100 bg-transparent text-gray-600">10
          </button>
        </div>
        <button
          type="button"
          className="text-gray-700 flex items-center justify-center w-10 h-10 p-0 font-semibold bg-white rounded-full border border-gray-200 shadow-sm">
          <ChevronRightIcon className="w-5 h-5 stroke-2" />
        </button>
      </div>
    </>
  );
};

export default Pagination;