import { connect } from "react-redux";
import { useNavigate } from "react-router";
import Button from "src/components/Shared/Buttons/Button";

const SsoLogout = ({ ...props }) => {
  const navigate = useNavigate();
  return (
    <div className="min-h-full flex flex-col justify-center py-12 px-2 sm:px-6 lg:px-8 w-full">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl sm:text-3xl font-extrabold text-gray-900">
          You have been logged out
        </h2>
        <div className="mt-1 text-center text-regular text-gray-600">
          Get access to all your data in one perfectly organized place by logging back in.
        </div>
      </div>
      <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-lg">
        <div className="px-4 py-4 sm:px-10">
          <Button
            width="w-full"
            onClick={() => navigate(`/`)}>
            Login
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site
  };
};

export default connect(mapStateToProps, {})(SsoLogout);
