import React from "react";
import NoData from "src/components/Shared/NoData/NoData";

const NoDataDashedContainer = ({ title = "No data found", type = "", image = { icon: null }, addButton = null }) => {
  return (
    <div className="mt-2 transition-all duration-150 h-[120px] relative border-2 border-dashed border-gray-300 rounded-xl p-5 flex flex-col justify-center items-center">
      {addButton ? (
        addButton
      ) : (
        <NoData
          title={title}
          type={type}
          image={image}
        />
      )}
    </div>
  );
};

export default NoDataDashedContainer;
