import { Menu, Transition } from '@headlessui/react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';

const Filter = () => {
  return (
    <>
      <Menu
        as="div"
        className="relative inline-block text-left">
        <div>
          <Menu.Button className="h-full inline-flex items-center justify-center transition-all duration-200 rounded-md px-2 py-2 text-regular font-medium focus:ring-2 focus:border-gray-400  bg-white text-gray-500 border-[1px] border-gray-300 hover:shadow-sm sm:w-auto gap-x-2 !ring-0">
            <FunnelIcon className="w-5 h-5"/>
            Apply Filter
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="absolute z-50 left-0 mt-2 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="p-6 flex justify-center items-center">
              <div className="text-base font-medium">Filter Content</div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default Filter;