import { connect } from "react-redux";
import { classNames } from "src/helpers/classNames";
import ExternalLinkEmbed from "src/components/impersonate/ExternalLinkEmbed";

const LinkEmbed = ({ content, workspaceDetails, user }) => {
  return (
    <div className="relative w-full">
      <div className={classNames("mx-auto w-full overflow-auto", !localStorage.getItem("impersonatedUserId") ? "h-[calc(100vh-10rem)]" : workspaceDetails?.layout_type === "TOP_NAVIGATION" ? "h-[calc(100vh-4rem)] lg:h-[calc(100vh-120px)]" : "h-[calc(100vh-4rem)]")}>
        <ExternalLinkEmbed
          href={content?.page_link}
          target={content?.page_link_newtab}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    user: state.auth.user,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, {})(LinkEmbed);
