import React from "react";
import { classNames } from "src/helpers/classNames";

export default function SingleLineCheckbox({ value, disabled = false, onChange = () => {}, title = "title", subtitle = "subtitle" }) {
  return (
    <div className={classNames("relative flex flex-col", disabled && "opacity-50")}>
      <div className="flex justify-between text-base font-medium text-gray-700">{title}</div>
      <div className="mt-1 flex items-center pt-1 md:pt-0">
        <input
          disabled={disabled}
          checked={value}
          id="type"
          aria-describedby="notifications-description"
          name="type"
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-highlightColor"
          onChange={() => onChange(!value)}
        />
        <p className="ml-3 text-sm text-gray-400">{subtitle}</p>
      </div>
    </div>
  );
}
