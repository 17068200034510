import mapKeys from "lodash/mapKeys";
import omit from "lodash/omit";
import {
  GET_WORKSPACE_DATA,
  ADD_WORKSPACE,
  REMOVE_WORKSPACE,
  LOG_OUT,
  EDIT_WORKSPACE,
  // REMOVE_PAGE
} from "src/actions/type";

const workspaceReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_WORKSPACE_DATA:
      return { ...mapKeys(action.payload, "_id") };
    case ADD_WORKSPACE:
      return { ...state, [action.payload._id]: action.payload };
    case EDIT_WORKSPACE:
      return { ...state, [action.payload._id]: action.payload };
    case REMOVE_WORKSPACE:
      return omit(state, action.payload);
    case LOG_OUT:
      return {};
    // case REMOVE_PAGE:
    //   return omit(state, action.payload);
    default:
      return state;
  }
};

export default workspaceReducer;
