import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { H1 } from "src/components/Shared/Text/Headers";
import WorkspaceList from "src/components/Workspaces/WorkspaceList";

const Workspaces = ({ me }) => {
  const navigate = useNavigate();
  const [viewOnly, setViewOnly] = useState(true);

  useEffect(() => {
    if (me?._id) {
      if (me?.type === "admin") {
        setViewOnly(false);
      }
      if (me?.type === "editor" && me?.workspaces?.length === 1) {
        navigate(`/workspaces/${me?.workspaces[0]._id}`); 
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);
  return (
    <>
      {(me?.type === "admin" || me?.workspaces?.length > 1) && (
        <>
          <H1>Workspaces</H1>
          <WorkspaceList viewOnly={viewOnly} />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    workspaces: Object.values(state.workspaces),
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(Workspaces);
