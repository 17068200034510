import React from 'react';
import { classNames } from "src/helpers/classNames";

const ActivityItem = ({data}) => {
  return (
    <>
      <div className="relative flex gap-5 py-4">
        <div className={classNames("w-12 h-12 min-w-[48px] min-h-[48px] flex items-center justify-center rounded-full" , data.color, data.background)}>
          <data.icon className="w-7 h-7"/>
        </div>
        <div className="relative w-full py-1">
          <div className="absolute top-0 right-0 text-sm text-gray-400">{data.date}</div>
          <div className="pr-24 font-semibold text-gray-600 text-base">{data.label}</div>
          <div className="text-sm text-gray-500">{data.text}</div>
        </div>
      </div>
    </>
  );
};

export default ActivityItem;