import React, { useEffect, useRef, useState } from "react";
import Button from "../Buttons/Button";
import Search from "../Search";
import Preloader from "../LoadingAnimations/Preloader";
import useOnClickOutside from "src/hooks/useOnClickOutside";

const AutoPopulate = ({ defaultValue = "", placeholder, isLoading = false, loaderText = "", options = [], onClick = () => {}, onChange = () => {}, searchableFields = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [keyword, setKeyword] = useState("");
  const ref = useRef();

  useOnClickOutside(ref, () => setIsOpen(false));

  const onHandleSelect = (item) => {
    setIsOpen(false);
    onClick(item);
  };

  useEffect(() => {
    setKeyword(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setFilteredOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    if (searchableFields.length > 0) {
      let updatedFilteredOptions = options.filter((option) => {
        let keywordMatched = false;
        searchableFields.forEach((searchableField) => {
          if (option?.object[searchableField]?.toLowerCase().includes(keyword.toLowerCase())) {
            keywordMatched = true;
          }
        });
        return keywordMatched;
      });
      setFilteredOptions(updatedFilteredOptions);
    }
  }, [keyword, options, searchableFields]);

  return (
    <div
      className="relative"
      ref={ref}>
      <Search
        placeholder={placeholder}
        inline={true}
        setIsFocused={setIsOpen}
        isFocused={isOpen}
        keyword={keyword}
        setKeyword={(e) => {
          setKeyword(e);
          onChange(e);
        }}
      />
      {isOpen && (filteredOptions?.length > 0 || isLoading) && (
        <div className="border-opplio-border absolute z-10 mt-1 w-full origin-top-right rounded-md border bg-white px-2 py-1 shadow-lg focus:outline-none">
          <div className="scrollbar -mx-1 max-h-60 overflow-auto px-1">
            {isLoading ? (
              <Preloader
                text={loaderText}
                circleDimension={"6"}
                size={"sm"}
              />
            ) : (
              filteredOptions?.map((item, index) => (
                <Button
                  key={index}
                  version="default"
                  className="flex !h-9 w-full items-center px-3 text-sm text-gray-500 transition-all duration-200 hover:bg-gray-50 hover:text-gray-700"
                  onClick={() => onHandleSelect(item)}>
                  {item.Component ? item.Component() : item.key}
                </Button>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AutoPopulate;
