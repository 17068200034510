import { FunnelIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import MultiRowSelectedCountBubble from "src/components/Shared/Table/MultiRowSelect/MultiRowSelectedCountBubble";
import { classNames } from "src/helpers/classNames";
import useOnClickOutside from "src/hooks/useOnClickOutside";
import Search from "src/components/Shared/Search";

// EXAMPLE OPTION:
// const option = {
//   key: 1,
//   value: "text",
//   selected: false,
//   object: {
//     _id: 'ObjectId("HDJNC14HDB792")',
//     name: "John Snow",
//     age: 26
//   },
// };

const MultiSelectObjectFilter = ({ defaultOptions = [], useSelectAll = true, searchableFields = [], placeholder = "Search", onChange = () => {}, createP = true, customSelector = null, itemComponent = null }) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [panelOpen, setPanelOpen] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);

  const ref = useRef();

  useOnClickOutside(ref, () => setPanelOpen(false));

  useEffect(() => {
    if (!selectedOptions.length) {
      setSelectedOptions(defaultOptions);
      setFilteredOptions(defaultOptions);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOptions]);

  const setAllOptionsClickValues = (value) => {
    let updatedOptions = selectedOptions.map((option) => {
      option.selected = value;
      return option;
    });
    setSelectedOptions(updatedOptions);
    onChange(updatedOptions);
  };

  const handleSelectAll = () => {
    let isAllSelected = selectedOptions.filter((option) => {
      return option.selected === true;
    }).length;

    if (selectedOptions.length === isAllSelected) {
      setAllOptionsClickValues(false);
    } else {
      setAllOptionsClickValues(true);
    }
  };

  const optionClicked = (key) => {
    let updatedOptions = selectedOptions.map((option) => {
      if (option.key === key) {
        if (option.selected) option.selected = false;
        else option.selected = true;
      }
      return option;
    });
    setSelectedOptions(updatedOptions);
    onChange(updatedOptions);
  };

  useEffect(() => {
    if (searchableFields.length > 0) {
      let updatedFilteredOptions = selectedOptions.filter((option) => {
        let keywordMatched = false;
        searchableFields.forEach((searchableField) => {
          if (option?.object[searchableField]?.toLowerCase().includes(keyword.toLowerCase())) {
            keywordMatched = true;
          }
        });
        return keywordMatched;
      });
      setFilteredOptions(updatedFilteredOptions);
    }
  }, [keyword, selectedOptions, searchableFields]);

  return (
    <div
      className="relative"
      ref={ref}>
      {createP && (
        <>
          {selectedOptions.reduce((count, option) => (option.selected ? count + 1 : count), 0) > 0 && (
            <MultiRowSelectedCountBubble
              count={selectedOptions.reduce((count, option) => (option.selected ? count + 1 : count), 0)}
              clear={() => setAllOptionsClickValues(false)}
            />
          )}
          <div onClick={() => setPanelOpen(true)}>
            {customSelector ? (
              customSelector
            ) : (
              <div className={classNames("relative cursor-pointer rounded text-gray-400 transition-all duration-100 hover:bg-gray-200/30", panelOpen ? "bg-gray-200/30" : "bg-gray-200/0")}>
                <FunnelIcon className="py-.5 relative z-20 h-6 w-6 px-[2px]" />
              </div>
            )}
          </div>
          <div className="absolute left-2 top-5 z-50 mt-3 flex w-64 justify-between rounded-md">
            <div className={classNames("absolute z-30 max-h-64 min-w-full overflow-auto rounded-md bg-white shadow-lg", panelOpen ? "flex flex-col" : "hidden")}>
              {searchableFields.length > 0 && (
                <div className="sticky left-0 top-0 z-20 mb-2 flex w-full bg-white p-1.5">
                  <Search
                    placeholder={placeholder}
                    keyword={keyword}
                    setKeyword={setKeyword}
                    inline={true}
                  />
                </div>
              )}
              {useSelectAll && !keyword && (
                <div
                  key="SELECT_ALL"
                  className="group flex w-full cursor-pointer items-center gap-x-3 border-white bg-gray-50 px-4 py-2 font-normal text-gray-400 transition-all duration-100 hover:bg-gray-100 hover:text-gray-600"
                  onClick={handleSelectAll}>
                  <input
                    type="checkbox"
                    checked={
                      selectedOptions.filter((option) => {
                        return option.selected === true;
                      }).length === selectedOptions.length
                    }
                    className="cursor-pointer rounded border-gray-400 bg-gray-50 text-highlightColor focus:ring-highlightColor group-hover:border-gray-500"
                    readOnly
                  />
                  Select All
                </div>
              )}
              {filteredOptions.map((option) => {
                return (
                  <div
                    key={option.key}
                    className="flex w-full cursor-pointer items-center gap-x-3 border-white px-4 py-2 font-normal text-gray-800 transition-all duration-100 hover:bg-gray-100 hover:text-gray-900"
                    onClick={() => optionClicked(option.key)}>
                    <input
                      type="checkbox"
                      checked={option.selected}
                      className="cursor-pointer rounded text-highlightColor focus:ring-highlightColor"
                      readOnly
                    />
                    {itemComponent ? itemComponent(option.object) : option.value}
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MultiSelectObjectFilter;
