import React, { useState } from "react";
import { connect } from "react-redux";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
import BetaHeader from "src/components/Activity/BetaHeader";
import Table from "src/components/Shared/Table/Table";
import TRHeader from "src/components/Shared/Table/TRHeader";
import UserCustomFieldItem from "src/components/Users/UserCustomFieldItem";
import ManageCustomAttributeModal from "src/components/Users/Modal/ManageCustomAttributeModal";
import Modal from "src/components/Shared/Modal";
import useFetch from "src/hooks/useFetch";
import { apiRequest } from "src/async/apiUtils";
import { toast } from "react-toastify";

const CustomAttributeList = ({ me }) => {
  const [isDeleteCustomFieldLoading, setIsDeleteCustomFieldLoading] = useState(false);
  const [deleteCustomFieldId, setDeleteCustomFieldId] = useState(null);
  const [editCustomField, setEditCustomField] = useState(null);
  const [showManageAttributeModal, setShowManageAttributeModal] = useState(false);

  const {
    response: { data: customFields },
    status: { done: customFieldLoaded },
    refreshData: refreshCustomFields,
  } = useFetch(`/users/custom-fields`);

  const manageCustomFieldReuired = async (id) => {
    try {
      await apiRequest("post", `/users/custom-fields/:id/required-change`, { params: { id } });
      refreshCustomFields();
    } catch (error) {}
  };

  const handleDeleteCustomField = async () => {
    try {
      setIsDeleteCustomFieldLoading(true);
      await apiRequest("delete", `/users/custom-fields/:id`, { params: { id: deleteCustomFieldId } });
      refreshCustomFields();
      setDeleteCustomFieldId(null);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsDeleteCustomFieldLoading(false);
    }
  };

  return (
    <Section>
      <div className="grid w-full gap-y-10">
        <div className="relative mb-5 w-full space-y-6">
          {me?.type === "admin" && (
            <>
              <div className="flex items-center justify-between">
                <BetaHeader
                  title="Custom Attributes"
                  subtitle={"A list of all user's custom attributes in your account including their labels, default values, and required status for safe filtering."}
                  horizontalRow={false}
                  isBeta={false}
                />
                <Button
                  version="primary"
                  onClick={() => {
                    setEditCustomField(null);
                    setShowManageAttributeModal(true);
                  }}>
                  Add attributes
                </Button>
              </div>
              <Table
                tableHeader={
                  <TRHeader>
                    <th
                      scope="col"
                      className="rounded-tl-lg px-6 py-3 text-left text-sm font-semibold text-gray-900">
                      Label
                    </th>
                    <th
                      scope="col"
                      className="rounded-tl-lg px-6 py-3 text-left text-sm font-semibold text-gray-900">
                      Default value
                    </th>
                    <th
                      scope="col"
                      className="rounded-tl-lg px-6 py-3 text-left text-sm font-semibold text-gray-900">
                      Required
                    </th>
                    <th
                      scope="col"
                      className="relative rounded-tr-lg py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Action</span>
                    </th>
                  </TRHeader>
                }
                colSpan="4"
                loaded={customFieldLoaded}
                dataExists={customFields?.length > 0}>
                {customFields.map((field, i) => (
                  <UserCustomFieldItem
                    key={field?._id}
                    item={field}
                    onEdit={() => {
                      setEditCustomField(field);
                      setShowManageAttributeModal(true);
                    }}
                    manageCustomFieldReuired={() => manageCustomFieldReuired(field?._id)}
                    onDelete={() => setDeleteCustomFieldId(field?._id)}
                  />
                ))}
              </Table>
              <ManageCustomAttributeModal
                field={editCustomField}
                isOpen={showManageAttributeModal}
                onCancel={() => setShowManageAttributeModal(false)}
                onSuccess={() => {
                  setShowManageAttributeModal(false);
                  refreshCustomFields();
                }}
              />
              <Modal
                title="Custom Attribute"
                secondaryTitle="Delete"
                isOpen={deleteCustomFieldId ? true : false}
                onCancel={() => setDeleteCustomFieldId(null)}
                onSuccess={handleDeleteCustomField}
                defaultOptions={{
                  onSuccessButtonText: "Delete",
                  onSuccessLoaderVisible: true,
                  onSuccessLoaderStart: isDeleteCustomFieldLoading,
                }}>
                <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete? You cannot undo this change.</div>
              </Modal>
            </>
          )}
        </div>
      </div>
    </Section>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(CustomAttributeList);
