import { connect } from "react-redux";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import Button from "src/components/Shared/Buttons/Button";
import { getPortalWorkspacesFirstPage } from "src/actions/impersonate";
import { useNavigate } from "react-router";

const ContentError = ({ pageError, user, showRefreshButton = true }) => {
  const pageErrorTypes = {
    permission_denied: {
      title: "Refresh",
      subtitle: "No content assigned",
      secondary: "Contact us and request access to your pages and analytics.",
    },
    page_error: {
      title: "Reload Page",
      subtitle: "Oop... there was a loading error.",
      secondary: "Something went wrong, please try later",
    },
    incorrect_credentials: {
      title: "Reload Page",
      subtitle: "Oop... there was a loading error.",
      secondary: "It appears the credentials that were added were invalid.",
    },
    incorrect_filters: {
      title: "Reload Page",
      subtitle: "Oop... there was a loading error.",
      secondary: "It appears the filters that were added were invalid or not filled out.",
    },
  };

  const navigate = useNavigate();
  const refreshImpersonate = async (id) => {
    try {
      const { link } = await getPortalWorkspacesFirstPage({ user_id: user?._id });

      if (link) {
        navigate(link);
      }
    } catch (err) {
      // toast.error("Currently user doesn't have any page assigned.");
    }
  };

  return (
    <div className="flex w-full flex-1 flex-col items-center justify-center px-2 py-12 sm:px-6 lg:px-8">
      <div className="flex flex-col items-center sm:mx-auto sm:w-full sm:max-w-md">
        {showRefreshButton && (
          <Button
            version="default"
            onClick={refreshImpersonate}
            className="group flex cursor-pointer items-center gap-x-2 rounded-md bg-gray-50 px-3 py-2 shadow-md transition-all duration-150 hover:bg-gray-100 hover:shadow-lg">
            <p className="flex h-full items-center pb-1 text-2xl text-gray-400 transition-all duration-150 group-hover:text-gray-500">{pageErrorTypes[pageError].title}</p>
            <ArrowPathIcon className="h-6 w-6 stroke-gray-400 transition-all duration-150 group-hover:stroke-gray-500" />
          </Button>
        )}
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 sm:text-3xl">{pageErrorTypes[pageError].subtitle}</h2>
        <div className="text-regular mt-1 text-center text-gray-600">{pageErrorTypes[pageError].secondary}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(ContentError);
