import React, { useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import { toast } from "react-toastify";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import { getBase64 } from "src/helpers/image";
import { apiRequest } from "src/async/apiUtils";

const SSOUploadMetaDataForm = ({ workspaceId = null, formData = null, setFormData = () => {}, onCallback = () => {}, ...props }) => {
  const [metaURL, setMetaURL] = useState("");
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState();
  const [disabled, setDisabled] = useState(false);

  const getXMLDetails = async (body) => {
    try {
      setDisabled(true);
      const { data } = await apiRequest("POST", workspaceId ? `/workspaces/:workspace_id/sso/details/url` : `/sso/details/url`, {
        body,
        params: { workspace_id: workspaceId },
      });
      setDisabled(false);
      setMetaURL("");
      setFile(null);
      onCallback(data.data);
    } catch (error) {
      setDisabled(false);
      toast.error(error.message);
    }
  };

  const getXMLDataByFile = async () => {
    try {
      if (file) {
        getBase64(file, async (result) => {
          await getXMLDetails({ file: result, type: "file" });
        });
      } else {
        toast.error("Please select a XML file");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="relative">
      <div className="w-ful max-w-4xl">
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-4 sm:col-span-4 lg:col-span-3">
            <Input
              inline={true}
              name="column-name"
              label="Identity Provider Name"
              value={formData?.provider_name || ""}
              onChange={(e) => setFormData((prevData) => ({ ...prevData, provider_name: e.target.value }))}
            />
          </div>
          <div className="col-span-4 sm:col-span-4 lg:col-span-3">
            <Input
              inline={true}
              name="column-name"
              label="Metadata URL"
              value={metaURL || ""}
              onChange={(e) => setMetaURL(e.target.value)}
            />
            <div className="mt-1 text-xs text-gray-400">*You can find the EntityID in Your IdP-Metadata XML file enclosed in EntityDescriptor tag having attribute as entityID</div>
          </div>
          <div className="col-span-4 sm:col-span-4 lg:col-span-1">
            <Button
              disabled={disabled}
              type="button"
              onClick={() => getXMLDetails({ url: metaURL, type: "url" })}>
              Fetch Metadata
            </Button>
          </div>
          <div className="col-span-4 sm:col-span-4 lg:col-span-3">
            <div className="relative z-0 flex items-center justify-center py-2">
              <span className="absolute left-0 top-1/2 -z-10 block w-full border-b border-gray-200"></span>
              <span className="bg-white px-5 text-center text-sm text-gray-500">Or continue with</span>
            </div>
          </div>
          <div className="col-span-4 sm:col-span-4 lg:col-span-3">
            <div className="relative border border-gray-300 rounded-md shadow-sm focus-within:border-highlightColor overflow-hidden px-0 flex">
              <div className="relative flex-shrink flex-grow h-[47px] md:h-[40px] 2xl:h-[47px]">
                <label className="uppercase text-[10px] font-semibold text-gray-400 m-0 leading-none pl-3">Metadata File</label>
                <div className="flex items-center h-8 px-3">
                  <PaperClipIcon className="h-5 w-5 text-gray-500" />
                  <input
                    type="text"
                    value={fileName || ""}
                    readOnly
                    className="no-shadow h-10 w-full border-0  px-3 py-2 text-sm outline-none focus:ring-transparent"
                    placeholder=""
                    name=""
                  />
                </div>
              </div>
              <div className="flex-shrink-0 border-l border-gray-200">
                <Button
                  disabled={disabled}
                  type="button"
                  version="default"
                  className="text-highlightColor relative h-full overflow-hidden px-3 text-sm font-semibold">
                  <input
                    type="file"
                    className="absolute right-0 top-0 h-full w-96 cursor-pointer opacity-0"
                    onChange={(e) => {
                      setFile(e.target.files[0]);
                      setFileName(e.target.files[0] ? e.target.files[0].name : "");
                    }}
                    value={undefined}
                  />
                  <span>Choose file</span>
                </Button>
              </div>
            </div>
          </div>
          <div className="col-span-4 sm:col-span-4 lg:col-span-1">
            <Button
              disabled={disabled}
              type="button"
              onClick={getXMLDataByFile}>
              Upload
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SSOUploadMetaDataForm;
