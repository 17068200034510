import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "src/components/Shared/Buttons/Button";
import Search from "src/components/Shared/Search";
import Table from "src/components/Shared/Table/Table";
import TRHeader from "src/components/Shared/Table/TRHeader";
import TableOptions from "src/components/Shared/TableOptions";
import { PlusIcon } from "@heroicons/react/24/outline";
import { apiRequest } from "src/async/apiUtils";
import PdpEntry from "src/components/Workspaces/Workspace/Pdp/PdpEntry";
import PdpModalManage from "src/components/Workspaces/Workspace/Pdp/PdpModalManage";
import WorkspaceImportUserModal from "src/components/Workspaces/Workspace/WorkspaceImportUserModal";
import { toast } from "react-toastify";
import Modal from "src/components/Shared/Modal";
import { v4 } from "uuid";

const WorkspacePdpList = ({ ...props }) => {
  const filterJson = {
    id: v4(),
    column: "",
    values: [],
    operator: "EQUALS",
  };
  const pdpJson = {
    name: "",
    filters: [filterJson],
    users: [],
    groups: [],
  };
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [datasetList, setDatasetList] = useState([]);
  const [pdpMainList, setPdpMainList] = useState([]);
  const [pdpList, setPdpList] = useState([]);
  const [pdpLoaded, setPdpLoaded] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const [isPdpManageModalOpen, setIsPdpManageModalOpen] = useState(false);
  const [pdpId, setPdpId] = useState(null);
  const [datasetId, setdatasetId] = useState(null);
  const [pdpData, setPdpData] = useState({});
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successLoaderStart, setSuccessLoaderStart] = useState(false);
  const [secondaryTitle, setSecondaryTitle] = useState("Add");
  const [modalType, setModalType] = useState("add");

  const loadUserGroupData = async () => {
    try {
      const res = await apiRequest("post", `/workspaces/:workspace_id/user-groups`, {
        body: {
          authToken: props?.workspaceDetails?.auth_token,
          workspace_id: props?.workspaceDetails?._id,
        },
        params: {
          workspace_id: props?.workspaceDetails?._id,
        },
      });

      if (res.data.status === 200) {
        setUsers(res.data.data.users || []);
        setGroups(res.data.data.groups || []);
      }
    } catch (error) {
      // console.dir("ERROR:", error);
    }
  };

  const loadDataset = async () => {
    try {
      const res = await apiRequest("post", `/workspaces/:workspace_id/dataset`, {
        body: {
          authToken: props?.workspaceDetails?.auth_token,
        },
        params: {
          workspace_id: props?.workspaceDetails?._id,
        },
      });

      if (res.data.status === 200) {
        setDatasetList(res.data.data);
      } else {
        setPdpLoaded(true);
      }
    } catch (error) {
      console.dir("ERROR:", error);
    }
  };

  const loadPdp = async (dataset, index, pdpArr = []) => {
    try {
      setPdpLoaded(false);
      const res = await apiRequest("post", `/workspaces/:workspace_id/dataset/:dataset_id/pdp`, {
        body: {
          authToken: props?.workspaceDetails?.auth_token,
        },
        params: {
          workspace_id: props?.workspaceDetails?._id,
          dataset_id: dataset?.id,
        },
      });

      if (res.data.status === 200) {
        pdpArr.push(
          ...res.data.data.map((dt) => {
            return { ...dt, dataset_name: dataset?.name, dataset_id: dataset?.id };
          }),
        );
        setPdpList(pdpArr);
        setPdpMainList(pdpArr);
        index++;
        if (datasetList.length > index) {
          loadPdp(datasetList[index], index, pdpArr);
        }
      }
      setPdpLoaded(true);
    } catch (error) {
      // console.dir("ERROR:", error);
    }
  };

  const managePdp = async () => {
    try {
      const error = manageValidation();
      if (error) {
        toast.error(error);
      } else {
        const notPortalMember = users.filter((user) => pdpData?.users?.includes(user?.id) && !user?.portalUser);
        if (notPortalMember.length > 0) {
          setIsPdpManageModalOpen(false);
          setImportModalOpen(true);
        } else {
          onSubmit(false);
        }
      }
    } catch (error) {
      // console.dir("ERROR:", error);
    }
  };

  const manageValidation = () => {
    if (!pdpData?.name) {
      return `Name field is required.`;
    } else if (!datasetId) {
      return `Dataset field is required.`;
    } else if (pdpData?.filters?.length > 0) {
      for (const filter of pdpData?.filters) {
        if (!filter?.column) {
          return `Column field is required.`;
        } else if (!filter?.operator) {
          return `Operator field is required.`;
        } else if (filter?.values?.length === 0) {
          return `Text value field is required.`;
        } else if (filter?.values?.length > 0) {
          for (const val of filter?.values) {
            if (!val) {
              return `Text value field is required.`;
            }
          }
        }
      }
    }
    return false;
  };

  const onSubmit = async (status) => {
    try {
      const notPortalMember = status ? users.filter((user) => pdpData?.users?.includes(user?.id) && !user?.portalUser) : [];
      setSuccessLoaderStart(true);
      const method = pdpId ? `put` : `post`;
      const url = pdpId ? `/workspaces/:workspace_id/dataset/:dataset_id/pdp/:pdp_id` : `/workspaces/:workspace_id/dataset/:dataset_id/pdp/add`;
      const res = await apiRequest(method, url, {
        body: {
          authToken: props?.workspaceDetails?.auth_token,
          pdpData,
          sendWelcomeEmail,
          password,
          confirmPassword,
          importStatus: status,
          users: notPortalMember,
        },
        params: {
          workspace_id: props?.workspaceDetails?._id,
          dataset_id: datasetId,
          pdp_id: pdpId,
        },
      });

      if (res.data.status === 200) {
        toast.success(res.data.message);
        setImportModalOpen(false);
        setIsPdpManageModalOpen(false);
        if (status && notPortalMember.length > 0) {
          setUsers(
            users.map((user) => {
              if (pdpData?.users.includes(user?.id)) {
                return { ...user, portalUser: true };
              } else {
                return user;
              }
            }),
          );
        }
        if (pdpId) {
          setPdpList(
            pdpList.map((pdp) => {
              if (pdp.id === pdpId) {
                return {
                  ...pdp,
                  ...pdpData,
                  dataset_name: datasetList.find((dataset) => dataset?.id === datasetId).name,
                  dataset_id: datasetId,
                };
              } else {
                return pdp;
              }
            }),
          );
          setPdpMainList(
            pdpMainList.map((pdp) => {
              if (pdp.id === pdpId) {
                return {
                  ...pdp,
                  ...pdpData,
                  dataset_name: datasetList.find((dataset) => dataset?.id === datasetId).name,
                  dataset_id: datasetId,
                };
              } else {
                return pdp;
              }
            }),
          );
        } else {
          setPdpList([
            ...pdpList,
            {
              ...res.data.data,
              dataset_name: datasetList.find((dataset) => dataset?.id === datasetId).name,
              dataset_id: datasetId,
            },
          ]);
          setPdpMainList([
            ...pdpMainList,
            {
              ...res.data.data,
              dataset_name: datasetList.find((dataset) => dataset?.id === datasetId).name,
              dataset_id: datasetId,
            },
          ]);
        }
      } else {
        toast.error(res.data.message);
      }
      setSuccessLoaderStart(false);
    } catch (error) {
      // console.dir("ERROR:", error);
    }
  };

  const onManageModal = (pdp = {}, type) => {
    setSendWelcomeEmail(true);
    setPassword("");
    setConfirmPassword("");
    setPdpId(pdp?.id || null);
    setdatasetId(pdp?.dataset_id || null);
    setPdpData(pdp?.id ? { ...pdp, filters: pdp?.filters?.map((fil) => ({ ...fil, id: v4() })) } : pdpJson);
    setSuccessLoaderStart(false);
    setModalType(type);
    setSecondaryTitle(type === "edit" ? "Edit" : type === "userAssign" ? "User Assign" : type === "groupAssign" ? "Group Assign" : "Add");
    setIsPdpManageModalOpen(true);
  };

  const deletePdp = async () => {
    try {
      setSuccessLoaderStart(true);
      const dataset_id = pdpList.find((pdp) => pdp.id === deleteId).dataset_id;
      const res = await apiRequest(`post`, `/workspaces/:workspace_id/dataset/:dataset_id/pdp/:pdp_id`, {
        body: {
          authToken: props?.workspaceDetails?.auth_token,
        },
        params: {
          workspace_id: props?.workspaceDetails?._id,
          dataset_id,
          pdp_id: deleteId,
        },
      });

      if (res.data.status === 200) {
        toast.success(res.data.message);
        setPdpList(pdpList.filter((pdp) => pdp.id !== deleteId));
        setPdpMainList(pdpMainList.filter((pdp) => pdp.id !== deleteId));
        setDeleteId(null);
      } else {
        toast.error(res.data.message);
      }
      setSuccessLoaderStart(false);
    } catch (error) {
      // console.dir("ERROR:", error);
    }
  };

  useEffect(() => {
    if (props?.workspaceDetails?._id) {
      loadUserGroupData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.workspaceDetails?._id]);

  useEffect(() => {
    if (props?.workspaceDetails?._id) {
      loadDataset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.workspaceDetails?._id]);

  useEffect(() => {
    if (props?.workspaceDetails?._id && datasetList.length > 0) {
      loadPdp(datasetList[0], 0, []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.workspaceDetails?._id, datasetList.length]);

  useEffect(() => {
    setPdpList(pdpMainList.filter((list) => list?.name?.toLowerCase().includes(keyword.toLowerCase()) || list?.dataset_name?.toLowerCase().includes(keyword.toLowerCase())));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword]);

  return (
    <>
      <TableOptions
        leftContent={
          <Search
            keyword={keyword}
            setKeyword={setKeyword}
            placeholder={"Search"}
            inline={true}
          />
        }
        rightContent={
          <Button
            version="secondary"
            onClick={() => onManageModal({}, "add")}>
            <PlusIcon className="h-4 w-4 stroke-[3px]" />
            Add Policy
          </Button>
        }
      />
      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="rounded-tl-lg py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Policy Name
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
              Dataset
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
              Users
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
              Groups
            </th>
            <th
              scope="col"
              className="relative rounded-tr-lg py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </TRHeader>
        }
        colSpan="5"
        setDeleteId={setDeleteId}
        deleteId={deleteId}
        loaded={pdpLoaded}
        dataExists={pdpList?.length > 0}>
        {pdpList.length > 0 &&
          pdpList.map((pdp, i) => {
            return (
              <PdpEntry
                key={pdp?.id}
                pdp={pdp}
                users={users}
                groups={groups}
                deleteId={deleteId}
                setDeleteId={setDeleteId}
                onEditModal={onManageModal}
              />
            );
          })}
      </Table>
      <PdpModalManage
        isOpen={isPdpManageModalOpen}
        workspaceId={props?.workspaceId}
        setIsOpen={setIsPdpManageModalOpen}
        title="PDP"
        secondaryTitle={secondaryTitle}
        users={users}
        groups={groups}
        pdpId={pdpId}
        datasetId={datasetId}
        setdatasetId={setdatasetId}
        pdpData={pdpData}
        setPdpData={setPdpData}
        datasetList={datasetList}
        filterJson={filterJson}
        onSubmit={managePdp}
        successLoaderStart={successLoaderStart}
        workspaceDetails={props?.workspaceDetails}
        modalType={modalType}
      />
      <WorkspaceImportUserModal
        isImportModalOpen={importModalOpen}
        setIsImportModalOpen={setImportModalOpen}
        selectedUsers={pdpData?.users}
        workspaceUsers={users}
        sendWelcomeEmail={sendWelcomeEmail}
        setSendWelcomeEmail={setSendWelcomeEmail}
        password={password}
        setPassword={setPassword}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
        onSuccess={(users, status) => onSubmit(status)}
        workspaceDetailsData={props.workspaceDetails}
        hideCrossIcon={false}
        disableStatus={successLoaderStart}
      />
      <Modal
        title="PDP"
        secondaryTitle="Delete"
        isOpen={!!deleteId}
        onCancel={() => setDeleteId(null)}
        onSuccess={deletePdp}
        isLoading={successLoaderStart}
        defaultOptions={{
          onSuccessButtonText: "Delete",
          onSuccessLoaderVisible: true,
          onSuccessLoaderStart: successLoaderStart,
        }}>
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {Array.isArray(pdpList) && pdpList?.find((item) => item.id === deleteId)?.name}?</div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(WorkspacePdpList);
