import { Menu, Transition } from "@headlessui/react";
import { ChevronUpDownIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import { baseUrl } from "src/config/host";
import { isSVGString } from "src/helpers";
import { classNames } from "src/helpers/classNames";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import Input from "src/components/Shared/Forms/Inputs/Input";

const MultiSelectDropdown = ({
  placeholder,
  inline = false,
  selectedItem = [],
  buttonClass,
  buttonLabelClass,
  buttonIconClass,
  dropdownData,
  dropdownClass = "",
  scrollableContainerClass = "",
  disabled = false,
  onClick = () => {},
  searchableFields = [],
  checkboxPosition = "right",
  isLoading = false,
  isSelectAll = false,
  selectAll = () => {},
}) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [selected, setSelected] = useState(selectedItem.length > 0 ? `${selectedItem.length} Selected` : !inline && placeholder);

  useEffect(() => {
    setFilteredOptions(dropdownData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownData]);

  useEffect(() => {
    if (searchableFields.length > 0) {
      let updatedFilteredOptions = dropdownData.filter((option) => {
        let keywordMatched = false;
        searchableFields.forEach((searchableField) => {
          if (option?.object[searchableField]?.toLowerCase().includes(keyword.toLowerCase())) {
            keywordMatched = true;
          }
        });
        return keywordMatched;
      });
      setFilteredOptions(updatedFilteredOptions);
    }
  }, [keyword, dropdownData, searchableFields]);

  useEffect(() => {
    setSelected(selectedItem.length > 0 ? `${selectedItem.length} Selected` : !inline && placeholder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeholder, selectedItem]);

  return (
    <>
      <Menu
        as="div"
        className="relative">
        <div className="relative flex">
          <Menu.Button
            className={classNames(
              inline
                ? "relative h-[47px] w-full cursor-pointer rounded-md border border-gray-300 bg-white px-0 pt-2  text-left hover:bg-gray-50 focus:outline-none sm:text-sm md:h-[40px] md:pt-1 2xl:h-[47px] 2xl:pt-2"
                : "flex h-[47px] w-full items-center justify-between gap-2 rounded-md border border-gray-300 pl-4 text-left md:h-[40px] 2xl:h-[47px]",
              buttonClass,
              isLoading ? "bg-gray-50" : "",
            )}
            disabled={isLoading}>
            {inline && <div className="m-0 flex justify-between pl-3 text-xs font-semibold uppercase leading-none text-gray-400 md:pl-2 md:text-[10px] 2xl:pl-3">{placeholder}</div>}
            <div className={classNames(inline ? "relative flex h-8 w-full items-center rounded-none border-none px-3 py-0 pl-3 text-left shadow-none sm:text-sm md:h-7 md:px-2 2xl:h-8 2xl:px-3" : "w-full flex-shrink text-sm font-medium text-gray-600", buttonLabelClass)}>
              {isLoading ? (
                <Preloader
                  size="xs"
                  circleDimension={"4"}
                  className={"!py-0"}
                />
              ) : (
                selected
              )}
            </div>
            <div className={classNames(inline ? "pointer-events-none absolute inset-y-0 right-0 flex items-center" : "flex-shrink-0")}>
              <ChevronUpDownIcon
                className={classNames("h-5 w-5 text-gray-400", buttonIconClass)}
                aria-hidden="true"
              />
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className={classNames("absolute left-0 mt-2 w-96 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none", dropdownClass)}>
            {searchableFields.length > 0 && (
              <div className="sticky left-0 top-0 z-20 mb-0 w-full bg-white p-1.5">
                <Input
                  type="text"
                  autoComplete="off"
                  name="search"
                  id="search"
                  value={keyword}
                  className="block w-full rounded-md border-gray-300 pl-9 text-sm focus:border-highlightColor focus:ring-0"
                  inputClassNames={"!h-10 block w-full sm:text-sm border-gray-300 rounded-md shadow-sm focus:ring-0 focus:border-highlightColor !pl-8"}
                  onKeyDown={(e) => e.stopPropagation()}
                  onChange={(e) => setKeyword(e.target.value)}
                />
                <div
                  className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                  aria-hidden="true">
                  <MagnifyingGlassIcon
                    className="mr-3 h-4 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            )}
            {isSelectAll && (
              <Button
                version="default"
                onClick={selectAll}
                className="!h-8 w-full px-3 text-sm text-gray-300">
                Select all / deselect all
              </Button>
            )}
            <div className={classNames("scrollbar max-h-60 overflow-y-auto pb-2", scrollableContainerClass)}>
              {filteredOptions.map((item) => (
                <Button
                  type="button"
                  version="default"
                  className={classNames("flex !h-8 w-full items-center gap-2 px-3 py-2 text-left hover:bg-gray-100", checkboxPosition === "left" ? "flex-row-reverse" : "")}
                  key={item._id}>
                  <div className="flex flex-shrink flex-grow items-center gap-2">
                    {item?.image && (
                      <div className="h-8 w-8 flex-shrink-0 overflow-hidden rounded-md object-contain">
                        {isSVGString(item.image) ? (
                          <div
                            className=""
                            dangerouslySetInnerHTML={{ __html: item.image }}
                          />
                        ) : (
                          <img
                            src={item?.image?.includes(".s3.") ? item?.image + "?" + Date.now() : item?.image?.includes("data:image/") ? item?.image : baseUrl + item?.image}
                            alt={item?.name}
                            className={classNames("h-full w-full object-contain")}
                          />
                        )}
                      </div>
                    )}
                    <div className="w-full flex-shrink text-[13px] font-semibold text-gray-600">{item?.name}</div>
                  </div>
                  <div className="flex-shrink-0">
                    <Checkbox
                      isChecked={selectedItem.includes(item._id)}
                      onChange={(e) => onClick(e, item)}
                    />
                  </div>
                </Button>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default MultiSelectDropdown;
