import { useLocation } from "react-router-dom";

function useUrlPathParams() {
  const { pathname } = useLocation();
  const pathSegments = pathname.split("/").filter((segment) => segment !== "");

  // Extract "workspace" parameters
  const workspace = pathSegments[1];

  return { workspace };
}

export default useUrlPathParams;
