import { connect } from "react-redux";
import UserProfile from "src/components/Users/UserProfile";
import { classNames } from "src/helpers/classNames";

const WorkspacePermissionHeader = ({ item, activeUser = {}, setActiveUser = () => { }, permissionChanges, setListToggle, setWarningModalIsOpen = () => {} }) => {
  return (
    <div className={classNames("relative flex h-16 w-full rounded-md bg-white shadow", item._id === activeUser?._id ? "" : "")}>
      <div
        onClick={permissionChanges > 0 ? () => { setWarningModalIsOpen(item); setListToggle(true) } : () => {setActiveUser(item);setListToggle(true)}}
        className={classNames("w-full flex cursor-pointer items-center justify-between px-3 relative bg-white border sm:border-2 rounded-md max-w-full transition-all", item?._id === activeUser?._id ? "sm:border-highlightColor" : "border-gray-100 ")}>
        <div className="relative flex items-center gap-x-2 w-full">
          <div className="w-12 h-12 rounded-full overflow-hidden flex items-center justify-center">
            <UserProfile user={item?.user_id || {}} />
          </div>
          <div className="max-w-[150px]">
            <p className="font-semibold text-gray-700 truncate">{item?.user_id?.name}</p>
            <p className="text-xs text-gray-600 truncate">{item?.user_id?.email}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(WorkspacePermissionHeader);
