import { ArrowTopRightOnSquareIcon, Bars3Icon, ClipboardDocumentListIcon, UserIcon, WindowIcon } from "@heroicons/react/24/outline";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Button from "src/components/Shared/Buttons/Button";
import Image from "src/components/Shared/Image";
import TRBody from "src/components/Shared/Table/TRBody";
import TableRow from "src/components/Shared/Table/TableRow";
import WorkspaceSubscriberInstance from "src/components/Workspaces/WorkspaceSubscriberInstance";
import { noFavicon } from "src/config/host";
import { classNames } from "src/helpers/classNames";

const WorkspaceEntry = ({ item, isDragging = { keyId: "", value: false }, provided }) => {
  return (
    <TableRow
      keyId={item?._id}
      provided={provided}
      isDragging={isDragging}
      classes={isDragging.value && isDragging.keyId === item?._id ? "!inline-table bg-white/80 !border-none" : ""}>
      <TRBody className="w-[3%] whitespace-nowrap !px-0">
        <Button
          dragHandleProps={provided?.dragHandleProps}
          type="button"
          version="default"
          className="mx-auto flex w-8 cursor-move items-center justify-center bg-transparent text-gray-500 outline-none ring-0 sm:min-w-[32px] sm:pl-3">
          <Bars3Icon className="h-6 w-6" />
        </Button>
      </TRBody>
      <TRBody className="w-[52%] whitespace-nowrap py-3 pl-1 pr-1 text-sm sm:pl-3 sm:pr-3">
        <div className="relative flex items-center gap-x-3">
          <Link
            to={`/workspaces/${item._id}`}
            className="group cursor-pointer rounded-md border border-transparent pr-5 font-medium text-gray-900 transition-all duration-200">
            <div className="flex items-center gap-4">
              <div
                style={!item?.square_logo && item.image_logo ? { background: item.top_bar_color } : {}}
                className="h-8 w-8 overflow-hidden rounded bg-white/10 sm:h-14 sm:w-14">
                <Image
                  image={item.square_logo || item.image_logo || item.image_favicon}
                  failImage={noFavicon}
                  alt={"Workspace"}
                />
              </div>
              <div>
                <div className="sm:max-w-auto flex h-full w-[100px] items-center gap-x-2 truncate text-gray-700 group-hover:text-gray-900 group-hover:underline sm:w-auto sm:overflow-visible">
                  {item?.name} <ArrowTopRightOnSquareIcon className="h-4 w-4 opacity-20 transition-all duration-150 group-hover:opacity-70" />
                </div>
                {item?.workspace_type === "JWT_FULL_EMBED" && <WorkspaceSubscriberInstance />}
              </div>
            </div>
          </Link>
        </div>
      </TRBody>
      <TRBody className="w-[15%] whitespace-nowrap py-3 pl-1 pr-1 text-sm sm:pl-3 sm:pr-3">
        <Link to={`/workspaces/${item?._id}?tab=Pages`}>
          <div className={classNames("flex w-12 cursor-pointer gap-x-1 font-medium transition-all duration-100 hover:underline", (item?.workspace_type === "JWT_FULL_EMBED" ? item?.domo_data?.page > 0 : item?.pages?.length > 0) ? "text-gray-900" : "text-gray-300")}>
            <WindowIcon className="h-5 w-5" />
            {item?.workspace_type !== "JWT_FULL_EMBED" ? item.pages?.length || 0 : item.workspace_type && item?.workspace_type === "JWT_FULL_EMBED" ? item?.domo_data?.page || 0 : 0}
          </div>
        </Link>
      </TRBody>
      <TRBody className="w-[15%] whitespace-nowrap py-3 pl-1 pr-1 text-sm sm:pl-3 sm:pr-3">
        <Link to={`/workspaces/${item?._id}?tab=Groups`}>
          <div className={classNames("flex w-12 cursor-pointer gap-x-1 font-medium transition-all duration-100 hover:underline", (item?.workspace_type === "JWT_FULL_EMBED" ? item?.domo_data?.group > 0 : item?.groups?.length > 0) ? "text-gray-900" : "text-gray-300")}>
            <ClipboardDocumentListIcon className="h-5 w-5" />
            {item?.workspace_type !== "JWT_FULL_EMBED" ? item.groups?.length || 0 : item.workspace_type && item?.workspace_type === "JWT_FULL_EMBED" ? item?.domo_data?.group || 0 : 0}
          </div>
        </Link>
      </TRBody>
      <TRBody className="w-[15%] whitespace-nowrap py-3 pl-1 pr-1 text-sm sm:pl-3 sm:pr-3">
        <Link to={`/workspaces/${item?._id}?tab=Users`}>
          <div className={classNames("flex w-12 cursor-pointer gap-x-1 font-medium transition-all duration-100 hover:underline", item?.userIds?.length > 0 ? "text-gray-900" : "text-gray-300")}>
            <UserIcon className="h-5 w-5" />
            {item?.userIds?.length || 0}
          </div>
        </Link>
      </TRBody>
      {/* <td className="whitespace-nowrap py-3 pl-3 pr-4 text-sm font-medium">
        <div className="flex justify-end">
          <QuickMenu
            items={[
              {
                name: "Edit",
                onClick: () => {
                  navigate(`/workspaces/${workspace._id}`);
                },
              },
            ]}
          />
        </div>
      </td> */}
    </TableRow>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(WorkspaceEntry);
