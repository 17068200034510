import { CLEAR_2FA_DETAILS, GET_2FA_DETAILS, LOGGED_IN, LOG_OUT } from "src/actions/type";
import { apiRequest } from "src/async/apiUtils";
import { apiUrl } from "src/config/host";

const asyncLocalStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  },
  removeItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.removeItem(key);
    });
  },
};

export const loginTracking = (body) => async (dispatch) => {
  try {
    const { data: resData } = await apiRequest("POST", `/login/tracking`, { body });

    if (resData.status === 200) {
      return resData.data;
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const login = (body) => async (dispatch) => {
  try {
    const { data: resData } = await apiRequest("POST", `/login`, { body });

    if (resData.status === 200) {
      if (resData?.two_factor_enabled) {
        dispatch({ type: GET_2FA_DETAILS, payload: { two_factor_enabled: resData.two_factor_enabled, email: body.email } });
      } else {
        dispatch({ type: LOGGED_IN, payload: resData.data });
        localStorage.setItem("userId", resData.data._id);
        localStorage.setItem("access_token", resData.access_token);
        localStorage.setItem("refresh_token", resData.refresh_token);
        // Remove user inactive time because login makes user active
        localStorage.removeItem("userLastActiveTime");
      }
      return { message: resData.message };
    } else if (resData.status === 401) {
      const { data: newResData } = await apiRequest("POST", `/regenerate-access-token`, {
        body: {
          token: localStorage.getItem("refresh_token"),
          ...body,
        },
      });

      if (newResData.status === 400) {
        throw new Error(newResData.message);
      }

      localStorage.setItem("access_token", newResData.access_token);
      // Remove user inactive time because login makes user active
      localStorage.removeItem("userLastActiveTime");

      dispatch({ type: LOGGED_IN, payload: newResData.data });

      return "User verification successful";
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const otpVerify = (body) => async (dispatch) => {
  try {
    const { data: resData } = await apiRequest("POST", `/otp/verify`, { body });

    if (resData.status === 200) {
      dispatch({ type: LOGGED_IN, payload: resData.data });
      dispatch({ type: CLEAR_2FA_DETAILS, payload: null });
      localStorage.setItem("userId", resData.data._id);
      localStorage.setItem("access_token", resData.access_token);
      localStorage.setItem("refresh_token", resData.refresh_token);
      // Remove user inactive time because login makes user active
      localStorage.removeItem("userLastActiveTime");
      return { message: resData.message };
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const changePasswordFirstTime = (body) => async (dispatch) => {
  try {
    const { data: resData } = await apiRequest("POST", `/change-password`, { body });

    if (resData.status === 200) {
      dispatch({ type: LOGGED_IN, payload: resData.data });
      localStorage.setItem("userId", resData.data._id);
      localStorage.setItem("access_token", resData.access_token);
      localStorage.setItem("refresh_token", resData.refresh_token);
      // Remove user inactive time because login makes user active
      localStorage.removeItem("userLastActiveTime");
      return resData.message;
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getAuthorizedUser =
  (signal = null) =>
  async (dispatch, getState) => {
    try {
      let userId = localStorage.getItem("userId");
      let impersonatedUserId = localStorage.getItem("impersonatedUserId");

      let url = `/users/self-details`;
      if (impersonatedUserId) {
        url = `/users/get-details`;
      }
      const { data: resData } = await apiRequest(
        "POST",
        url,
        {
          body: {
            id: impersonatedUserId || userId,
          },
        },
        { signal },
      );

      if (resData.status === 200) {
        if (resData?.data?.type === "user" && !impersonatedUserId) {
          const authLogout = async (site) => {
            // const { data } = await apiRequest("GET", `/sso/default-list`);
            let impersonatedUserId = asyncLocalStorage.getItem("impersonatedUserId");
            if (impersonatedUserId) {
              asyncLocalStorage.removeItem("impersonatedUserId");
              // window.location.href = "/pages";
            }

            await asyncLocalStorage.removeItem("userId");
            await asyncLocalStorage.removeItem("impersonatedUserId");
            await asyncLocalStorage.removeItem("access_token");
            await asyncLocalStorage.removeItem("refresh_token");

            dispatch({ type: LOG_OUT, payload: null });
          };
          return authLogout();
        }

        dispatch({ type: LOGGED_IN, payload: resData.data });
        return resData.message;
      } else {
        /* else if (resData.status === 401) {
          const {data: newResData} = await apiRequest(
            "POST",
            `/regenerate-access-token`,
            {
              body: {
                token: localStorage.getItem("refresh_token"),
                id: localStorage.getItem("userId"),
              }
            },
            {signal}
          );

          if (newResData.status === 400) {
            throw new Error(newResData.message);
          }

          localStorage.getItem("access_token", newResData.access_token);

          dispatch({ type: LOGGED_IN, payload: newResData.data });
          return "User verification successful";
        } */
        throw new Error(resData.message);
      }
    } catch (error) {
      if (error.message !== "Failed to fetch" && error.message !== "The user aborted a request.") {
        throw new Error(error.message);
      }
    }
  };

export const ssoLoginActivity = () => {
  apiRequest("POST", `/sso-login/activity`, {});
};

export const getUser =
  (signal = null) =>
  async (dispatch) => {
    try {
      let userId = localStorage.getItem("userId");
      let impersonatedUserId = localStorage.getItem("impersonatedUserId");

      const { data: resData } = await apiRequest(
        "POST",
        `/users/get-details`,
        {
          body: {
            id: impersonatedUserId || userId,
            access_token: localStorage.getItem("access_token"),
          },
        },
        { signal },
      );

      if (resData.status === 200) {
        dispatch({ type: LOGGED_IN, payload: resData.data });
        return resData.message;
      } else if (resData.status === 401) {
        const { data: newResData } = await apiRequest(
          "POST",
          `/regenerate-access-token`,
          {
            body: {
              token: localStorage.getItem("refresh_token"),
              id: localStorage.getItem("userId"),
            },
          },
          { signal },
        );

        if (newResData.status === 400) {
          throw new Error(newResData.message);
        }

        localStorage.getItem("access_token", newResData.access_token);

        dispatch({ type: LOGGED_IN, payload: newResData.data });
        return "User verification successful";
      } else {
        throw new Error(resData.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const verifyUser = (body) => async (dispatch) => {
  try {
    const { data: resData } = await apiRequest("POST", `/verify-user`, { body });

    if (resData.status === 200) {
      const { message, data } = resData;

      return { message, data };
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const verifyToken =
  (body, signal = null) =>
  async (dispatch) => {
    try {
      const { data: resData } = await apiRequest("POST", `/verify-token`, { body }, { signal });

      if (resData.status === 200) {
        return resData;
      } else {
        throw new Error(resData.message);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

export const changePassword = (body) => async (dispatch) => {
  try {
    const { data: resData } = await apiRequest("POST", `/change-password`, { body });

    if (resData.status === 200) {
      return resData.message;
    } else {
      throw new Error(resData.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const logout = (site, RelayState) => async (dispatch) => {
  let impersonatedUserId = await asyncLocalStorage.getItem("impersonatedUserId");
  if (impersonatedUserId) {
    asyncLocalStorage.removeItem("impersonatedUserId");
    window.location.href = "/pages";
  }
  let access_token = await asyncLocalStorage.getItem("access_token");
  if (access_token) {
    await asyncLocalStorage.removeItem("userId");
    await asyncLocalStorage.removeItem("impersonatedUserId");
    await asyncLocalStorage.removeItem("access_token");
    await asyncLocalStorage.removeItem("refresh_token");
    await asyncLocalStorage.removeItem("userLastActiveTime");

    window.location.href = `${apiUrl}/logout?RelayState=${RelayState}`;
  }
};

export const logoutDispatch = (site) => (dispatch) => {
  dispatch({ type: LOG_OUT, payload: null });
};

export const refreshTokenVerify = async () => {
  const refreshTokenRes = await fetch(`${apiUrl}/refresh-token/verify`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("refresh_token")}`,
    }),
    body: JSON.stringify({}),
  });

  let refreshTokenData = {};

  if (refreshTokenRes.status === 200) {
    refreshTokenData = await refreshTokenRes.json();
    localStorage.setItem("access_token", refreshTokenData.access_token);
    localStorage.setItem("refresh_token", refreshTokenData.refresh_token);
    // Remove user inactive time because login makes user active
    localStorage.removeItem("userLastActiveTime");
  } else {
    await asyncLocalStorage.removeItem("userId");
    await asyncLocalStorage.removeItem("impersonatedUserId");
    await asyncLocalStorage.removeItem("access_token");
    await asyncLocalStorage.removeItem("refresh_token");
    window.location.href = `${apiUrl}/logout`;
  }

  return { refreshTokenRes, refreshTokenData };
};
