import { useEffect, useState } from "react";
import { useDomo } from "src/context/DomoProvider";

const useColumnNamesByPage = (pageId) => {
  const { columnNames, getColumnNamesByPage } = useDomo();
  const [columns, setColumns] = useState({ loading: false, columns: [] });

  useEffect(() => {
    const newColumns = getColumnNamesByPage({ pageId });
    setColumns(newColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnNames, pageId]);

  return columns;
};

export default useColumnNamesByPage;
