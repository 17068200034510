export const formatName = (name, email) => {
  if (!name || !name.trim()) {
    if (!email || !email.trim()) return "";

    return email.substr(0, 1).toUpperCase() + email.substr(1, 1).toUpperCase();
  }

  const nameSplit = name.split(" ");

  if (!nameSplit.length) return "";

  if (nameSplit.length === 1) {
    return nameSplit[0].substr(0, 1).toUpperCase() + nameSplit[0].substr(1, 1).toUpperCase();
  }

  if (!nameSplit[1] && nameSplit[2]) {
    nameSplit[1] = nameSplit[2];
  }

  if (nameSplit[0].substr(0, 1).toUpperCase() + nameSplit[1].substr(0, 1).toUpperCase() === "A") {
    // TODO: rmv cl
  }

  return nameSplit[0].substr(0, 1).toUpperCase() + nameSplit[1].substr(0, 1).toUpperCase();
};