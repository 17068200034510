// import mapKeys from "lodash/mapKeys";
// import omit from "lodash/omit";
import {
  GET_PAGE_DATA,
  LOG_OUT,
  // REMOVE_PAGE
} from "src/actions/type";

const pageReducer = (state = [], action) => {
  switch (action.type) {
    case GET_PAGE_DATA:
      return action.payload;
    case LOG_OUT:
      return [];
    // case REMOVE_PAGE:
    //   return omit(state, action.payload);
    default:
      return state;
  }
};

export default pageReducer;
