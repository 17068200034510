import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "src/async/apiUtils";
import useUrlQueryParams from "src/hooks/useUrlQueryParams";
import ContentError from "src/components/impersonate/ContentError";
import ContentLoading from "src/components/impersonate/ContentLoading";
import ContentTypesRouter from "src/components/impersonate/ContentTypesRouter";
import { getPageToRender } from "src/actions/impersonate";

const EmbedDashboard = ({ workspaceDetails, site, user }) => {
  const navigate = useNavigate();
  const { workspace_id } = useParams();
  const { group, page } = useUrlQueryParams();
  const [loadingPage, setLoadingPage] = useState(true);
  const [content, setContent] = useState(null);
  const [contentError, setContentError] = useState(null);

  useEffect(() => {
    const handlePageGet = async () => {
      try {
        setLoadingPage(true);
        const { data } = await getPageToRender({ workspace_id, user_id: user?._id, queries: { group, page } });
        if (data?.invalidWorkspace) {
          navigate("/permission-denied");
        } else if (data?.invalidPage || data?.invalidGroup) {
          const { data: resData } = await apiRequest("GET", `/portal/users/${workspace_id}/page`);

          if (resData.status === 200) {
            navigate(resData.link);
            return;
          }
        } else if (data?.invalidFilter) {
          setContentError("incorrect_filters");
        } else {
          setContentError(null);
          setContent(data);
          setLoadingPage(false);
        }
      } catch (error) {
        setLoadingPage(false);
        setContentError("page_error");
      }
    };

    if (workspace_id && user?._id && (workspaceDetails?.workspace_type === "JWT_FULL_EMBED" || page)) {
      handlePageGet();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, group, page, workspace_id, workspaceDetails?.workspace_type]);

  return (
    <>
      {contentError ? (
        <ContentError pageError={contentError} />
      ) : (
        <>
          {loadingPage ? (
            <ContentLoading menuType={workspaceDetails?.layout_type} />
          ) : (
            <div className="w-full">
              <ContentTypesRouter
                content={content}
                menuType={workspaceDetails?.layout_type}
                workspace={workspaceDetails}
                site={site}
                setError={() => {}}
                setLoading={() => {}}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    user: state.auth.user,
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, {})(EmbedDashboard);
