import { ArrowUpRightIcon, PlusIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { connect } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Button from "src/components/Shared/Buttons/Button";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import QuickMenu from "src/components/Shared/QuickMenu";
import TRBody from "src/components/Shared/Table/TRBody";
import TRHeader from "src/components/Shared/Table/TRHeader";
import Table from "src/components/Shared/Table/Table";
import TableRow from "src/components/Shared/Table/TableRow";
import WorkspaceDomainManage from "./WorkspaceDomainManage";
import useFetch from "src/hooks/useFetch";
import NoDataDashedContainer from "src/components/Shared/NoData/NoDataDashedContainer";
import { generateLink, getBaseFromDomain } from "src/helpers";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import WorkspaceDomainDeleteModal from "./WorkspaceDomainDeleteModal";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import { getWorkspaceDetails } from "src/actions/workspace";
import InformationAlert from "src/components/Shared/Alerts/InformationAlert";

const WorkspaceDomain = ({ workspaceId, workspaceDetails, site = {}, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isQuickMenuDisabled, setIsQuickMenuDisabled] = useState(false);

  const {
    response: { data: domains },
    message,
    status: { done: domainsLoaded, fail: domainsLoadedFail },
    refreshData: refreshDomain,
  } = useFetch(`/workspaces/:workspace_id/domains`, { params: { workspace_id: workspaceId } });

  const removeDomain = async () => {
    setIsQuickMenuDisabled(true);

    try {
      const res = await apiRequest("DELETE", `workspaces/:workspace_id/domains`, {
        params: {
          workspace_id: workspaceId,
        },
      });

      refreshDomain();
      getWorkspaceSettings();
      setIsDeleteModalOpen(false);
      toast.success(res?.data?.message);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsQuickMenuDisabled(false);
    }
  };

  const getWorkspaceSettings = async () => {
    try {
      await props.getWorkspaceDetails({ workspace_id: workspaceId });
    } catch (err) {}
  };

  const items = [
    {
      _id: 1,
      name: "Delete",
      onClick: () => setIsDeleteModalOpen(true),
    },
  ];

  const emptyDomain = domains?.domains?.length >= 1 && domainsLoaded;

  const addButton =
    emptyDomain || !domainsLoaded || !site?.allow_subdomains ? null : (
      <Button
        version="primary"
        onClick={() => setIsOpen(true)}>
        <div className="flex gap-x-3">
          <PlusIcon className="h-5 w-5 stroke-2" /> Add Domain
        </div>
      </Button>
    );

  return (
    <EditContainer
      title="Domains"
      subtitle="Add a custom white-labeled domain for your clients"
      preview={
        <div className="flex h-10 w-56 items-center">
          <div className="flex gap-x-2 rounded border border-gray-100 px-3 py-1 shadow">
            {domains?.domains?.length ? (
              <>
                {domains?.domains?.length + " custom domain"} 
              </>
            ) : domainsLoadedFail ? (
            message
          ) : (
            "No Domains"
          )}
          </div>
        </div>
      }
      defaultOptions={{
        onCancelButtonVisible: false,
        onSuccessButtonVisible: false,
      }}
      fullWidth={true}>
      <>
        <div className="relative space-y-5 py-4">
          <div className="space-y-1">
            {domains?.tier_slug && (
              <div className="relative inline-flex items-center space-x-2 text-gray-500">
                <div className="text-base font-semibold capitalize">{domains?.tier_slug}</div>
                <div
                  className="cursor-pointer"
                  data-tooltip-id={`${domains?.tier_slug}`}>
                  <QuestionMarkCircleIcon className="h-5 w-5" />
                </div>
                <ReactTooltip
                  id={`${domains?.tier_slug}`}
                  delayShow={200}
                  positionStrategy="fixed"
                  className="rounded bg-gray-700 px-2 py-2 opacity-100">
                  <div className="font-normal leading-[10px]">Your app is always accessible at this domain.</div>
                </ReactTooltip>
              </div>
            )}
            <div className="flex">
              <a
                href={site?.site_domain}
                rel="noreferrer"
                target="_blank"
                className="inline-flex items-end space-x-1 font-semibold text-highlightColor underline">
                <span>{getBaseFromDomain(site?.site_domain)}</span>
                <ArrowUpRightIcon className="relative -top-px h-3 w-3 stroke-[4]" />
              </a>
            </div>
          </div>
          {domainsLoaded || domainsLoadedFail ? (
            domains?.domains?.length > 0 ? (
              <>
                <Table
                  tableHeader={
                    <TRHeader>
                      <th
                        scope="col"
                        className="overflow-hidden rounded-tl-lg py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Custom
                      </th>
                      <th
                        scope="col"
                        className="overflow-hidden rounded-tl-lg py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Status
                      </th>
                      <th
                        scope="col"
                        className="overflow-hidden py-3.5 pl-4 pr-3 text-left text-[0px] font-semibold text-gray-900 sm:table-cell sm:pl-6 sm:text-sm"></th>
                    </TRHeader>
                  }
                  colSpan="5"
                  loaded={true}
                  dataExists={true}>
                  {domains?.domains?.map((domain, index) => (
                    <TableRow key={domain?.domain}>
                      <TRBody className="whitespace-pre-wrap">
                        <div className="flex">
                          <a
                            href={generateLink(domain?.domain)}
                            rel="noreferrer"
                            target="_blank"
                            className="inline-flex items-end space-x-1 font-semibold text-highlightColor underline">
                            <span>{domain?.domain}</span>
                            <ArrowUpRightIcon className="relative -top-px h-3 w-3 stroke-[4]" />
                          </a>
                        </div>
                      </TRBody>
                      <TRBody className="whitespace-pre-wrap">
                        {domain?.isExistsOnDigitalOcean ? (
                          domain?.phase
                        ) : (
                          <div className="-mt-4 flex">
                            <InformationAlert
                              content="Domain has been deleted from the main app, delete the domain and reconfigure again."
                              type="info"
                            />
                          </div>
                        )}
                      </TRBody>
                      <td className="bg-white px-5 py-3 text-sm">
                        <div className="flex justify-end">
                          <QuickMenu
                            disabled={isQuickMenuDisabled}
                            items={items}
                          />
                        </div>
                      </td>
                    </TableRow>
                  ))}
                </Table>
                <div className="flex">{addButton}</div>
              </>
            ) : (
              <NoDataDashedContainer
                title={domainsLoadedFail ? message : "No Domains found"}
                addButton={addButton}
              />
            )
          ) : (
            <Preloader />
          )}
        </div>
        <WorkspaceDomainManage
          isOpen={isOpen}
          onCancel={() => setIsOpen(false)}
          domain={{ default_ingress: domains?.default_ingress, tier_slug: domains?.tier_slug, region_label: domains?.region_label }}
          refreshDomain={() => {
            refreshDomain();
            getWorkspaceSettings();
          }}
        />
        <WorkspaceDomainDeleteModal
          isOpen={isDeleteModalOpen}
          disable={isQuickMenuDisabled}
          onDelete={removeDomain}
          onCancel={() => setIsDeleteModalOpen(false)}
        />
      </>
    </EditContainer>
  );
};

const mapStateToProps = (state) => ({
  site: state.site,
});

export default connect(mapStateToProps, { getWorkspaceDetails })(WorkspaceDomain);
